import React, {useState, useContext, useMemo, useEffect} from "react";
import {Col, Container, Row, Button, Form, FloatingLabel, FormLabel} from "react-bootstrap";
import profileImg from "../../assets/images/others/image-not-found.png";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {ArrowIcon} from "../components/SvgComponents";
import AccSidebar from "../components/AccSidebar";
import {useForm, Controller} from "react-hook-form";
import {Patterns, VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {Toast, UploadImageToS3} from "../../utils/helpers";
import {UpdateProfile} from "../../services/user.service";
import {EmptyLocalStorage, GetCurrentUser, SetAuthUser} from "../../services/authentication.service";
import moment from 'moment';
import {IsCustomerLogin} from "../../App";
import {createOrUpdateUser} from "../../services/firebase.service";
import countryList from 'react-select-country-list'
import {constants} from "../../utils/constants";
import ThemeModal from "../components/ThemeModal";
import {GetAvatar} from "../../services/avatar.service";
import {Country, State, City} from 'country-state-city';
import Select from "react-select";

function ProfileInformation() {
    const navigate = useNavigate()
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [profile, setProfile] = useState(false);
    const [avatarModalShow, setAvatarModalShow] = useState(false);
    const [avatars, setAvatars] = useState([]);
    const options = useMemo(() => countryList().getData(), [])
    const [selectedAvatar, setSelectedAvatar] = useState(isCustomerLogin?.image || '');
    const [disableButton, setDisableButton] = useState(false);

    const [countryCode, setCountryCode] = useState('');
    const [location, setLocation] = useState('');

    const {register, handleSubmit, reset, watch, control, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const country = watch("country")
    const city = watch("city")
    const state = watch("state")

    //TODO: Need to add confirmation Yes/No on click Remove Button

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getAvatars();
    }, [])

    useEffect(() => {
        reset({
            country: {
                label: isCustomerLogin?.location,
                value: isCustomerLogin?.location,
                isoCode: isCustomerLogin?.country_code
            },
            state: {
                label: isCustomerLogin?.state,
                value: isCustomerLogin?.state,
                isoCode: isCustomerLogin?.state_code
            },
            city: {
                label: isCustomerLogin?.city,
                value: isCustomerLogin?.city
            }
        })
    }, [isCustomerLogin])

    //console.log(country,"----")

    const getAvatars = async () => {
        await GetAvatar().then((data) => {
            if (data.status) {
                //Toast(data.message, true);
                setAvatars(data?.data?.data);
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const onSubmit = async (data) => {
        let params = {
            'first_name': data.fName.trim(),
            'last_name': data.lName.trim(),
            'username': data.userName.trim(),
            'email': data.email.trim(),
            'dob': data.dob,
            'location': data?.country?.value ? data.country.value : '',
            'latitude': '12.222',
            'longitude': '33.3333',
            'image': selectedAvatar,
            'show_name': data.show_name,
            'address': data.address,
            'city': data?.city?.value ? data.city.value : '',
            'zip': data.zip,
            'state': data?.state?.value ? data.state.value : '',
            'country_code': data?.country?.isoCode ? data.country.isoCode : '',
            'state_code': data?.state?.isoCode ? data.state.isoCode : '',
        }
        /*if (data.media && data.media.length > 0) {
            let uploadImage = await UploadImageToS3(data?.media);
            if (uploadImage) {
                if (!uploadImage.success) {
                    Toast(uploadImage.message, false);
                    return false;
                }
                params.image = uploadImage.data;
            }
        }*/

        updateProfile(isCustomerLogin.id, params)
    }

    const onRemove = () => {
        updateProfile(isCustomerLogin.id, {
            'image': null,
        })
        setProfile('');
        setSelectedAvatar('');
    }

    const updateProfile = async (id, data) => {
        await UpdateProfile(id, data).then((data) => {
            if (data.status) {
                createOrUpdateUser(data.data).then(() => {
                    Toast(data.message, true);
                    setIsCustomerLogin(data.data)
                    reset();
                    //navigate('/myprofile');
                })
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*const handleChange = (event) => {
        if (event.target.files[0]) {
            setProfile(URL.createObjectURL(event.target.files[0]));
        } else {
            setProfile(false);
        }
    }*/

    const AvatarRadios = ({img, value, name, index, setSelectedAvatar}) => {
        const handleAvatarClick = () => {
            setSelectedAvatar(value)
        }
        return (
            <Col sm={2}>
                <div className={"LabelAvatar"}>
                    <input type={"radio"} className={"inputAvatar"} id={`avatarInput${index}`} name={"avatarInput"}
                           value={value} aria-label={`option ${index}`}/>
                    <Form.Label htmlFor={`avatarInput${index}`} onClick={(value) => handleAvatarClick()}>
                        <img src={img} alt={name} className={"img-fluid"}/>
                    </Form.Label>
                </div>
            </Col>
        )
    }

    const SelectAvatarModal = ({selectedAvatar, setSelectedAvatar}) => {
        const onSelect = () => {
            if (selectedAvatar) {
                setProfile(selectedAvatar);
                setAvatarModalShow(false)
            } else {
                setProfile(false);
                Toast("Select an avatar", true)
            }
        }

        return (
            <div className={"AvatarSelectModal"}>
                <form className={"AvatarModalForm"}>
                    <Row className={"g-1"}>
                        {
                            avatars && avatars.map((avatar, index) => {
                                return (
                                    <AvatarRadios key={index}
                                                  index={index}
                                                  img={avatar.image}
                                                  value={avatar.image}
                                                  name={avatar.name}
                                                  setSelectedAvatar={setSelectedAvatar}/>
                                )
                            })
                        }
                    </Row>
                    <Button type={"button"} className={"btn-solid w-100"} onClick={onSelect}>Select Avatar</Button>
                </form>
            </div>
        )
    }

    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={5} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>
                                    <AccSidebar userData={isCustomerLogin}/>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={8} xl={{span: 7, offset: 1}}
                                     className={"col-xxl-7 offset-xxl-1"}>
                                    <h1>Profile Information</h1>
                                    <form className={"profileInfoForm"} autoComplete={"off"}
                                          onSubmit={handleSubmit(onSubmit)}>
                                        <h4>Profile picture</h4>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                                <div className={"profileChange"}>
                                                    <div className={"imgContainer"}>
                                                        <img className={"img-fluid"}
                                                             src={profile ? profile : isCustomerLogin?.image || profileImg}
                                                             alt={"profileImg"}/>
                                                    </div>
                                                    <div className={"w-100"}>
                                                        <Row className={"g-1"}>
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Button type={"button"} className={"btnSolid w-100"}
                                                                        onClick={() => setAvatarModalShow(true)}
                                                                        style={{
                                                                            paddingLeft: '0',
                                                                            paddingRight: '0',
                                                                            textAlign: 'center',
                                                                            marginBottom: '0',
                                                                        }}>
                                                                    Change Avatar
                                                                </Button>
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Button onClick={() => onRemove()} type={"button"}
                                                                        className={"btnSolid active w-100"} style={{
                                                                    paddingLeft: '0',
                                                                    paddingRight: '0',
                                                                    textAlign: 'center'
                                                                }}>
                                                                    Remove Avatar
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Button disabled={disableButton} type={"submit"}
                                                                className={"btnSolid w-100"}>
                                                            Save Profile
                                                        </Button>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <h4>Status</h4>
                                        <div className={"checkBox-container m-b-20"}>
                                            <div className={"checkBox-Group"}>
                                                <FormLabel className={""} htmlFor={"showName"}>
                                                    Show Name
                                                </FormLabel>
                                                <Form.Check
                                                    defaultChecked={isCustomerLogin.show_name}
                                                    type="switch"
                                                    id="showName"
                                                    {...register("show_name")}
                                                />
                                            </div>
                                        </div>
                                        <h4>Account info</h4>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formNameF"
                                                label="First Name"
                                            >
                                                <Form.Control type="text" autoComplete={"off"} placeholder="First Name"
                                                              defaultValue={isCustomerLogin?.first_name}
                                                              {...register("fName",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.NAME,
                                                                          message: VALIDATIONS_TEXT.NAME_MAX
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.FNAME_REQUIRED
                                                                      },
                                                                      pattern: {
                                                                          value: /^[^\s]+(?:$|.*[^\s]+$)/,
                                                                          message: "Entered value cant start/end white spacing"
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.fName &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.fName.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formNameL"
                                                label="Last Name"
                                            >
                                                <Form.Control type="text" autoComplete={"off"} placeholder="Last Name"
                                                              defaultValue={isCustomerLogin?.last_name}
                                                              {...register("lName",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.NAME,
                                                                          message: VALIDATIONS_TEXT.NAME_MAX
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.LNAME_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.lName &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.lName.message}</span>}
                                        </div>

                                        <div className='mb13 select-box'>
                                            <Controller
                                                control={control}
                                                name="country"
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.COUNTRY_REQUIRED,
                                                    },
                                                }}
                                                render={({field}) => (
                                                    <Select
                                                        {...field}
                                                        className="react-select-container w-100"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select Country"
                                                        options={Country.getAllCountries().flatMap((item) => (
                                                            {
                                                                label: item?.name,
                                                                value: item?.name,
                                                                ...item,
                                                            }
                                                        ))}
                                                        /*onChange={() => {
                                                            setValue("state", null)
                                                            setValue("city", null)
                                                        }}*/
                                                    />
                                                )}
                                            />
                                            {
                                                errors.country &&
                                                <span className={"validation-error"}
                                                      role="alert">{errors.country.message}</span>}
                                        </div>

                                        <div className='mb13 select-box'>
                                            <Controller
                                                control={control}
                                                name="state"
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: VALIDATIONS_TEXT.STATE_REQUIRED,
                                                    },
                                                }}
                                                /*onChange={() => {
                                                    setValue("city", null)
                                                }}*/
                                                render={({field}) => (
                                                    <Select
                                                        defaultValue={isCustomerLogin?.state}
                                                        {...field}
                                                        className="react-select-container w-100"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select State"
                                                        options={State.getStatesOfCountry(country?.isoCode).flatMap((item) => (
                                                            {
                                                                label: item?.name,
                                                                value: item?.name,
                                                                ...item,
                                                            }
                                                        ))}
                                                    />
                                                )}
                                            />
                                            {errors.state &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.state.message}</span>}
                                        </div>

                                        <div className='mb13 select-box'>
                                            <Controller
                                                defaultValue={isCustomerLogin?.city}
                                                control={control}
                                                name="city"
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: VALIDATIONS_TEXT.CITY_REQUIRED,
                                                    },
                                                }}
                                                render={({field}) => (
                                                    <Select
                                                        {...field}
                                                        className="react-select-container w-100"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select City"
                                                        options={City.getCitiesOfState(country?.isoCode, state?.isoCode).flatMap((item) => (
                                                            {
                                                                label: item?.name,
                                                                value: item?.name,
                                                                ...item,
                                                            }
                                                        ))}
                                                    />
                                                )}
                                            />
                                            {errors.city &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.city.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formZip"
                                                label="Zip"
                                            >
                                                <Form.Control type="text" autoComplete={"off"} placeholder="Zip"
                                                              defaultValue={isCustomerLogin?.zip}
                                                              className={"formZip"}
                                                              {...register("zip",
                                                                  {
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.ZIP_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.zip &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.zip.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formAddress"
                                                label="Address"
                                            >
                                                <Form.Control type="text" autoComplete={"off"} placeholder="Address"
                                                              defaultValue={isCustomerLogin?.address}
                                                              className={"formAddress"}
                                                              {...register("address",
                                                                  {
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.ADDRESS_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.address &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.address.message}</span>}
                                        </div>


                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formEmail"
                                                label="Email address"
                                            >
                                                <Form.Control type="text" autoComplete={"off"}
                                                              placeholder="Email address"
                                                              defaultValue={isCustomerLogin?.email}
                                                              {...register("email",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.EMAIL,
                                                                          message: VALIDATIONS_TEXT.EMAIL_MAX
                                                                      },
                                                                      pattern: {
                                                                          value: Patterns.Email,
                                                                          message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.email &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.email.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formUsername"
                                                label="Username"
                                            >
                                                <Form.Control type="text" autoComplete={"off"} placeholder="Username"
                                                              defaultValue={isCustomerLogin?.username}
                                                              {...register("userName",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.USERNAME,
                                                                          message: VALIDATIONS_TEXT.USERNAME_MAX
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.USERNAME_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.userName &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.userName.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formUsername"
                                                label="Date of birth"
                                            >
                                                <Form.Control type="date" autoComplete={"off"}
                                                              placeholder="Date of birth"
                                                              max={moment().subtract(constants.DATE_AGE_LIMIT, 'years').format('YYYY-MM-DD')}
                                                              defaultValue={isCustomerLogin.dob ? new Date(isCustomerLogin.dob).toISOString().split('T')[0] : ''}
                                                              {...register("dob",
                                                                  {
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.DOB_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.dob &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.dob.message}</span>}
                                        </div>

                                        {/*<div className={"mb13"}>*/}
                                        {/*    <FloatingLabel*/}
                                        {/*        controlId="formCity"*/}
                                        {/*        label="City"*/}
                                        {/*    >*/}
                                        {/*        <Form.Control type="text" autoComplete={"off"} placeholder="City"*/}
                                        {/*                      defaultValue={isCustomerLogin?.city}*/}
                                        {/*                      className={"formCity"}*/}
                                        {/*                      {...register("city",*/}
                                        {/*                          {*/}
                                        {/*                              required: {*/}
                                        {/*                                  value: true,*/}
                                        {/*                                  message: VALIDATIONS_TEXT.CITY_REQUIRED*/}
                                        {/*                              },*/}
                                        {/*                          })*/}
                                        {/*                      }/>*/}
                                        {/*    </FloatingLabel>*/}
                                        {/*    {errors.city &&*/}
                                        {/*    <span className={"validation-error"}*/}
                                        {/*          role="alert">{errors.city.message}</span>}*/}
                                        {/*</div>*/}

                                        {/*<div className={"mb13"}>*/}
                                        {/*    <FloatingLabel*/}
                                        {/*        controlId="formState"*/}
                                        {/*        label="State"*/}
                                        {/*    >*/}
                                        {/*        <Form.Control type="text" autoComplete={"off"} placeholder="State"*/}
                                        {/*                      defaultValue={isCustomerLogin?.state}*/}
                                        {/*                      className={"formState"}*/}
                                        {/*                      {...register("state",*/}
                                        {/*                          {*/}
                                        {/*                              required: {*/}
                                        {/*                                  value: true,*/}
                                        {/*                                  message: VALIDATIONS_TEXT.STATE_REQUIRED*/}
                                        {/*                              },*/}
                                        {/*                          })*/}
                                        {/*                      }/>*/}
                                        {/*    </FloatingLabel>*/}
                                        {/*    {errors.state &&*/}
                                        {/*    <span className={"validation-error"}*/}
                                        {/*          role="alert">{errors.state.message}</span>}*/}
                                        {/*</div>*/}

                                        {/*<div className={"mb13"}>*/}
                                        {/*    <FloatingLabel*/}
                                        {/*        controlId="formLocation"*/}
                                        {/*        label="Location"*/}
                                        {/*    >*/}
                                        {/*        <Form.Select id={"formLocation"}*/}
                                        {/*                     defaultValue={isCustomerLogin.location}*/}
                                        {/*                     {...register("Select Country",*/}
                                        {/*                         {*/}
                                        {/*                             required: {*/}
                                        {/*                                 value: true,*/}
                                        {/*                                 message: VALIDATIONS_TEXT.LOCATION_REQUIRED*/}
                                        {/*                             },*/}
                                        {/*                         })*/}
                                        {/*                     }*/}
                                        {/*                     onChange={(e) => {*/}
                                        {/*                         const idx = e.target.selectedIndex;*/}
                                        {/*                         const option = e.target.querySelectorAll('option')[idx];*/}
                                        {/*                         const country_code = option.getAttribute('data-code');*/}
                                        {/*                         setCountryCode(country_code)*/}
                                        {/*                         setLocation(e.target.value)*/}
                                        {/*                     }}*/}
                                        {/*        >*/}
                                        {/*            <option>Country</option>*/}
                                        {/*            {*/}
                                        {/*                options && options.map((dt) => {*/}
                                        {/*                    return <option data-code={dt.value}*/}
                                        {/*                                   value={dt.label}>{dt.label}</option>*/}
                                        {/*                })*/}
                                        {/*            }*/}
                                        {/*        </Form.Select>*/}
                                        {/*<Form.Select aria-label="Location"
                                                             defaultValue={isCustomerLogin?.location}
                                                             {...register("location",
                                                                 {
                                                                     required: {
                                                                         value: true,
                                                                         message: VALIDATIONS_TEXT.LOCATION_REQUIRED
                                                                     },
                                                                 })
                                                             }>
                                                    <option>Select Location</option>
                                                    {
                                                        options && options.map((dt) => {
                                                            return <option value={dt.label}>{dt.label}</option>
                                                        })
                                                    }
                                                </Form.Select>*/}
                                        {/*    </FloatingLabel>*/}
                                        {/*    {errors.location &&*/}
                                        {/*    <span className={"validation-error"}*/}
                                        {/*          role="alert">{errors.location.message}</span>}*/}
                                        {/*</div>*/}
                                        <Button type={"submit"} className={"btn-solid w-100 m-t-30"}>
                                            Save
                                        </Button>
                                    </form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ThemeModal title={"Select Avatar"} content={<SelectAvatarModal selectedAvatar={selectedAvatar}
                                                                            setSelectedAvatar={setSelectedAvatar}/>}
                        className={"addCardInfo"} size={"sm"}
                        show={avatarModalShow}
                        onHide={() => {
                            setAvatarModalShow(false)
                            // reset()
                        }}/>
        </div>
    )
        ;
}

export default ProfileInformation;
