import React, {useState, useEffect, useContext} from 'react'
import {Button, ButtonToolbar, OverlayTrigger, Tooltip, Popover} from "react-bootstrap";
import {UpdateChatRequest} from "../../../services/chat_request.service";
import {constants} from "../../../utils/constants";
import {change_message_status, changeFlag} from "../../../services/firebase.service";
import {Toast} from "../../../utils/helpers";
import {GetProfile} from "../../../services/user.service";
import {IsCustomerLogin} from "../../../App";
import {EmptyLocalStorage} from "../../../services/authentication.service";
import {useNavigate} from "react-router-dom";
import {FaUndo} from "react-icons/fa";
import ThemeModal from "../../components/ThemeModal";

export const RefundToken = ({setAccLocked, activeThread, message}) => {
    const navigate = useNavigate();
    const [refundModal, setRefundModal] = useState(false);
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [disable, setDisable] = useState(false);
    /*
    * Refund Token component Modal
    * */
    const RefundModalContent = ({message}) => {
        return (
            <div className="UnblockModal">
                <p className={"text-center"}>Are you sure you want to<br/> refund tokens of this message??</p>
                <div className="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={disable} className="btn-rounded-outline"
                            onClick={() => {
                                setDisable(true)
                                refundTokens(message)
                            }}>Refund</Button>
                    <Button className="btn-solid" onClick={() => setRefundModal(false)}>Cancel</Button>
                </div>
            </div>
        )
    }

    const refundTokens = async (message) => {
        await UpdateChatRequest(message.chatRequestId, {
            status: constants.CHAT_REQUEST_STATUS.LOCKED,
        }).then((data) => {
            if (data.status) {
                change_message_status({
                    activeThread: activeThread,
                    timestamp: message.document_id,
                    status: constants.CHAT_REQUEST_STATUS.LOCKED,
                    messageReply: ''
                })

                changeFlag(message.idFrom, activeThread);
                changeFlag(message.idTo, activeThread);

                Toast(data.message, true);
                getProfile();
                setRefundModal(false);
                setAccLocked(false)
            } else {
                Toast(data.message, false);
                setRefundModal(false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getProfile = async () => {
        await GetProfile().then(async (result) => {
            if (result.status) {
                if (result.data?.profile) {
                    let dt = result.data?.profile;
                    if (dt) {
                        //await SetAuthUser(dt)
                        await setIsCustomerLogin(dt)
                    }
                }
            }
        }).catch((error) => {
            //SetAuthUser(null)
            setIsCustomerLogin(null)
        })
    }

    return (
        <>
            {/*<OverlayTrigger placement="top"
                            delay={{ hide: 0, show: 0 }}
                            overlay={(props) => <Tooltip id="refund-tooltip" {...props}>Refund Tokens</Tooltip>}>
                <Button type={"button"}
                        className={"btn-settings"}
                        onClick={() => {
                            setDisable(false)
                            setRefundModal(true)
                        }}
                ><FaUndo/></Button>
            </OverlayTrigger>*/}
            <Button type={"button"}
                        className={"btn-settings"}
                        onClick={() => {
                            setDisable(false)
                            setRefundModal(true)
                        }}
                    title="Refund Tokens"
                ><FaUndo/></Button>
            <ThemeModal title={""} content={<RefundModalContent message={message}/>}
                        className={"addCardInfo"} size={"sm"}
                        show={refundModal}
                        onHide={() => {
                            setRefundModal(false)
                            // reset()
                        }}/>
        </>
    )
}