import React, {useState, useEffect} from 'react'
import {FaReply} from "react-icons/fa";
import {constants} from "../../../utils/constants";
import {checkDeleteUser} from "../../../services/firebase.service";
import {Toast} from "../../../utils/helpers";

export const ShowReplyOrLocked = ({isCustomerLogin, message, showMessageBar, setReplyMessage, chatInput, reply, showReply, activeCustomer}) => {
    useEffect(() => {
        checkReplyOrLocked()
    }, [])

    const checkReplyOrLocked = () => {
        if (isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL
            && message.chatStatus == constants.CHAT_REQUEST_STATUS.REQUESTED) {
            let diff = Math.abs(message.message_timestamp - new Date().getTime()) / 3600000;

            if (message.lockMessage == 0) {
                //Set Lock 1
                if (parseInt(diff) >= constants.ACCOUNT_LOCK_HOURS) {
                    showReply(false);
                } else {
                    showReply(true);
                }
            } else if (message.lockMessage == 1) {
                //Set Lock 2
                if (parseInt(diff) >= constants.ACCOUNT_LOCK_FIVE_HOURS) {
                    showReply(false);
                } else {
                    showReply(true);
                }
            }
        } else {
            if (isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL) {
                if (message.chatStatus != constants.CHAT_REQUEST_STATUS.LOCKED) {
                    showReply(true);
                }
            } else {
                showReply(false);
            }
        }
    }

    return (
        reply && <div title="Reply Message" className={`options ${message.deleted_at == '' ? 'd-none' : 'd-block'}`}
                      onClick={async () => {
                          let checkDel = await checkDeleteUser(activeCustomer.id);
                          if (checkDel) {
                              Toast(`This account has been deleted by the user`, false);
                              return false;
                          }
                          showMessageBar(false)
                          setReplyMessage(message)
                          chatInput.current.value = ''
                      }}>
            <FaReply/>
        </div>
    )
}

