import React, {useState, useCallback} from 'react'
import {getImage} from "../../../services/firebase.service";
import ourChatAvatar from "../../../assets/images/others/image-not-found-lg.png"

export const GetThreadProfileImage = ({user_id, search}) => {
    const [image, setImage] = useState(null);

    useCallback(getImage(user_id, (data) => {
        setImage(data)
    }), [search])

    return (
        <>
            <img className={"img-fluid"}
                 src={image ? image : ourChatAvatar}
                 onError={({currentTarget}) => {
                     currentTarget.onerror = null; // prevents looping
                     currentTarget.src = ourChatAvatar;
                 }}
                 alt={"our chat"}/>
        </>
    )
}
