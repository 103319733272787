import React, {useContext, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {HiArrowSmLeft} from 'react-icons/hi';
import {LeftIcon, SuccessNotificationIcon} from "../components/SvgComponents";
import {EmptyLocalStorage, Logout} from "../../services/authentication.service";
import {activeInactiveUser} from "../../services/firebase.service";
import {Toast} from "../../utils/helpers";
import {IsCustomerLogin} from "../../App";
import {FaClock} from "react-icons/fa"

function NotVerifiedAccount() {
    const navigate = useNavigate();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);

    async function handleLogout() {
        await Logout().then(async (data) => {
            if (data.status) {
                await EmptyLocalStorage();
                Toast(data.message, true)
                activeInactiveUser(isCustomerLogin.id, false)
                setIsCustomerLogin(null)
                navigate('/');
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    if (isCustomerLogin && isCustomerLogin.is_approved === 1 && isCustomerLogin.is_verified === 1 && isCustomerLogin.is_profile_complete === 1) {
        navigate('/dashboard')
    } else {
        return (
            <div className={"d-flex justify-content-center"} style={{flexDirection: 'column',alignItems: 'center'}}>
                <div className={"m-b-20"}>
                    <FaClock style={{fontSize:'50px', color:'#ea7217'}} />
                </div>
                <h1 className={"text-center"}>Pending Account</h1>

                <div className={"filler m-t-30"}></div>

                <div style={{textAlign:'center', marginLeft:'20px', marginRight:'20px'}}>
                    Your account needs to be verified from OurChat Team. You'll receive an email once it's verified and
                    then
                    you can log-in with the same credentials.
                </div>
                <div className={"mx-auto text-center mt-4"}>
                        <span onClick={() => {
                            handleLogout()
                            // navigate('/')
                        }} style={{cursor: 'pointer'}}>
                            <span style={{marginRight: '10px'}}><LeftIcon/></span> Back to Sign In
                        </span>
                </div>
            </div>
        );
    }
}

export default NotVerifiedAccount;
