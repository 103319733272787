import {createContext, useEffect, useState} from "react";
import RouteConfig from "./RouteConfig";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/our-chat.css';
import {GetCurrentUser} from "./services/authentication.service";
import {GetProfile} from "./services/user.service";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {getUser} from "./services/firebase.service";

const IsCustomerLogin = createContext();
//const stripePromise = loadStripe("pk_test_51LQJsWG9u0JG5wXrJfqmsW8F5ollsvaqvWgHtYznqYn8cxyJ1NIkmuAsA6hGO0cCHfbSQwKjEVEVxFgQ0L1ec7g400TNqik3ds");
const stripePromise = loadStripe("pk_live_51LQJsWG9u0JG5wXrJiR5cTOtG1z923Q4iZEVVsPdNV2su1HzaJCQA5X5nt36T323ESOr0cgwjbAVmpShZztilDTb00tAwHxesb");

function App() {
    const [isCustomerLogin, setIsCustomerLogin] = useState(null);
    const current_user = GetCurrentUser()

    useEffect(() => {
        if (current_user) {
            getProfile()
        }
    }, [setIsCustomerLogin])

    const getProfile = async () => {
        await GetProfile().then((result) => {
            if (result.status) {
                if (result.data?.profile) {
                    let dt = result.data?.profile;
                    if (dt) {
                        setIsCustomerLogin(dt)
                    }
                }
            }
        }).catch((error) => {
            setIsCustomerLogin(null)
        })
    }

    return (
        <div className="App">
            <IsCustomerLogin.Provider value={[isCustomerLogin, setIsCustomerLogin]}>
                <Elements stripe={stripePromise}>
                    <RouteConfig/>
                </Elements>
            </IsCustomerLogin.Provider>
        </div>
    );
}

export default App;
export {
    IsCustomerLogin
}
