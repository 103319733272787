import React, {useState, useEffect, useCallback} from 'react'
import {ReceivedComponent} from "./ReceivedComponent";
import {SentComponent} from "./SentComponent";
import ScrollableFeed from 'react-scrollable-feed'
import {lockFlag} from "../../../services/firebase.service";

const ChatConversation = ({
                              messages,
                              activeThread,
                              isCustomerLogin,
                              activeCustomerDetail,
                              messageBar,
                              showMessageBar,
                              replyMessage,
                              setReplyMessage,
                              chatInput,
                              setUnlockModal,
                              resetMessages,
                              setResetMessage,
                              requestBox,
                              isBlocked,
                              reportType,
                              setReportType
                          }) => {

    const chatBox = (message, key) => {
        if (message.contentType == 'sent') {
            return (
                <ReceivedComponent
                    message={message}
                    isCustomerLogin={isCustomerLogin}
                    img={activeCustomerDetail?.id.toString() || ''}
                    content={message.content}
                    userActive={true}
                    key={key}
                    activeThread={activeThread}
                    activeCustomerDetail={activeCustomerDetail}
                    messageBar={messageBar}
                    showMessageBar={showMessageBar}
                    replyMessage={replyMessage}
                    setReplyMessage={setReplyMessage}
                    chatInput={chatInput}
                    isBlocked={isBlocked}

                    reportType={reportType}
                    setReportType={setReportType}
                />
            )
        } else {
            return (
                <SentComponent
                    message={message}
                    isCustomerLogin={isCustomerLogin}
                    img={isCustomerLogin?.id.toString() || ''}
                    content={message.content}
                    userActive={true}
                    key={key}
                    activeThread={activeThread}

                    messageBar={messageBar}
                    showMessageBar={showMessageBar}
                    replyMessage={replyMessage}
                    setReplyMessage={setReplyMessage}
                    chatInput={chatInput}
                    setUnlockModal={setUnlockModal}
                    resetMessages={resetMessages}
                    setResetMessage={setResetMessage}
                    isBlocked={isBlocked}

                />
            )
        }
    }

    return (
        <>
            <ul className={"chatList"}>
                <ScrollableFeed>
                    <div style={{paddingBottom: '70px', paddingTop: '50px'}}>
                        {
                            messages && messages.length > 0 ?
                                messages.map((message, key) => {
                                    if (requestBox) {
                                        //console.log(requestBox);
                                        if (requestBox == 10) {
                                            if (message.chatStatus == 10 && !message.lockFlag) {
                                                if (message.contentType == 'sent') {
                                                    return (
                                                        <ReceivedComponent
                                                            message={message}
                                                            isCustomerLogin={isCustomerLogin}
                                                            img={activeCustomerDetail?.id.toString() || ''}
                                                            content={message.content}
                                                            userActive={true}
                                                            key={key}
                                                            activeThread={activeThread}
                                                            activeCustomerDetail={activeCustomerDetail}
                                                            messageBar={messageBar}
                                                            showMessageBar={showMessageBar}
                                                            replyMessage={replyMessage}
                                                            setReplyMessage={setReplyMessage}
                                                            chatInput={chatInput}
                                                            isBlocked={isBlocked}

                                                            reportType={reportType}
                                                            setReportType={setReportType}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <SentComponent
                                                            message={message}
                                                            isCustomerLogin={isCustomerLogin}
                                                            img={isCustomerLogin?.id.toString() || ''}
                                                            content={message.content}
                                                            userActive={true}
                                                            key={key}
                                                            activeThread={activeThread}

                                                            messageBar={messageBar}
                                                            showMessageBar={showMessageBar}
                                                            replyMessage={replyMessage}
                                                            setReplyMessage={setReplyMessage}
                                                            chatInput={chatInput}
                                                            setUnlockModal={setUnlockModal}
                                                            resetMessages={resetMessages}
                                                            setResetMessage={setResetMessage}
                                                            isBlocked={isBlocked}

                                                        />
                                                    )
                                                }
                                            }
                                        }
                                        else if (requestBox == 20) {
                                            if (message.chatStatus == 20 || message.messageReply != '') {
                                                if (message.contentType == 'sent') {
                                                    return (
                                                        <ReceivedComponent
                                                            message={message}
                                                            isCustomerLogin={isCustomerLogin}
                                                            img={activeCustomerDetail?.id.toString() || ''}
                                                            content={message.content}
                                                            userActive={true}
                                                            key={key}
                                                            activeThread={activeThread}
                                                            activeCustomerDetail={activeCustomerDetail}
                                                            messageBar={messageBar}
                                                            showMessageBar={showMessageBar}
                                                            replyMessage={replyMessage}
                                                            setReplyMessage={setReplyMessage}
                                                            chatInput={chatInput}
                                                            isBlocked={isBlocked}

                                                            reportType={reportType}
                                                            setReportType={setReportType}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <SentComponent
                                                            message={message}
                                                            isCustomerLogin={isCustomerLogin}
                                                            img={isCustomerLogin?.id.toString() || ''}
                                                            content={message.content}
                                                            userActive={true}
                                                            key={key}
                                                            activeThread={activeThread}

                                                            messageBar={messageBar}
                                                            showMessageBar={showMessageBar}
                                                            replyMessage={replyMessage}
                                                            setReplyMessage={setReplyMessage}
                                                            chatInput={chatInput}
                                                            setUnlockModal={setUnlockModal}
                                                            resetMessages={resetMessages}
                                                            setResetMessage={setResetMessage}
                                                            isBlocked={isBlocked}

                                                        />
                                                    )
                                                }
                                            }
                                        } else if (requestBox == 30) {
                                            if (message.chatStatus == 10 && message.lockFlag) {
                                                if (message.contentType == 'sent') {
                                                    return (
                                                        <ReceivedComponent
                                                            message={message}
                                                            isCustomerLogin={isCustomerLogin}
                                                            img={activeCustomerDetail?.id.toString() || ''}
                                                            content={message.content}
                                                            userActive={true}
                                                            key={key}
                                                            activeThread={activeThread}
                                                            activeCustomerDetail={activeCustomerDetail}
                                                            messageBar={messageBar}
                                                            showMessageBar={showMessageBar}
                                                            replyMessage={replyMessage}
                                                            setReplyMessage={setReplyMessage}
                                                            chatInput={chatInput}
                                                            isBlocked={isBlocked}

                                                            reportType={reportType}
                                                            setReportType={setReportType}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <SentComponent
                                                            message={message}
                                                            isCustomerLogin={isCustomerLogin}
                                                            img={isCustomerLogin?.id.toString() || ''}
                                                            content={message.content}
                                                            userActive={true}
                                                            key={key}
                                                            activeThread={activeThread}

                                                            messageBar={messageBar}
                                                            showMessageBar={showMessageBar}
                                                            replyMessage={replyMessage}
                                                            setReplyMessage={setReplyMessage}
                                                            chatInput={chatInput}
                                                            setUnlockModal={setUnlockModal}
                                                            resetMessages={resetMessages}
                                                            setResetMessage={setResetMessage}
                                                            isBlocked={isBlocked}

                                                        />
                                                    )
                                                }
                                            }
                                        }
                                    } else {
                                        if (message.contentType == 'sent') {
                                            return (
                                                <ReceivedComponent
                                                    message={message}
                                                    isCustomerLogin={isCustomerLogin}
                                                    img={activeCustomerDetail?.id.toString() || ''}
                                                    content={message.content}
                                                    userActive={true}
                                                    key={key}
                                                    activeThread={activeThread}
                                                    activeCustomerDetail={activeCustomerDetail}
                                                    messageBar={messageBar}
                                                    showMessageBar={showMessageBar}
                                                    replyMessage={replyMessage}
                                                    setReplyMessage={setReplyMessage}
                                                    chatInput={chatInput}
                                                    isBlocked={isBlocked}

                                                    reportType={reportType}
                                                    setReportType={setReportType}
                                                />
                                            )
                                        } else {
                                            return (
                                                <SentComponent
                                                    message={message}
                                                    isCustomerLogin={isCustomerLogin}
                                                    img={isCustomerLogin?.id.toString() || ''}
                                                    content={message.content}
                                                    userActive={true}
                                                    key={key}
                                                    activeThread={activeThread}
                                                    messageBar={messageBar}
                                                    showMessageBar={showMessageBar}
                                                    replyMessage={replyMessage}
                                                    setReplyMessage={setReplyMessage}
                                                    chatInput={chatInput}
                                                    setUnlockModal={setUnlockModal}
                                                    resetMessages={resetMessages}
                                                    setResetMessage={setResetMessage}
                                                    isBlocked={isBlocked}

                                                />
                                            )
                                        }
                                    }
                                })
                                : <li style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'end',
                                    height: '100%',
                                    fontSize: '10px'
                                }}><span
                                    style={{display: 'block', marginBottom: '25px',}}>To begin a chat, send a message</span>
                                </li>
                        }
                    </div>
                </ScrollableFeed>
            </ul>
        </>
    )
}

export default ChatConversation
