import React, {useContext, useEffect, useState} from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Col, Container, Row, Button, Form, FloatingLabel, Offcanvas} from "react-bootstrap";
import LogoImg from '../../../assets/images/logo/logo.svg';
import {NavLink, useNavigate} from "react-router-dom";
import {
    InboxIcon,
    DashboardIcon,
    Star,
    SecurityIcon,
    PaymentIcon,
    SettingsIcon,
    ProfileInfoIcon,
    FaqIcon,
    SupportIcon,
    SignOutIcon,
    ArrowIcon,
    VisaIcon,
    BlockSqIcon,
    AddIcon,
    InboxIconOutline,
    FeedIcon, LogoSvg, LogoSvgIcon
} from "../SvgComponents";
import ProfileIcon from "../../../assets/images/others/image-not-found.png"
import ProfileIconFallback from "../../../assets/images/others/image-not-found.png"
import {Toast} from "../../../utils/helpers";
import {EmptyLocalStorage, Logout} from "../../../services/authentication.service";
import {IsCustomerLogin} from "../../../App";
import {constants} from "../../../utils/constants";
import {
    activeInactiveUser,
    get_chat_indicator,
    post_chat_indicator,
    updateTokens
} from "../../../services/firebase.service";
import ThemeModal from "../ThemeModal";
import {GetTokens, PurchaseTokens, PurchaseTokensWithTax} from "../../../services/token.service";
import {useForm} from "react-hook-form";
import {GetProfile, GetUserCards, PostUserCards} from "../../../services/user.service";
import {useStripe, useElements, CardElement} from "@stripe/react-stripe-js";
import {auth, googleProvider} from "../../../utils/firebase-config";


const NavLinks = [
    {
        type: "link",
        url: "/",
        urlText: "Home",
        disabled: false
    },
    {
        type: "link",
        url: "/our-team",
        urlText: "Our Team",
        disabled: false
    },
    {
        type: "link",
        url: "/why-ourchat",
        urlText: "Why OurChat",
        disabled: false
    },
    {
        type: "link",
        url: "/contact",
        urlText: "Contact Us",
        disabled: false
    },
    {
        type: "dropdown",
        url: "/",
        urlText: "Services",
        dropdownItems: [{
            url: "/service-01",
            urlText: "Service 01",
            disabled: false
        },
            {
                url: "/service-02",
                urlText: "Service 02",
                disabled: true
            }],
    }];

const NavBtns = [
    {
        btnType: "outline",
        btnText: "Login",
        btnUrl: "/login",
    },
    {
        btnType: "solid",
        btnText: "Sign up",
        btnUrl: "/sign-up",
    }];

const RenderProfileTile = (name, icon) => {
    return (
        <>
            <img className={"ProfileIcon"} src={icon} alt={""} title={""} onError={({currentTarget}) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = ProfileIconFallback;
            }}/>
            {name.substring(0, 15)}
        </>
    )
}


function UserNavigation({userData, updateCard, setUpdateCard}) {
    const navigate = useNavigate();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);

    const [tokenModalShow, setTokenModalShow] = useState(false)
    const [tokensList, setTokensList] = useState([])
    const [purchaseToken, setPurchaseToken] = useState([]);
    const [paymentModalShow, setPaymentModalShow] = useState(false);
    const [checked, setChecked] = useState(false);
    const [userCards, setUserCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [cardModalShow, setCardModalShow] = useState(false);
    const [chatIndicator, setChatIndicator] = useState(false);

    const [logoutModalShow, setLogoutModalShow] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const [showOffCanvas, setShowOffCanvas] = useState(false);

    const handleClose = () => setShowOffCanvas(false);
    const toggleShow = () => setShowOffCanvas(!showOffCanvas);

    const {register, handleSubmit, watch, reset, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        //getUserCards()
        getTokens()
        get_chat_indicator(userData.id.toString(), (new_message_notify) => {
            setChatIndicator(new_message_notify)
        })
    }, [])

    useEffect(() => {
        getUserCards()
    }, [paymentModalShow])

    /*
    * Add new Card Details
    * */
    const onCardDetailsSubmit = async (event) => {
        event.preventDefault();
        event.target.disabled = true

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });

        if (error) {
            event.target.disabled = false
            Toast(error.message, false);
            return false;
        }

        if (paymentMethod) {
            //console.log('payment method',paymentMethod);
            setIsLoading(true)
            await PostUserCards({
                payment_method_id: paymentMethod, //'pm_card_visa'
            }).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                    setUpdateCard(!updateCard);
                    getUserCards();
                    setCardModalShow(false);
                    setIsLoading(false);
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    console.log(error.response.data)
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    const CardModalContent = () => {
        let style = {
            base: {
                iconColor: '#3D2570',
                color: '#3D2570',
                fontWeight: '500',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                ':-webkit-autofill': {
                    fontWeight: '500',
                    color: '#3D2570',
                },
                '::placeholder': {
                    fontWeight: '500',
                    color: '#3D2570',
                },
            },
            invalid: {
                iconColor: '#E14339',
                color: '#E14339',
            },
        };
        return (
            <div className={"cardDetailsModal"}>
                <Form className={"addNewCardDetails"} autoComplete={"off"}>
                    <div className="p-3">
                        <CardElement options={{style: style}}
                                     className={`text-black themeCardStripe ${isLoading ? "d-none" : ''}`}/>
                        {
                            (isLoading)
                                ?

                                <Button className={"btn-solid w-100 btn-pay"}
                                        disabled={true}
                                >
                                    Processing...
                                </Button>

                                :

                                <Button className={"btn-solid w-100 btn-pay"}
                                        type="submit"
                                        onClick={(event) => {
                                            onCardDetailsSubmit(event)
                                        }}
                                        disabled={!stripe || !elements}>
                                    Add Card
                                </Button>
                        }

                    </div>
                </Form>
            </div>
        )
    }

    const getUserCards = async () => {
        await GetUserCards().then(async (result) => {
            if (result.status) {
                setUserCards(result.data)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getTokens = async () => {
        await GetTokens({status: 10}).then(async (result) => {
            if (result.status) {
                let tkn = [];
                //console.log(result.data)
                result.data && result.data.map((dt) => {
                    tkn.push({
                        id: dt.id,
                        tokenCount: dt.tokens,
                        pricePerToken: (dt.total_amount / dt.tokens).toFixed(2),
                        //price: dt.amount,
                        price: dt.total_amount,
                        saved_amount: dt.saved_amount,
                        isRecommended: dt.recommended == 1 ? true : false
                    })
                })
                setTokensList(tkn)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const handleTokenComponentClick = (data) => {
        setPurchaseToken(data);
        setTokenModalShow(false)
        setPaymentModalShow(true)
    }

    const TokenComponent = ({data, index}) => {
        return (
            <Form.Check type={"radio"} id={`token${index}`} className={"form-check-tokens"}>
                <Form.Check.Input className={data.isRecommended && "recommended"} type={"radio"}
                                  name={"selectedTokenPackage"}/>
                <Form.Check.Label className={data.isRecommended && "recommended"}>
                    <div className={"TokenCount"}>
                        <Star/>
                        <span className={"countContainer"}>
                            <span className={"count"}>{data.tokenCount}</span>
                            <span className={"text"}>tokens</span>
                        </span>
                    </div>
                    {/*<div className={"costPerToken"}>
                        <p>${data.pricePerToken} / token</p>
                    </div>*/}
                    <div className={"priceToken"}>
                        <p>
                            ${data.price.toFixed(2)}

                        </p>
                        <ArrowIcon/>
                    </div>
                </Form.Check.Label>
            </Form.Check>
        )
    }

    const PurchaseTokensModal = () => {
        return (
            <div className={"purchaseTokensModal"}>
                {/*<p>Quam pulvinar nullam quam nunc.</p>*/}
                <ul className={"list-unstyled listTokens"}>
                    {
                        tokensList.map((data, index) => (
                            <li key={index} onClick={() => handleTokenComponentClick(data)}>
                                <TokenComponent data={data} index={index}/>
                            </li>
                        ))
                    }

                </ul>
            </div>
        )
    }

    const PaymentModal = ({selectedCard, setSelectedCard, checked, setChecked}) => {
        return (
            <div className="mainDashboard accountSettings">
                <div className="ProfileInformation">
                    <div className="PaymentModal">
                        <div className="heading-container d-flex align-items-center justify-content-between py-2">
                            <div className="star-svg-container d-flex align-items-center">
                                <Star/>
                                <span className="ms-2"><b>{purchaseToken.tokenCount}</b> tokens</span>
                            </div>
                            <span><b>${purchaseToken.price.toFixed(2)}</b></span>
                        </div>

                        {/*<h4 className={"m-t-40 mb-4"}>Payment methods</h4>

                        <ul className={"list-unstyled listCards"}>
                            {
                                userCards && userCards.map((data, index) => {
                                    console.log("nav cards list: ", data);
                                    console.log("nav selected Card: ", selectedCard.id);
                                    return (
                                        <li key={index}>
                                            <Form.Check type={"radio"}
                                                        id={`payment${index}`}
                                                        className={"form-check-payments"}
                                                        name={"payment"}

                                            >
                                                <Form.Check.Label>
                                                    <VisaIcon/>
                                                    <sup>***</sup> {data.last_four}
                                                </Form.Check.Label>
                                                <div>
                                                    <Form.Check.Input
                                                        autoFocus
                                                        type={"radio"}
                                                        name={"payment"}
                                                        defaultChecked={selectedCard && selectedCard.id === data.id}
                                                        onChange={(e) => {
                                                            setChecked(!checked);
                                                            setSelectedCard(data)
                                                        }}
                                                    />
                                                </div>
                                            </Form.Check>
                                        </li>
                                    )
                                })
                            }
                            <li>
                                <div className={"addNewPayment"}
                                     onClick={() => setCardModalShow(true)}>
                                    <div className={"svgContainer"}>
                                        <AddIcon/>
                                        <span><b>Add debit / credit card</b></span>
                                    </div>
                                    <div>
                                        <ArrowIcon/>
                                    </div>
                                </div>
                            </li>
                        </ul>*/}

                        <div className="btn-container d-flex align-items-center">
                            <Button className="btn-rounded-outline me-3"
                                    onClick={() => {
                                        setSelectedCard([])
                                        setChecked(false)
                                        handleBackButtonClick()
                                    }}>Back</Button>
                            <Button className="btn-solid" disabled={isLoading}
                                    onClick={() => {
                                        handlePurchaseButtonClick()
                                        /*if (selectedCard && selectedCard.id) {
                                            handlePurchaseButtonClick()
                                        } else {
                                            Toast("Please select a card.", false);
                                        }*/
                                    }}>Purchase {purchaseToken.tokenCount} tokens <small>(${purchaseToken.price.toFixed(2)})</small></Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const handleBackButtonClick = () => {
        setTokenModalShow(true)
        setPaymentModalShow(false)
    }

    const handlePurchaseButtonClick = async () => {
        if (purchaseToken) {
            setIsLoading(true)
            await PurchaseTokensWithTax({
                token_id: purchaseToken.id,
                return_url: window.location.href
            }).then((data) => {
                if (data.status) {
                    setIsLoading(false)
                    //redirect to data.data.url
                    if (data.data.url) {
                        setPaymentModalShow(false)
                        //setSuccessModalShow(true)
                        setChecked(false)
                        getProfile(true);
                        window.location.replace(data.data.url);
                    } else {
                        Toast("Some error occured please refresh page and try again!", false);
                    }
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    /*const handlePurchaseButtonClick = async () => {
        if (purchaseToken, selectedCard) {
            setIsLoading(true)

            /!*await PurchaseTokensWithTax({
                token_id: purchaseToken.id
            }).then((data) => {
                if (data.status) {
                    if (data && data.data.url) {
                        console.log(data.data.url)
                        setPaymentModalShow(false)
                        setSuccessModalShow(true)
                        setChecked(false)
                        setSelectedCard([])
                        setIsLoading(false)
                        window.location.href = data.data.url;
                    }
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })*!/


            await PurchaseTokens({
                payment_method_id: selectedCard.payment_method_id,
                token_id: purchaseToken.id
            }).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                    getProfile();
                    setPaymentModalShow(false)
                    setSuccessModalShow(true)
                    setChecked(false)
                    setSelectedCard([])
                    setIsLoading(false)
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }*/

    const getProfile = async () => {
        await GetProfile().then(async (result) => {
            if (result.status) {
                if (result.data?.profile) {
                    updateTokens(result.data.profile.id, result.data.profile.total_tokens);
                    let dt = result.data?.profile;
                    if (dt) {
                        await setIsCustomerLogin(dt)
                    }
                }
            }
        }).catch((error) => {
            setIsCustomerLogin(null)
        })
    }

    const LogoutModal = () => {
        return (
            <div className={"purchaseTokensModal text-center"}>
                <p>Are you sure want to logout? </p>
                <div class="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={isLoading} className="btn-rounded-outline"
                            onClick={() => handleLogout()}>Logout</Button>
                    <Button className="btn-rounded-outline" onClick={() => {
                        setLogoutModalShow(false)
                    }}>Cancel</Button>
                </div>
            </div>
        )
    }


    async function handleLogout() {
        //console.log('here')
        await Logout().then(async (data) => {
            if (data.status) {
                auth.signOut().then(() => {
                   // console.log('logout')
                    googleProvider.signOut();
                });
                await EmptyLocalStorage();
                Toast(data.message, true)
                activeInactiveUser(isCustomerLogin.id, false)
                setIsCustomerLogin(null)
                navigate('/');
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                //console.log(error);
                return Toast(error.response.data.message, false);
            }
        })
    }

    const handleOffCanvasSelect = (eventKey) => console.log(`selected ${eventKey}`);

    return (
        <>
            <Navbar bg="light" expand="md" fixed="top" className={"ourChatNav ProfessionalNav d-none d-md-block"}>
                <Container fluid>
                    <NavLink
                        to={isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ? "/explore" : "/dashboard"}>
                        <img src={LogoImg} alt={"Our Chat"} className={"img-fluid"}/>
                    </NavLink>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                        >
                            <NavLink
                                onClick={() => {
                                    post_chat_indicator(isCustomerLogin.id.toString(), 0)
                                }}
                                to={"/inbox"} className={`nav-link inboxIco ${chatIndicator ? 'hasNewMessages' : ''}`}>
                                <span><InboxIconOutline/></span>
                                Inbox
                            </NavLink>
                            {
                                isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                    <NavLink to={"/explore"} className={"nav-link feedIco"}>
                                        <FeedIcon/> Explore
                                    </NavLink> :
                                    <NavLink to={"/dashboard"} className={"nav-link feedco"}>
                                        <FeedIcon/> Dashboard
                                    </NavLink>

                            }
                        </Nav>
                        <div className="d-flex align-items-center">
                        <span className={"profileStats"} onClick={() => {
                            setTokenModalShow(true);
                        }}>
                            <Star/> <span>{userData?.total_tokens || 0}</span>
                        </span>
                            <NavDropdown align={"end"}
                                         style={{paddingLeft: '20px'}}
                                         title={RenderProfileTile(userData?.name || 'Profile', userData?.image || ProfileIcon)}
                                         id={"ProfileDropdown"} className={"ProfileDropdown"}
                                         autoClose={"auto"}>
                                {
                                    isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                        <NavDropdown.Item onClick={() => navigate('/myprofile')}>
                                            <ProfileInfoIcon/> Profile
                                        </NavDropdown.Item> :
                                        <NavDropdown.Item onClick={() => navigate('/profile-information')}>
                                            <ProfileInfoIcon/> Profile
                                        </NavDropdown.Item>
                                }
                                <NavDropdown.Item onClick={() => navigate('/security')}>
                                    <SecurityIcon/> Security
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/payment')}>
                                    <PaymentIcon/> Payment
                                </NavDropdown.Item>
                                {/*<NavLink to={"/settings"} className={"dropdown-item"}>
                                <SettingsIcon/> Settings
                            </NavLink>*/}
                                <NavDropdown.Item onClick={() => navigate('/blocked-users')}>
                                    <BlockSqIcon/> Blocked Users
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={() => navigate('/faqs')}>
                                    <FaqIcon/> FAQ
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/support')}>
                                    <SupportIcon/> Support
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={() => {
                                    setLogoutModalShow(true)
                                }}>
                                    <SignOutIcon/> Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </Navbar.Collapse>
                </Container>
                <ThemeModal title={"Purchase tokens"} content={<PurchaseTokensModal/>} className={"addCardInfo"}
                            size={"sm"}
                            show={tokenModalShow}
                            onHide={() => {
                                setTokenModalShow(false)
                                reset()
                            }}/>
                <ThemeModal title={"Purchase tokens"}
                            content={<PaymentModal checked={checked} setChecked={setChecked} selectedCard={selectedCard}
                                                   setSelectedCard={setSelectedCard}/>}
                            className={"addCardInfo"}
                            size={"sm"}
                            show={paymentModalShow}
                            onHide={() => {
                                setPaymentModalShow(false)
                                setChecked(false)
                                setSelectedCard([])
                                reset()
                            }}/>
                <ThemeModal title={"Add Card Details"} content={<CardModalContent/>} className={"addCardInfo"}
                            size={"md"}
                            show={cardModalShow}
                            onHide={() => {
                                setCardModalShow(false)
                                reset()
                            }}/>
            </Navbar>
            {/*Mobile Nav Bar*/}
            <Navbar expand="lg" fixed="top" variant="light" bg="light" className={"ourChatNavMobile d-block d-md-none"}>
                <Container>
                    {
                        isCustomerLogin ? <Navbar.Brand
                            href={isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ? "/explore" : "/dashboard"}
                            className={"logoMobile"}>
                            <LogoSvg/>
                        </Navbar.Brand> : <Navbar.Brand href="/" className={"logoMobile"}>
                            <LogoSvg/>
                        </Navbar.Brand>
                    }

                    <div className={"ms-auto mobileNavs"}>
                        <span className={"profileStats"} onClick={() => {
                            setTokenModalShow(true);
                        }}>
                            <Star/> <span>{userData?.total_tokens || 0}</span>
                        </span>
                        <div onClick={() => toggleShow()}>
                            <img className={"img-fluid profileImg"} src={userData?.image || ProfileIcon}/>
                        </div>
                    </div>
                </Container>
            </Navbar>
            <Offcanvas show={showOffCanvas} onHide={handleClose} className={"offCanvasBar"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <LogoSvg/>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className={"navOffCanvas"} variant="pills" activeKey="auto" autoClose={"auto"}
                         onSelect={handleOffCanvasSelect}>
                        <Nav.Item className={`inboxIcon ${chatIndicator ? "hasNewMessages" : ''}`}>
                            <Nav.Link eventKey="1" href={"/inbox"}>
                                <span><InboxIconOutline/></span> Inbox
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={"feedIcon"}>
                            <Nav.Link eventKey="2" href={"/explore"}>
                                <FeedIcon/> Explore
                            </Nav.Link>
                        </Nav.Item>
                        {
                            isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                <Nav.Item>
                                    <Nav.Link eventKey="3" onClick={() => {
                                        toggleShow()
                                        navigate('/myprofile')
                                    }}>
                                        <ProfileInfoIcon/> Profile
                                    </Nav.Link>
                                </Nav.Item> : <Nav.Item>
                                    <Nav.Link eventKey="3" onClick={() => {
                                        toggleShow()
                                        navigate('/profile-information')
                                    }}>
                                        <ProfileInfoIcon/> Profile
                                    </Nav.Link>
                                </Nav.Item>
                        }

                        <Nav.Item>
                            <Nav.Link eventKey="4" onClick={() => {
                                toggleShow()
                                navigate('/security')
                            }}>
                                <SecurityIcon/> Security
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="5" onClick={() => {
                                toggleShow()
                                navigate('/payment')
                            }}>
                                <PaymentIcon/> Payment
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="6" onClick={() => {
                                toggleShow()
                                navigate('/blocked-users')
                            }}>
                                <BlockSqIcon/> Blocked Users
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="7" onClick={() => {
                                toggleShow()
                                navigate('/faqs')
                            }}>
                                <FaqIcon/> FAQ
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="8" onClick={() => {
                                toggleShow()
                                navigate('/support')
                            }}>
                                <SupportIcon/> Support
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="9" onClick={handleLogout}>
                                <SignOutIcon/> Sign Out
                            </Nav.Link>
                        </Nav.Item>
                        {/*<NavDropdown title="Dropdown" id="nav-dropdown">*/}
                        {/*    <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Item eventKey="4.2">Another action</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Item eventKey="4.3">Something else here</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Divider />*/}
                        {/*    <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item>*/}
                        {/*</NavDropdown>*/}
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
            <ThemeModal title={"Logout"} content={<LogoutModal/>} className={"addCardInfo"}
                        size={"sm"}
                        show={logoutModalShow}
                        onHide={() => {
                            setLogoutModalShow(false)
                        }}/>
            {/*Mobile Nav Bar*/}
        </>
    );
}

export default UserNavigation;
