import React, {useEffect, useState} from 'react';
import {constants} from "../../../../utils/constants";
import moment from "moment";
import ProfilePlaceholder from "../../../../assets/images/others/image-not-found.png";
import {Row, Col} from "react-bootstrap";
import {RequestBox} from "./RequestBox";
import {EmptyListIcon} from "../../../components/SvgComponents";
import {useNavigate} from "react-router-dom";
import {
    getChatCounts,
} from "../../../../services/firebase.service";
import {PendingRequest} from "./PendingRequest";

export const Revenue = ({user_id, stats, blockCount, requestData, requestCount}) => {
    const navigate = useNavigate()




    return (
        <Row className={"m-t-20"}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className={""}>
                <h4>Revenue</h4>
                <div className={"statBox"}>
                    <Row className={"align-items-center"}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2>${stats.total_revenue ? parseFloat(stats.total_revenue).toFixed(2) : '0.00'}</h2>
                            <p>All time revenue</p>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ul className={"list-unstyled listStats"}>
                                <li>
                                    <div>
                                        <span>Today</span>
                                        <span>${stats.today_revenue ? parseFloat(stats.today_revenue).toFixed(2) : '0.00'}</span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span>This week</span>
                                        <span>${stats.weekly_revenue ? parseFloat(stats.weekly_revenue).toFixed(2) : '0.00'}</span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span>This month</span>
                                        <span>${stats.monthly_revenue ? parseFloat(stats.monthly_revenue).toFixed(2) : '0.00'}</span>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                <div className={"spacer"} style={{marginTop: "30px"}}></div>
                <h4>Charity Revenue</h4>
                <div className={"statBox"}>
                    <Row className={"align-items-center"}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2>${stats.total_charity_revenue ? parseFloat(stats.total_charity_revenue).toFixed(2) : '0.00'}</h2>
                            <p>All time charity revenue</p>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ul className={"list-unstyled listStats"}>
                                <li>
                                    <div>
                                        <span>Today</span>
                                        <span>${stats.today_charity_revenue ? parseFloat(stats.today_charity_revenue).toFixed(2) : '0.00'}</span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span>This week</span>
                                        <span>${stats.weekly_charity_revenue ? parseFloat(stats.weekly_charity_revenue).toFixed(2) : '0.00'}</span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span>This month</span>
                                        <span>${stats.monthly_charity_revenue ? parseFloat(stats.monthly_charity_revenue).toFixed(2) : '0.00'}</span>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>

                <PendingRequest stats={stats} user_id={user_id} requestCount={requestCount} blockCount={blockCount}/>
            </Col>
            <Col xs={12} sm={12} md={6} lg={{span: 5, offset: 1}} xl={{span: 5, offset: 1}}>

                <div className={"spacer"} style={{marginTop: "50px"}}></div>
                <h4>Latest Requests</h4>
                <div className={`requestList`}>
                    <div className={"requestsContainer"}>
                        <ul className={"tilesList"}>
                            {
                                requestData && requestData.length > 0 ? requestData.map((requests, index) => {
                                    if (requests.status != constants.CHAT_REQUEST_STATUS.ACCEPTED) {
                                        return (
                                            <RequestBox
                                                isActive={requests.isActive}
                                                img={requests?.user?.image || ProfilePlaceholder}
                                                isUnderAge={moment().diff(requests?.user?.dob, 'years', false) <= 18 ? 'Under 18' : ''}
                                                name={requests?.user?.name}
                                                lastSeen={requests.created_ago}
                                                lastMessage={requests?.last_message || "N/A"}
                                                statusBar={requests.status}
                                                userId={requests?.user?.id || ''}
                                                remaining_time={requests.remaining_time}
                                            />
                                        )
                                    }
                                }) : <div className={`requestList isEmpty`}>
                                    <div className={"EmptyIconBox h-100"}>
                                        <div
                                            className={"mx-auto text-center h-100 d-flex flex-column align-contents-center justify-content-center align-items-center"}>
                                            <EmptyListIcon/>
                                            <p>No request yet.</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </ul>
                        {
                            /*requestData && requestData.length > 0 ?
                            <Button onClick={() => {
                            clickLoadMore()
                            }} type={"button"} className={"grey-btn w-100"}>
                            Load more
                            </Button> : <span>No Request Found</span>*/
                        }
                    </div>
                </div>
            </Col>
        </Row>
    )
}
