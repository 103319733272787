import React, {useState, useEffect} from 'react'
import {checkDeleteUser, updateReaction} from "../../../services/firebase.service";
import {ReactionBarSelector} from '@charkour/react-reactions';
import {FaRegSmile, FaLock, FaClock, FaCheck} from "react-icons/fa";
import {TbMessageReport} from "react-icons/tb";
import {GetThreadProfileImage} from "./GetThreadProfileImage";
import {constants} from "../../../utils/constants";
import {Tooltip} from "react-bootstrap";
import {ShowReplyOrLocked} from "./ShowReplyOrLocked";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {ShowTimeOrLockedStatus} from "./ShowTimeOrLockedStatus";
import {ShowReaction} from "./ShowReaction";
import {MessageReport} from "./MessageReport";
import {ReportIcon} from "../../components/SvgComponents";
import {Toast} from "../../../utils/helpers";

export const ReceivedComponent = (props) => {
    const [reactVisible, setReactVisible] = useState(false);
    const [reply, showReply] = useState(false);
    const [icon, showIcon] = useState(false);
    const [lock, setLock] = useState(false);
    const [modalReportShow, setModalReportShow] = useState(false);
    const [reportMessage, setReportMessage] = useState("");

    useEffect(() => {
        // let diff = Math.abs(props.message.message_timestamp - new Date().getTime()) / 3600000;
        // if (props.message.lockMessage == 0) {
        //     if (parseInt(diff) >= 3600000 * constants.ACCOUNT_LOCK_HOURS) {
        //         setLock(true);
        //     }
        // } else if (props.message.lockMessage == 1) {
        //     if (parseInt(diff) >= 3600000 * constants.ACCOUNT_LOCK_FIVE_HOURS) {
        //         setLock(true);
        //     }
        // }
    }, [])

    const updateReact = (reaction) => {
        let update_reactions = [];

        if (props.message.reactions) {
            update_reactions = props.message.reactions.filter(function (react) {
                return react.id !== props.isCustomerLogin.id
            })
        }

        let data = {
            activeThread: props.activeThread,
            timestamp: props.message.timestamp,
            reactions: [...update_reactions, {
                id: props.isCustomerLogin.id,
                name: props.isCustomerLogin.name || props.isCustomerLogin.username,
                react: reaction
            }]
        }
        updateReaction(data, (data) => {
            setReactVisible(false);
        })
    }


    const renderEmoji = (props) => {
        return (
            <Tooltip id="emoji" {...props}>{props.name}</Tooltip>
        )
    };

    return (
        <li className={`full-msg received /*isActive*/`} onMouseLeave={() => setReactVisible(false)}>
            <div className={"MessageTile"}>
                <div className={"imgContainer"}>
                    <div className="imgTile">
                        <GetThreadProfileImage user_id={props.img}/>
                    </div>
                </div>

                <div className={"MessageContainer"}>
                    {
                        props.isCustomerLogin && props.isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                            <div className={"replyOnBox"}>
                                <div className={"repliedMessage"}>
                                    {props.message.messageReply ? props.message.messageReply : ''}
                                </div>
                            </div> : ''
                    }

                    <p>{props.content}</p>

                    {/*timestamp*/}
                    <div className={"timeStamp"}>
                        <span
                            className={"messageTime"}>{moment(props.message.timestamp).format("YYYY-MM-DD hh:mm a")}</span>
                        <span className={"timeOrLocked"}>
                            {
                                props.isCustomerLogin &&
                                props.isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL
                                &&
                                <ShowTimeOrLockedStatus
                                    isCustomerLogin={props.isCustomerLogin}
                                    activeThread={props.activeThread}
                                    message={props.message}
                                    icon={icon}
                                    showIcon={showIcon}
                                    reply={reply}
                                />
                            }
                        </span>
                    </div>

                    <div className={`ReactionBar ${!reactVisible ? "d-none" : ""}`}>
                        <ReactionBarSelector iconSize={16}
                                             active={props.reaction}
                                             onSelect={(reactions) => {
                                                 updateReact(reactions)
                                             }}
                        />
                    </div>

                    <ShowReaction props={props}/>

                </div>

                {/*{
                    !props.isBlocked && <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '15%',
                    }}>
                        {
                            !lock && <>
                                <div style={{marginLeft: '10px', cursor: 'pointer'}} className={`${!reply ? 'd-none' : 'd-block'}`}>
                                    <div className={"emoji"} onClick={() => setReactVisible(!reactVisible)}
                                         onBlur={() => setReactVisible(!reactVisible)}>
                                        <FaRegSmile/>
                                    </div>
                                </div>

                                <div style={{marginLeft: '10px', cursor: 'pointer'}} className={`${!reply ? 'd-none' : 'd-block'}`}>
                                    <ShowReplyOrLocked
                                        isCustomerLogin={props.isCustomerLogin}
                                        message={props.message}
                                        chatInput={props.chatInput}
                                        setReplyMessage={props.setReplyMessage}
                                        showMessageBar={props.showMessageBar}
                                        reply={reply}
                                        showReply={showReply}
                                    />
                                </div>
                            </>
                        }


                        <div style={{marginLeft: '10px'}} className={`${!reply ? 'd-none' : 'd-block'}`}>
                            <div style={{cursor: 'pointer'}} onClick={() => {
                                setReportMessage(props.content)
                                setModalReportShow(true)
                            }}>
                                <ReportIcon/>
                            </div>
                        </div>

                    </div>
                }*/}

                <div style={{display: `${props.isBlocked || lock ? 'none' : ''}`}} className={"MessageOptions"}>

                    <div className={"emoji"} onClick={() => setReactVisible(!reactVisible)}
                         onBlur={() => setReactVisible(!reactVisible)}>
                        <FaRegSmile/>
                    </div>

                    <ShowReplyOrLocked
                        isCustomerLogin={props.isCustomerLogin}
                        message={props.message}
                        chatInput={props.chatInput}
                        setReplyMessage={props.setReplyMessage}
                        showMessageBar={props.showMessageBar}
                        reply={reply}
                        showReply={showReply}
                        activeCustomer={props.activeCustomerDetail}
                    />

                    <div title="Report Message" className={"MessageOptions"} style={{cursor: 'pointer'}}
                         onClick={async () => {
                             let checkDel = await checkDeleteUser(props.activeCustomerDetail.id);
                             if (checkDel) {
                                 Toast(`This account has been deleted by the user`, false);
                                 return false;
                             }
                             setReportMessage(props.content)
                             setModalReportShow(true)
                         }}>
                        <TbMessageReport style={{fontSize: '20px'}}/>
                    </div>
                </div>

                {/*<div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{display: `${props.isBlocked || lock ? 'none' : ''}`}} className={"MessageOptions"}>

                        <div className={"emoji"} onClick={() => setReactVisible(!reactVisible)}
                             onBlur={() => setReactVisible(!reactVisible)}>
                            <FaRegSmile/>
                        </div>

                        <ShowReplyOrLocked
                            isCustomerLogin={props.isCustomerLogin}
                            message={props.message}
                            chatInput={props.chatInput}
                            setReplyMessage={props.setReplyMessage}
                            showMessageBar={props.showMessageBar}
                            reply={reply}
                            showReply={showReply}
                        />

                        <div className={"MessageOptions"} style={{cursor: 'pointer'}} onClick={() => {
                            setReportMessage(props.content)
                            setModalReportShow(true)
                        }}>
                            <TbMessageReport style={{fontSize: '20px'}}/>
                        </div>
                    </div>


                </div>*/}
            </div>
            <MessageReport
                userId={props.img}
                activeCustomerDetail={props.activeCustomerDetail}
                isCustomerLogin={props.isCustomerLogin}
                modalReportShow={modalReportShow}
                setModalReportShow={setModalReportShow}
                reportMessage={reportMessage}
                setReportMessage={setReportMessage}
                reportType={props.reportType}
                setReportType={props.setReportType}
            />
        </li>
    )
}
