import {constants, apiUrl} from '../utils/constants';
import {DELETE, GET, POST, PUT} from './api.service.wrapper';
import {SetAuthUser} from "./authentication.service";

export const GetProfile = async () => {
    let response = await GET(apiUrl.profile);
    return response;
}

export const UpdateProfile = async (id, data) => {
    let response = await PUT(apiUrl.users, id, data);
    await SetAuthUser(JSON.stringify(response?.data));
    return response;
}

export const GetUser = async (params) => {
    let response = await GET(apiUrl.users, params);
    return response;
}

export const GetUserById = async (id) => {
    let response = await GET(apiUrl.users + "/" + id);
    return response;
}

export const PostBlockUser = async (blocked_user_id) => {
    const response = await POST(apiUrl.blocked_users, {blocked_user_id});
    return response;
}

export const DeleteBlockUser = async (blocked_user_id) => {
    const response = await DELETE(apiUrl.unblock_user, blocked_user_id);
    return response;
}

export const GetBlockUserById = async (blocked_user_id) => {
    const response = await GET(apiUrl.get_block_user_by_id + "/" + blocked_user_id);
    return response;
}

export const GetUserCards = async () => {
    let response = await GET(apiUrl.user_cards);
    return response;
}

export const PostUserCards = async (params) => {
    let response = await POST(apiUrl.user_cards, params);
    return response;
}

export const DeleteUserCard = async (card_id) => {
    const response = await DELETE(apiUrl.user_cards, card_id);
    return response;
}

export const DeleteUserAccount = async (user_id) => {
    const response = await DELETE(apiUrl.delete_user_account, user_id);
    return response;
}

export const GetPages = async (params) => {
    const response = await GET(apiUrl.pages, params);
    return response;
}

export const GetAllPages = async (params) => {
    const response = await GET(apiUrl.get_pages, params);
    return response;
}

export const GetUserExplore = async (params) => {
    let response = await GET(apiUrl.user_explore, params);
    return response;
}

export const GetUserExploreListing = async (params) => {
    let response = await GET(apiUrl.user_explore_listing, params);
    return response;
}

export const GetUserSearch = async (params) => {
    let response = await GET(apiUrl.user_search, params);
    return response;
}

/*export const PostContact = async () => {
    let response = await GET(apiUrl.profile);
    return response;
}*/
