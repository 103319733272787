import firebase from 'firebase';
import firestore from 'firebase/firestore';
import {get_firebase_token} from "./helpers";

// Initialize Cloud Firestore through Firebase
const firebaseConfig = {
    apiKey: "AIzaSyBG9aBPtOcoY5dcVApTqMsh1quQ_U5lQY8",
    authDomain: "our-chat-36fc5.firebaseapp.com",
    databaseURL: "https://our-chat-36fc5-default-rtdb.firebaseio.com",
    projectId: "our-chat-36fc5",
    storageBucket: "our-chat-36fc5.appspot.com",
    messagingSenderId: "138925262838",
    appId: "1:138925262838:web:429135e52e528a237169d7",
    measurementId: "G-NS7506W6HF"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();

export const auth = new firebase.auth();

get_firebase_token((token) => {
    return auth.signInWithCustomToken(token)
});

export const appleProvider = new firebase.auth.OAuthProvider('apple.com');
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();


