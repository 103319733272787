import React, {useContext, useEffect, useRef, useState} from "react";
import {Dropdown, DropdownButton, Form, Button, Container} from "react-bootstrap";
import {
    AddIcon,
    ArchiveIcon,
    ArrowIcon,
    CancelCircle,
    CancelIcon,
    ChevronDownCircle,
    DollarIcon,
    EmptyListIcon,
    LeftIcon,
    LockedIcon,
    SearchIcon,
    SendIcon,
    Star,
    SuccessIcon,
    UnlockedIcon,
    VerticalIcon,
    VisaIcon
} from "../components/SvgComponents";
import Profile01 from "../../assets/images/others/feed/feed1.png"
import ourChatAvatar from "../../assets/images/others/image-not-found-lg.png"
import ourChatAvatarList from "../../assets/images/others/our-chat-avatar.png"
import {IsCustomerLogin} from "../../App";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ChatList from "./components/ChatList";
import ArchieveList from "./components/ArchieveList";
import {
    archiveUserChatList,
    change_message_status,
    checkDeleteUser,
    creatProfessionalChatList,
    fetchServerTime,
    getThreadList,
    getThreadMessages,
    getUser,
    isOnline,
    lockThread,
    post_chat_indicator,
    removeEmptyMessageThreads,
    removeScheduleOnReplyMessage,
    submitMessage,
    unlockMessage,
    updateLastMessage,
    updateScheduler,
    updateTokens
} from "../../services/firebase.service";
import ChatConversation from "./components/ChatConversation";
import {
    GetBlockUserById,
    GetPages,
    GetProfile,
    GetUserById,
    GetUserCards,
    PostUserCards
} from "../../services/user.service";
import moment from "moment";
import {constants} from "../../utils/constants";
import {EmptyLocalStorage, GetCurrentUser, SetAuthUser} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {
    ChatNotifications,
    GetChatRequestLimit,
    PostChatRequest,
    UpdateChatRequest
} from "../../services/chat_request.service";
import ThemeModal from "../components/ThemeModal";
import {GetTokens, PurchaseTokens, PurchaseTokensWithTax} from "../../services/token.service";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useForm} from "react-hook-form";
import {FaList} from "react-icons/fa"
import placeholderImg from "../../assets/images/placeholders/placeholder1.png";
import {MessageInput} from "./components/MessageInput";
import {StatusBar} from "./components/StatusBar";
import ChatUserProfile from "./UserProfile";
import {GetOffensiveWords} from "../../services/offensive_words.service";
import {LoadingComponent} from "../components/LoadingComponent";
import randomPoppers from "../../assets/images/others/random-poppers.png"
import {PinnedTab} from "./components/PinnedTab";
import {SearchBar} from "./components/SearchBar";
import {GetReportType} from "../../services/report_type.service";

function Chat() {
    let navigate = useNavigate();
    let location = useLocation();
    const stripe = useStripe();
    const elements = useElements();
    const searchInputRef = useRef();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    //const isCustomerLogin = GetCurrentUser()
    const [searchBar, setSearchBar] = useState(false);
    const [searchReset, setSearchReset] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);
    const [chatActive, setChatActive] = useState(false);
    const [characterCount, setCharacterCount] = useState(0);
    const [archiveSidebar, setArchiveSidebar] = useState(false);
    const [accLocked, setaccLocked] = useState(false);
    const [search, setSearch] = useState(null);
    const [threads, setThreads] = useState(null);
    const [activeThread, setActiveThread] = useState(null);
    const [activeThreadData, setActiveThreadData] = useState(null);
    const [activeCustomer, setActiveCustomer] = useState(null);
    const [messages, setMessages] = useState(null);
    const [message, setMessage] = useState(false);
    const [textareaDisabled, setTextareaDisabled] = useState(false);
    const [activeCustomerDetail, setActiveCustomerDetail] = useState(null);
    const [isBlocked, setIsBlocked] = useState(false);
    const [submitModalShow, setSubmitModalShow] = useState(false);
    const [disable, setDisable] = useState(false);
    const chatInput = useRef({});
    const [lastMessage, setLastMessage] = useState(null);
    const [tokensList, setTokensList] = useState([]);
    const [purchaseToken, setPurchaseToken] = useState([]);
    const [tokenModalShow, setTokenModalShow] = useState(false);
    const [cardModalShow, setCardModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userCards, setUserCards] = useState([]);
    const [paymentModalShow, setPaymentModalShow] = useState(false);
    const [selectedCard, setSelectedCard] = useState([]);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [notEnoughTokens, setNotEnoughTokensShow] = useState(false);
    const [unlockModal, setUnlockModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [messageBar, showMessageBar] = useState(true);
    const [replyMessage, setReplyMessage] = useState('');
    const [offensiveWords, setOffensiveWords] = useState([]);
    const [offensiveWordsForModal, setOffensiveWordsForModal] = useState([]);
    const [offensiveWordsModal, setOffensiveWordsModal] = useState(false);
    const [messageReachedModal, setMessageReachedModal] = useState(false);
    const [messageCharacterModal, setMessageCharacterModal] = useState(false);
    const [searchMessage, setSearchMessage] = useState(null);
    const [mobileSidebarActive, setMobileSidebarActive] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    const [welcomeScreen, showWelcomeScreen] = useState(true);
    const [searchClear, setSearchClear] = useState(false);
    const [resetMessages, setResetMessage] = useState(false);
    const [requestBox, setRequestBox] = useState('');
    const [scroll, setScroll] = useState(false);
    const [change, setChange] = useState(false);
    const {reset, formState: {errors}} = useForm({
        mode: "onChange"
    });

    if (isCustomerLogin) {
        post_chat_indicator(isCustomerLogin.id.toString(), 0);
    }

    /*
    * Get Threads
    * */
    useEffect(() => {
        if (isCustomerLogin) {
            if (location?.state?.requestBox && isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL) {
                setRequestBox(location.state.requestBox);
            }
            getThreads();
            setPageLoading(false)
        }
    }, [search, isCustomerLogin])

    /*useEffect(() => {
        if (isCustomerLogin) {
            getThreads();
            setPageLoading(false)
            /!*setTimeout(() => {
                setPageLoading(false)
            }, [2000])*!/
        }
    }, [])*/


    /*
    * get active customers
    * */
    useEffect(() => {
        showMessageBar(true);
        getActiveCustomer();
    }, [activeThread, activeCustomer])

    /*
    * get messages
    * */
    useEffect(() => {
        getMessages();
    }, [activeCustomerDetail, searchMessage]) //activeCustomerDetail,

    /*
    * get blocked user by id
    * */
    useEffect(() => {
        if (activeCustomerDetail) {
            getBlockUserById()
        }
    }, [activeCustomerDetail, showProfile])

    /*
    * Get user cards and get token
    * */
    useEffect(() => {
        //getUserCards()
        getTokens()
    }, [])

    useEffect(() => {
        getUserCards()
    }, [paymentModalShow])

    /*
    * Get offensive words list
    * */
    useEffect(() => {
        getOffensiveWords();
    }, [])

    /*
    * Text Count
    * */
    const textCount = (count) => {
        setCharacterCount(count);
    }

    const [reportType, setReportType] = useState([]);

    useEffect(() => {
        getReportType()
    }, [])

    const getReportType = async () => {
        await GetReportType().then((data) => {
            if (data.status) {
                setReportType(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    * Get Offensive Words
    * */
    const getOffensiveWords = async () => {
        await GetOffensiveWords().then((data) => {
            if (data.status) {
                let offensive = []
                data.data && data.data.map((ow) => {
                    offensive.push(ow.keyword)
                })
                setOffensiveWords(offensive)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    * Get Blocked User
    * */
    const getBlockUserById = async () => {
        await GetBlockUserById(activeCustomerDetail.id).then((data) => {
            if (data.status) {
                data.data ? setIsBlocked(true) : setIsBlocked(false)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    * Get User By Id
    * */
    const getActiveCustomer = async () => {
        if (activeThread, activeCustomer) {
            await getUser(activeCustomer, (data) => {
                setActiveCustomerDetail(data)
            });
            /*await GetUserById(activeCustomer).then(async (result) => {
                if (result.status) {
                    if (result.data) {
                        let dt = result.data;
                        if (dt) {
                            //console.log("::::Active Customer::::", dt)
                            await setActiveCustomerDetail(dt)
                        }
                    }
                }
            }).catch((error) => {
                setActiveCustomerDetail(null)
            })*/
        }
    }

    /*
    * Fetch Chat List
    * */
    const getThreads = async () => {
        let is_new = location.state && location.state.is_new == 1 ? 1 : 0;
        if (is_new == 0) {
            await removeEmptyMessageThreads(isCustomerLogin.id.toString())
        }

        getThreadList(search, false, isCustomerLogin, async (data) => {
            if (location.state && location.state.user_id) {
                showWelcomeScreen(false)
                let setData = data[0];
                data.filter(async (dt) => {
                    if (location?.state?.user_id == dt.user_id) {
                        setData = dt
                    }
                })
                await setThreadData(setData)
            } else {
                await setThreadData(data[0])
            }

            data.map(async (dt) => {
                if (dt.user_id) {
                    await isOnline(dt.user_id, (online_data) => {
                        dt.online = online_data;
                    });
                }
            })
            setThreads(data);
        })
    }

    const setThreadData = (data) => {
        setActiveThread(data?.chat_id)
        setActiveThreadData(data)
        setActiveCustomer(data?.user_id)
    }

    /*
    * Get Archive Threads
    * */
    const getArchiveThreads = () => {
        getThreadList(search, true, isCustomerLogin, async (data) => {
            await setActiveThread(data[0]?.chat_id)
            await setActiveThreadData(data[0])
            await setActiveCustomer(data[0]?.user_id)
            setThreads(data);
        })
    }

    /*
    * Get all chat messages
    * */
    const getMessages = async () => {
        if (activeThread, activeCustomer) {
            setaccLocked(false);
            setLastMessage(null);
            setMessages(null)
            await getThreadMessages(searchMessage, activeThread, activeCustomer, isCustomerLogin, (data) => {
                if (data) {
                    setMessages(data ? data : []);
                }
            });
        }
    }

    /*
    * Get all archive messages
    * */
    const handleArchiveClick = () => {
        archiveUserChatList({
            user_id: isCustomerLogin.id,
            chat_id: activeThread,
            is_archive: archiveSidebar ? 0 : 1
        }).then(() => {
            archiveSidebar ? getArchiveThreads() : getThreads();
        })
    }

    /*
    * handle sidebar on mobile screen
    * */
    const handleSidebarClick = () => {
        console.log("clicked");
    }

    /*
    * on message key down
    * */
    const onKeyDownMessage = async (e) => {
        setMessage(e);
        if (e.keyCode == 13 && e.target.value.length > 0) {
            if (e.ctrlKey) {
                //todo: on press ctrl + enter break to new line
                return true;
            } else {
                const censoredWords = [];

                var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

                offensiveWords && offensiveWords.filter(word => {
                    if (e.target.value.includes(word)) {
                        censoredWords.push(word)
                    }
                });

                if (censoredWords && censoredWords.length > 0 || e.target.value.match(urlRegex)) {
                    setOffensiveWordsForModal(censoredWords)
                    setOffensiveWordsModal(true);
                    return false;
                }

                //for change offensive words to *****
                //let change = censored(offensiveWords, e.target.value);
                //return false;

                if (isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
                    //Check Professional Has Message Limit
                    await GetChatRequestLimit({
                        professional_id: activeCustomerDetail.id
                    }).then(async (data) => {
                        if (data.status) {
                            if (data.data.length == 0) {
                                await setSubmitModalShow(true);
                            } else {
                                //Enable Message Limit Reach Popup
                                setMessageReachedModal(true);
                                return false;
                            }
                        } else {
                            Toast(data.message, false);
                        }
                    }).catch((error) => {
                        if (error?.response?.status == 401) {
                            EmptyLocalStorage()
                            navigate('/');
                        } else {
                            return Toast(error?.response?.data?.message, false);
                        }
                    })
                } else {
                    await SubmitMessage(e)
                }
            }
        }
    }

    /*const censored = (badWordRegexes, input) => {
        let badWords = [];
        return badWordRegexes.reduce(
            // For each regex, replace all matches of the regex with "*"
            (input, regex) =>
                // As each regex is applied, the input cascades,
                // rolling all edits together
                input.replace(regex,
                    badWord => badWords.push(badWord)
                ),
            badWord
        );
    }*/

    /*
    * get profile
    * */
    const getProfile = async (purchase = false) => {
        await GetProfile().then(async (result) => {
            if (result.status) {
                if (result.data?.profile) {
                    if(purchase){
                        updateTokens(result.data.profile.id, result.data.profile.total_tokens);
                    }
                    let dt = result.data?.profile;
                    if (dt) {
                        //await SetAuthUser(dt)
                        await setIsCustomerLogin(dt)
                    }
                }
            }
        }).catch((error) => {
            //SetAuthUser(null)
            setIsCustomerLogin(null)
        })
    }

    const wordFilter = (str, rgx) => {
        return str.replace(rgx, "****");
    }

    /*
    * submit message
    * */
    const SubmitMessage = async (e) => {
        if (e.target.value.trim().length > 0) {
            setTextareaDisabled(true);
            e.preventDefault();
            let message = e.target.value.trim();

            if (isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL && message.length < constants.PROFESSIONAL_MIN_CHARACTER_LIMIT) {
                setMessageCharacterModal(true);
                setTextareaDisabled(false);
                return false;
            }

            //if user delete check
            let checkDel = await checkDeleteUser(activeCustomerDetail.id);
            if (checkDel) {
                Toast(`This account has been deleted by the user so message can't be sent`, false);
                setTextareaDisabled(false);
                return false;
            }

            if (isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
                await PostChatRequest({
                    professional_id: activeCustomer.toString(),
                    last_message: message.substring(0, 70),
                }).then((data) => {
                    if (data.status) {
                        let chatRequest = {
                            chatRequestId: data.data.id,
                            chatRequestCreatedAt: data.data.created_at,
                            chatStatus: constants.CHAT_REQUEST_STATUS.REQUESTED,
                            messageReply: ''
                        }
                        //Create Chat Thread For Professional
                        creatProfessionalChatList({
                            user_id: isCustomerLogin.id,
                            profileId: activeCustomerDetail.id,
                            username: isCustomerLogin?.name || isCustomerLogin?.username || '',
                        }).then(() => {
                            //Submit Message
                            message_post(chatRequest, message, e)

                            //Get Profile Data
                            getProfile(false)
                            setTextareaDisabled(false);
                            setCharacterCount(0)
                        })
                    } else {
                        Toast(data.message, false);
                        setTextareaDisabled(false);
                    }
                }).catch((e) => {
                    if (e.response.data.status === false && e.response.status === 400) {
                        setTokenModalShow(true)
                        //console.log("here phasing");
                    }
                })
            } else {
                if (!messageBar) {
                    if (replyMessage.chatStatus == constants.CHAT_REQUEST_STATUS.ACCEPTED) {
                        console.log('first')
                        let chatRequest = {
                            chatRequestId: replyMessage.chatRequestId,
                            chatRequestCreatedAt: replyMessage.chatRequestCreatedAt,
                            messageReply: replyMessage.content,
                            chatStatus: constants.CHAT_REQUEST_STATUS.ACCEPTED,
                        }

                        message_post(chatRequest, message, e)

                        showMessageBar(true)
                        change_message_status({
                            activeThread: activeThread,
                            timestamp: replyMessage.timestamp,
                            status: constants.CHAT_REQUEST_STATUS.ACCEPTED,
                            messageReply: message
                        })
                        //getProfile()
                        setTextareaDisabled(false);
                        setCharacterCount(0)
                    } else {
                        console.log('second')
                        await UpdateChatRequest(replyMessage.chatRequestId, {
                            status: constants.CHAT_REQUEST_STATUS.ACCEPTED,
                            //user_id: activeCustomer,
                            //professional_id: isCustomerLogin.id
                        }).then((data) => {
                            if (data.status) {
                                let chatRequest = {
                                    chatRequestId: replyMessage.chatRequestId,
                                    chatRequestCreatedAt: replyMessage.chatRequestCreatedAt,
                                    messageReply: replyMessage.content,
                                    chatStatus: constants.CHAT_REQUEST_STATUS.ACCEPTED,
                                }

                                message_post(chatRequest, message, e)

                                showMessageBar(true)
                                change_message_status({
                                    activeThread: activeThread,
                                    timestamp: replyMessage.timestamp,
                                    status: constants.CHAT_REQUEST_STATUS.ACCEPTED,
                                    messageReply: message
                                })

                                removeScheduleOnReplyMessage(replyMessage.timestamp)

                                getProfile(false)
                                setTextareaDisabled(false);
                                setCharacterCount(0)
                            } else {
                                Toast(data.message, false);
                                setTextareaDisabled(false);
                            }
                        })
                    }

                }
            }
        }
    }

    /*
    * message post
    * */
    const message_post = async (chatRequest = null, message, e) => {
        if (message) {
            e.target.value = '';
            let server_time = await fetchServerTime(isCustomerLogin.id.toString())
            let data = {
                chatId: activeThread.toString(),
                content: message.substring(0, constants.MESSAGE_CHARACTER_LIMIT),
                //content: activeCustomerDetail?.message_characters ? message.substring(0, activeCustomerDetail?.message_characters) : message,
                contentType: 'text',
                delete: 0,
                idFrom: isCustomerLogin.id.toString(),
                idTo: activeCustomer.toString(),
                isread: false,
                timestamp: server_time, //new Date().getTime(),
                message_timestamp: server_time, // new Date().getTime(),
                reactions: [],
                chatRequestId: chatRequest.chatRequestId ? chatRequest.chatRequestId : '',
                messageReply: chatRequest.messageReply ? chatRequest.messageReply : '',
                chatRequestCreatedAt: chatRequest.chatRequestCreatedAt ? chatRequest.chatRequestCreatedAt : '',
                chatStatus: chatRequest.chatStatus ? chatRequest.chatStatus : '',
                lockMessage: 0,
                lockFlag: 0
            }

            let submit_message = submitMessage(data, isCustomerLogin);
            submit_message.then(async (data) => {
                setTextareaDisabled(true);
                if (data) {
                    updateLastMessage(isCustomerLogin.id.toString(), activeThread.toString(), message.substring(0, 70))
                    updateLastMessage(activeCustomer.toString(), activeThread.toString(), message.substring(0, 70))
                    post_chat_indicator(activeCustomer.toString(), 1)

                    //push chat notification
                    await ChatNotifications({
                        user_to: activeCustomer.toString(),
                        user_from: isCustomerLogin.id.toString(),
                        chat_id: activeThread.toString(),
                    }).then((data) => {
                        if (data.status) {
                            //console.log('data======', data);
                        } else {
                            Toast(data.message, false);
                        }
                    })


                    e.target.value = '';
                    setTextareaDisabled(false);
                    e.target.focus();
                }
            });
        }
    }

    /*
    * Not enough tokens modal
    * */
    const NotEnoughTokensModal = () => {
        return (
            <div className="UnblockModal">
                <p className={"text-center"}>You dont have enough tokens! <br/> would you like to purchase tokens? </p>
                <div className="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button className="btn-rounded-outline" style={{padding: '13px 23px',}}
                            onClick={() => handlePurchaseBtn()}>Purchase Tokens</Button>
                    <Button className="btn-solid" onClick={() => setNotEnoughTokensShow(false)}>Cancel</Button>
                </div>
            </div>
        )
    }

    /*
    * handle submit if tokens are zero
    * */
    const handlePurchaseBtn = () => {
        // console.log("clicked");
        setTokenModalShow(true);
        setNotEnoughTokensShow(false);
    }

    /*
    * submit message component Modal
    * */
    const SubmitMessageModal = ({count}) => {
        return (
            <div className="UnblockModal">
                <p className={"text-center"}>Sending this message will cost<br/> {count} tokens </p>
                <div className="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={disable} className="btn-rounded-outline"
                            onClick={() => handleSubmit(count)}>Submit</Button>
                    <Button className="btn-solid" onClick={() => handleCancelSubmit()}>Cancel</Button>
                </div>
            </div>
        )
    }

    /*
    * submit step 1
    * */
    const handleSubmit = async (count) => {
        if (isCustomerLogin.total_tokens === 0 || isCustomerLogin.total_tokens < count) {
            //console.log("count is zero");
            setSubmitModalShow(false)
            setNotEnoughTokensShow(true);
        } else {
            setDisable(true)
            await SubmitMessage(message)
            setSubmitModalShow(false)
            setDisable(false)
        }
    }

    /*
    * Handle cancle Request
    * */
    const handleCancelSubmit = () => {
        setSubmitModalShow(false)
    }

    /*
    * reset text area
    * */
    const resetTextarea = (e) => {
        console.log(e.target.value);
    }

    /*
    * handle focus
    * */
    const handleFocus = () => {
        if (characterCount <= 0) {
            setInputFocus(false)
        }
    }

    /*
    * unlock chat
    * */
    const unlockChat = () => {
        lockThread(activeThread, 1, isCustomerLogin)
        //setaccLocked(false);
        if (lastMessage) {
            let data = {
                activeThread: activeThread,
                message_timestamp: lastMessage.document_id,
            }
            unlockMessage(data, (data) => {
                setaccLocked(false);
                setUnlockModal(false);
            })
        }
    }

    /*
    * submit message component Modal
    * */
    const UnlockModalContent = () => {
        return (
            <div className="UnblockModal">
                <p className={"text-center"}>Are you sure you want to<br/> Unlock this chat??</p>
                <div className="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={disable} className="btn-rounded-outline"
                            onClick={() => unlockChat()}>Unlock</Button>
                    <Button className="btn-solid" onClick={() => setUnlockModal(false)}>Cancel</Button>
                </div>
            </div>
        )
    }

    /*
    * offensive words component Modal
    * */
    const OffensiveModalContent = () => {
        return (
            <div className="UnblockModal">
                {/*<p className={"text-center"}>The message will not be sent<br/> The following illegal words were
                    found?</p>*/}
                <p className={"text-center"}>Hyperlinks and Profane language <br/> is not allowed</p>

                {/*{
                    offensiveWordsForModal && offensiveWordsForModal.length > 0 ?
                        offensiveWordsForModal.map((owfm) => {
                            return (
                                <div class={"offensive-tags"}>
                                    <span class={"tag"}>{owfm}</span>
                                </div>
                            )
                        })
                        : ''
                }*/}

                {/*<div className="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={disable} className="btn-rounded-outline"
                            onClick={() => unlockChat()}>Unlock</Button>
                    <Button className="btn-solid" onClick={() => setUnlockModal(false)}>Cancel</Button>
                </div>*/}
            </div>
        )
    }

    /*
    * message reached component Modal
    * */
    const MessageRequestReached = () => {
        return (
            <div className="MessageReachedModal">
                {/*<p className={"text-center"}>Maximum message limit has been reached!</p>*/}
                <p className={"text-center"}>The Professional's message limit has been reached. Please try again in the
                    future!</p>
            </div>
        )
    }

    const MessageMaximumCharacter = () => {
        return (
            <div className="MessageReachedModal">
                <p className={"text-center"}>Message must be greater or equal to 30 characters!</p>
            </div>
        )
    }

    /*
    * refund tokens
    * */
    const refundTokens = async () => {
        await UpdateChatRequest({
            status: constants.CHAT_REQUEST_STATUS.LOCKED,
            user_id: isCustomerLogin.id,
            //professional_id: activeCustomer
        }).then((data) => {
            if (data.status) {
                setTextareaDisabled(false);
                setCharacterCount(0)
                unlockChat(activeThread, 2, isCustomerLogin)
                setaccLocked(false)
                Toast(data.message, true);
            } else {
                Toast(data.message, false);
                //setTextareaDisabled(false);
            }
        })

    }

    /*
    * token component click
    * */
    const handleTokenComponentClick = (data) => {
        //console.log(data);
        setPurchaseToken(data);
        setTokenModalShow(false)
        setPaymentModalShow(true)
    }

    /*
    * get user cards
    * */
    const getUserCards = async () => {
        await GetUserCards().then(async (result) => {
            if (result.status) {
                setUserCards(result.data)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    * get tokens
    * */
    const getTokens = async () => {
        await GetTokens({status: 10}).then(async (result) => {
            if (result.status) {
                let tkn = [];
                result.data && result.data.map((dt) => {
                    tkn.push({
                        id: dt.id,
                        tokenCount: dt.tokens,
                        pricePerToken: (dt.total_amount / dt.tokens).toFixed(2),
                        //price: dt.amount,
                        price: dt.total_amount,
                        saved_amount: dt.saved_amount,
                        isRecommended: dt.recommended == 1 ? true : false
                    })
                })
                setTokensList(tkn)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    * Token component
    * */
    const TokenComponent = ({data, index}) => {
        return (
            <Form.Check type={"radio"} id={`token${index}`} className={"form-check-tokens"}>
                <Form.Check.Input className={data.isRecommended && "recommended"} type={"radio"}
                                  name={"selectedTokenPackage"}/>
                <Form.Check.Label className={data.isRecommended && "recommended"}>
                    <div className={"TokenCount"}>
                        <Star/>
                        <span className={"countContainer"}>
                            <span className={"count"}>{data.tokenCount}</span>
                            <span className={"text"}>tokens</span>
                        </span>
                    </div>
                    {/*<div className={"costPerToken"}>
                        <p>${data.pricePerToken} / token</p>
                    </div>*/}
                    <div className={"priceToken"}>
                        <p>
                            ${data.price.toFixed(2)} {/*<small>Save ${data.saved_amount}</small>*/}
                        </p>
                        <ArrowIcon/>
                    </div>
                </Form.Check.Label>
            </Form.Check>
        )
    }

    /*
    * purchase tokens
    * */
    const PurchaseTokensModal = () => {
        return (
            <div className={"purchaseTokensModal"}>
                {/*<p>Quam pulvinar nullam quam nunc.</p>*/}
                <ul className={"list-unstyled listTokens"}>
                    {
                        tokensList.map((data, index) => (
                            <li key={index} onClick={() => handleTokenComponentClick(data)}>
                                <TokenComponent data={data} index={index}/>
                            </li>
                        ))
                    }

                </ul>
            </div>
        )
    }

    /*
    * Card Modal Content
    * */
    const CardModalContent = () => {
        let style = {
            base: {
                iconColor: '#3D2570',
                color: '#3D2570',
                fontWeight: '500',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                ':-webkit-autofill': {
                    fontWeight: '500',
                    color: '#3D2570',
                },
                '::placeholder': {
                    fontWeight: '500',
                    color: '#3D2570',
                },
            },
            invalid: {
                iconColor: '#E14339',
                color: '#E14339',
            },
        };
        return (
            <div className={"cardDetailsModal"}>
                <Form className={"addNewCardDetails"} autoComplete={"off"}
                      onSubmit={() => handleSubmit(onCardDetailsSubmit)}>
                    <div className="p-3">
                        <CardElement options={{style: style}}
                                     className={`text-black themeCardStripe ${isLoading ? "d-none" : ''}`}/>
                        {
                            (isLoading)
                                ?

                                <Button className={"btn-solid w-100 btn-pay"}>
                                    Processing...
                                </Button>

                                :

                                <Button className={"btn-solid w-100 btn-pay"} type="submit"
                                        onClick={onCardDetailsSubmit}
                                        disabled={!stripe || !elements}>
                                    Add Card
                                </Button>
                        }

                    </div>
                </Form>
            </div>
        )
    }

    /*
    * Add new Card Details
    * */
    const onCardDetailsSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });


        if (paymentMethod) {
            setIsLoading(true)
            await PostUserCards({
                payment_method_id: paymentMethod, //'pm_card_visa',
            }).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                    getUserCards();
                    setCardModalShow(false);
                    setPaymentModalShow(true)
                    setIsLoading(false)
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    /*
    * handle back button click
    * */
    const handleBackButtonClick = () => {
        // console.log("back btn cliked");
        setTokenModalShow(true)
        setPaymentModalShow(false)
    }

    /*
    * handle purchase
    * */
    const handlePurchaseButtonClick = async () => {
        if (purchaseToken) {
            setIsLoading(true)
            await PurchaseTokensWithTax({
                token_id: purchaseToken.id,
                return_url: window.location.href
            }).then((data) => {
                if (data.status) {
                    setIsLoading(false)
                    //redirect to data.data.url
                    if (data.data.url) {
                        setPaymentModalShow(false)
                        //setSuccessModalShow(true)
                        setChecked(false)
                        getProfile(true);
                        window.location.replace(data.data.url);
                    } else {
                        Toast("Some error occured please refresh page and try again!", false);
                    }
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }
    /*const handlePurchaseButtonClick = async () => {
        // console.log("purchase button clicked");
        if (purchaseToken, selectedCard) {
            setIsLoading(true)
            await PurchaseTokens({
                payment_method_id: selectedCard.payment_method_id,
                token_id: purchaseToken.id
            }).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                    getProfile(true);
                    setPaymentModalShow(false)
                    setSuccessModalShow(true)
                    setChecked(false)
                    setSelectedCard([])
                    setIsLoading(false)
                }
            }).catch((error) => {
                setIsLoading(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }*/

    /*
    * Payment Modal
    * */
    const PaymentModal = ({selectedCard, setSelectedCard, checked, setChecked}) => {
        return (
            <div className="mainDashboard accountSettings">
                <div className="ProfileInformation">
                    <div className="PaymentModal">
                        <div className="heading-container d-flex align-items-center justify-content-between py-2">
                            <div className="star-svg-container d-flex align-items-center">
                                <Star/>
                                <span className="ms-2"><b>{purchaseToken.tokenCount}</b> tokens</span>
                            </div>
                            <span><b>${purchaseToken.price.toFixed(2)}</b></span>
                        </div>

                        {/*<h4 className={"m-t-40 mb-4"}>Payment methods</h4>

                        <ul className={"list-unstyled listCards"}>
                            {
                                userCards && userCards.map((data, key) => {
                                    return (
                                        <li key={key}>
                                            <Form.Check type={"radio"}
                                                        id={"applePay"}
                                                        className={"form-check-payments"}
                                            >
                                                <Form.Check.Label>
                                                    <VisaIcon/>
                                                    <sup>***</sup> {data.last_four}
                                                </Form.Check.Label>
                                                <Form.Check.Input
                                                    autoFocus
                                                    type={"radio"}
                                                    id={`payment-${key}`}
                                                    name={"payment"}
                                                    value={data.id}
                                                    defaultChecked={selectedCard && selectedCard.id === data.id}
                                                    onChange={(e) => {
                                                        setChecked(!checked);
                                                        setSelectedCard(data)
                                                    }}
                                                />
                                            </Form.Check>
                                        </li>
                                    )
                                })
                            }
                            <li>
                                <div className={"addNewPayment"}
                                     onClick={() => {
                                         setCardModalShow(true)
                                         setPaymentModalShow(false)
                                     }}>
                                    <div className={"svgContainer"}>
                                        <AddIcon/>
                                        <span><b>Add debit / credit card</b></span>
                                    </div>
                                    <div>
                                        <ArrowIcon/>
                                    </div>
                                </div>
                            </li>
                        </ul>*/}

                        <div className="btn-container d-flex align-items-center">
                            <Button className="btn-rounded-outline me-3"
                                    onClick={() => {
                                        setSelectedCard([]);
                                        setChecked(false);
                                        handleBackButtonClick();
                                    }}>Back</Button>
                            <Button className="btn-solid" disabled={isLoading}
                                    disabled={isLoading}
                                    onClick={() => {
                                        handlePurchaseButtonClick()
                                        /*if (selectedCard && selectedCard.id) {
                                            handlePurchaseButtonClick()
                                        } else {
                                            Toast("Please select a card.", false);
                                        }*/
                                    }}>Purchase {purchaseToken.tokenCount} tokens <small>(${purchaseToken.price.toFixed(2)})</small></Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /*
    * Success Modal
    * */
    const SuccessModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>Great</b></h2>
                    <p className={"m-b-20"}>You have
                        successfully <br/> purchased <b>{purchaseToken.tokenCount} tokens</b></p>
                    <Button className="btn-solid" onClick={() => {
                        setSuccessModalShow(false)
                        setTextareaDisabled(false)
                    }}>Done</Button>
                </div>
            </div>
        )
    }

    /*
    * Welcome Message
    * */
    const [page, setPage] = useState("");
    const [cancelSearch, showCancelSearch] = useState(false);
    const searchRef = useRef();

    useEffect(() => {
        getPages()
    }, [])

    const getPages = async () => {
        await GetPages({slug: 'welcome-message'}).then((data) => {
            if (data.status) {
                setPage(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const WelcomeMessage = ({page}) => {
        return (
            <div className={"ChatMainWindow"}>
                <div className={"chatTopBar"}>
                    <div className={"chatIntro"}>
                        <div className={"chatImgContainer"}>
                            <img className={"img-fluid"} src={ourChatAvatarList} alt={"our chat"}/>
                        </div>
                        <div className={"chatTitleContainer"}>
                            <p>{page?.title || ''}</p>
                        </div>
                    </div>
                    <div className={"ChatIconsContainer"}>
                        <div className={"dropdownContainer d-md-none"}
                             style={{cursor: 'pointer'}}
                             onClick={() => setMobileSidebarActive(!mobileSidebarActive)}>
                            <FaList/>
                        </div>
                    </div>
                </div>
                <div className={"chatWindow"}>
                    <ul className={"chatList"} style={{marginTop: '15px'}}>
                        <li className={"full-msg received welcomeMsg"}>
                            <div className={"MessageTile"}>
                                <div className={"imgContainer"}>
                                    <div className="imgTile">
                                        <img src={ourChatAvatarList} className={"img-fluid"} alt={"Our Chat"}/>
                                    </div>
                                </div>
                                <div className={"MessageContainer"}>
                                    {
                                        page?.content ?
                                            <div dangerouslySetInnerHTML={{__html: page?.content}}/>
                                            :
                                            <>
                                                <p><b>Welcome to OurChat!!</b></p><br/>
                                                <p>OurChat is working hard to bring value to influencers/athletes such
                                                    as yourself.
                                                    By joining early and pre-registering for our service we are proud to
                                                    offer:</p>
                                                <br/>
                                                <p> 1. A chance to take part in cross-industry events where
                                                    athletes and influencers
                                                    can network and gain access to new audiences.</p>
                                                <br/>
                                                <p>2. The ability to participate in the early stages of our merchandise
                                                    and
                                                    memorabilia services.</p>
                                                <br/>

                                                <p className={"mt-2"}>Email us for any query
                                                    at: <b>support@ourchat.us</b></p>
                                            </>
                                    }

                                    {/*<p><b>Welcome to OurChat!!</b> 👋</p>
                                    <p>OurChat is working hard to bring value to influencers/athletes such as yourself.
                                        By joining early and pre-registering for our service we are proud to offer:</p>
                                    <p> 1. A chance to take part in cross-industry events where athletes and influencers
                                        can network and gain access to new audiences.</p>
                                    <p>2. The ability to participate in the early stages of our merchandise and
                                        memorabilia services.</p>

                                    <p className={"mt-2"}>Email us for any query at: <b>support@ourchat.us</b></p>

                                    <img className={"img-fluid mt-2"} src={randomPoppers} alt={""} title={""}/>*/}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    if (pageLoading) {
        return <div style={{
            backgroundColor: '#ffffff',
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}><LoadingComponent/></div>
    } else if (showProfile) {
        return (
            <>
                <ChatUserProfile user_id={activeCustomer} showProfile={showProfile} setShowProfile={setShowProfile}/>
            </>
        )
    } else {
        return (
            <div className={"ChatWindow"}>
                <div className={`ChatSidebar ${mobileSidebarActive ? "mobileSidebarActive" : ''}`}>
                    <div className={"ChatSidebarTop"}>
                        <div className={"headingContainer"}>
                            <div className={"headings"}>
                                <h1>Inbox</h1>
                                {/*<div className={"dropdownContainer"}>
                                <DropdownButton id="inboxBtn" align={"end"} title={<VerticalIcon/>}>
                                    <Dropdown.Item as="button"
                                                   onClick={() => {
                                                       getArchiveThreads()
                                                       setArchiveSidebar(!archiveSidebar)
                                                   }}><ArchiveIcon/> View
                                        archive</Dropdown.Item>
                                </DropdownButton>
                            </div>*/}
                                <div className={"sidebarToggle d-block d-sm-none"}
                                     onClick={() => setMobileSidebarActive(!mobileSidebarActive)}>
                                    <CancelCircle/>
                                </div>
                            </div>
                            <div className={"searchBarContainer"}>
                                <div className={"searchControls"}>
                                    <Form.Control
                                        ref={searchRef}
                                        type="text"
                                        placeholder="Search"
                                        className={"searchBarLeft"}
                                        onChange={(e) => {
                                            e.target.value.length > 0 ? showCancelSearch(true) : showCancelSearch(false);
                                            setSearch(e.target.value);
                                            setSearchClear(!searchClear);
                                        }}
                                    />
                                    <Button type={"button"}
                                            className={`searchBtns ${cancelSearch ? 'd-block' : 'd-none'}`}
                                            onClick={() => {
                                                showCancelSearch(false);
                                                searchRef.current.value = '';
                                                setSearch("");
                                            }}>
                                        <CancelCircle/>
                                        {/*<SearchIcon />*/}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"chatTilesContainer"}>
                        {/*
                    * Chat List
                    */}
                        {
                            !activeCustomer && !activeThread ?
                                <div>
                                    <ul className={"tilesList"}>
                                        <PinnedTab showWelcomeScreen={showWelcomeScreen}
                                                   setActiveThread={setActiveThread}
                                                   welcomeScreen={welcomeScreen}
                                                   isCustomerLogin={isCustomerLogin}/>
                                    </ul>
                                    <div style={{
                                        marginLeft: '10px',
                                        fontSize: '14px',
                                        textAlign: 'center'
                                    }}>
                                    </div>
                                </div> :
                                <ChatList threads={threads.length > 0 ? threads : ''}
                                          activeThread={activeThread}
                                          setActiveThread={setActiveThread}
                                          setActiveCustomer={setActiveCustomer}
                                          chatInput={chatInput}
                                          handleArchiveClick={handleArchiveClick}
                                          activeCustomerDetail={activeCustomerDetail}
                                          isBlocked={isBlocked}
                                          setIsBlocked={setIsBlocked}
                                          isCustomerLogin={isCustomerLogin}
                                          search={search}
                                          mobileSidebarActive={mobileSidebarActive}
                                          setMobileSidebarActive={setMobileSidebarActive}
                                          welcomeScreen={welcomeScreen}
                                          showWelcomeScreen={showWelcomeScreen}
                                          location={location}
                                          scroll={scroll}
                                          setScroll={setScroll}
                                          reportType={reportType}
                                          setReportType={setReportType}
                                          searchMessage={searchMessage}
                                          setaccLocked={setaccLocked}
                                          setLastMessage={setLastMessage}
                                          setMessages={setMessages}
                                />
                        }

                    </div>
                    {/*<div className={`ChatSidebarArchive ${archiveSidebar ? "d-block" : "d-none"}`}>
                    <div className={"ChatSidebarTop archivesTop"}>
                        <div className={"headingContainer"}>
                            <div className={"headings"}>
                                <p><span className={"svgIcon"}
                                         onClick={() => {
                                             getThreads()
                                             setArchiveSidebar(!archiveSidebar)
                                         }}><LeftIcon/></span> Archived
                                    messages <span className={"numberBuble"}>{threads?.length || 0}</span></p>
                            </div>
                        </div>
                    </div>

                    * Archieve List

                    <div className={"chatTilesContainer "}>
                        {
                            !activeCustomer && !activeThread ?
                                <div style={{
                                    marginLeft: '10px',
                                    fontSize: '14px'
                                }}>No Archive Found</div> :
                                <ArchieveList threads={threads}
                                              activeThread={activeThread}
                                              setActiveThread={setActiveThread}
                                              setActiveCustomer={setActiveCustomer}
                                              chatInput={chatInput}
                                              handleArchiveClick={handleArchiveClick}
                                              activeCustomerDetail={activeCustomerDetail}
                                              isBlocked={isBlocked}
                                              setIsBlocked={setIsBlocked}
                                />
                        }

                    </div>
                </div>*/}
                </div>
                {
                    welcomeScreen ?
                        <WelcomeMessage page={page}/>
                        :
                        <div className={"ChatMainWindow"}>
                            {
                                !activeCustomer && !activeThread ?
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}>
                                        <div className={`requestList isEmpty`}>
                                            <div className={"EmptyIconBox h-100"}>
                                                <div
                                                    className={"mx-auto text-center h-100 d-flex flex-column align-contents-center justify-content-center align-items-center"}>
                                                    <EmptyListIcon/>
                                                    <p>No chat available</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {/* Chat Header */}
                                        <div className={"chatTopBar"}>
                                            <div className={"chatIntro"} onClick={async () => {
                                                let checkDel = await checkDeleteUser(activeCustomerDetail.id);
                                                if (checkDel) {
                                                    Toast(`This account has been deleted by the user`, false);
                                                    return false;
                                                }
                                                setShowProfile(true);
                                                //navigate('/inbox/user_profile/' + activeCustomerDetail.id)
                                            }}>
                                                <div className={"chatImgContainer"}>
                                                    <img className={"img-fluid"}
                                                         src={activeCustomerDetail?.image || ourChatAvatar}
                                                         alt={"our chat"}
                                                         onError={({currentTarget}) => {
                                                             currentTarget.onerror = null; // prevents looping
                                                             currentTarget.src = ourChatAvatar;
                                                         }}
                                                    />
                                                </div>
                                                <div className={"chatTitleContainer"}>
                                                    <p style={{textTransform: 'capitalize'}}>{activeCustomerDetail?.name || activeCustomerDetail?.username || ''}</p>
                                                    {
                                                        moment().diff(activeCustomerDetail?.dob, 'years', false) <= 18 ?
                                                            <span className={"ageTag"}>Under 18</span> : ''
                                                    }

                                                </div>
                                            </div>
                                            <div className={"ChatIconsContainer"}>
                                                <div className={"SearchContainer"} onClick={() => {
                                                    setSearchBar(!searchBar)
                                                }}>
                                                    <SearchIcon/>
                                                </div>
                                                {/*<div className={"dropdownContainer d-none"}
                                         style={{cursor: 'pointer'}}
                                         onClick={() => handleArchiveClick()}
                                    >
                                        <ArchiveIcon/>
                                    </div>*/}
                                                <div className={"dropdownContainer d-md-none"}
                                                     style={{cursor: 'pointer'}}
                                                     onClick={() => setMobileSidebarActive(!mobileSidebarActive)}>
                                                    <FaList/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Search bar */}
                                        <SearchBar
                                            searchBar={searchBar}
                                            searchInputRef={searchInputRef}
                                            setSearchMessage={setSearchMessage}
                                            setSearchReset={setSearchReset}
                                            searchReset={searchReset}
                                            setSearchBar={setSearchBar}
                                        />
                                        {/* Main Chat window */}
                                        <div className={"chatWindow"}>
                                            {/* status bars */}
                                            {
                                                isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                                    activeCustomerDetail?.taking_messages == 0 ?
                                                        <StatusBar type={"error"}
                                                                   text={"This chat is locked. Messages are not being accepted by this user."}/>
                                                        : ''
                                                    : ''
                                            }
                                            {
                                                isBlocked ?
                                                    <StatusBar type={"error"}
                                                               text={"This chat is locked. The user has been blocked by you."}/>
                                                    : ''
                                            }

                                            {/*{
                                    activeThreadData && activeThreadData.block == 1 ?
                                        <StatusBar type={"error"}
                                                   text={"This chat is locked. You are blocked by the user."}/>
                                        : ''
                                }*/}
                                            {/*<StatusBar type={"success"} text={"This chat is locked. No further action is needed until the user is refunded or chooses to unlock the chat."} />*/}
                                            {/*<StatusBar type={"error"} text={"This chat is locked. No further action is needed until the user is refunded or chooses to unlock the chat."} />*/}

                                            {/* unlock chat bar - user flow */}

                                            {/*{
                                        accLocked && isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                            <div className={"accLocked"}>
                                                <p>No reply received in
                                                    over {activeThreadData && activeThreadData.unlock == 0 ? constants.ACCOUNT_LOCK_HOURS + 'h' : constants.ACCOUNT_LOCK_FIVE_HOURS + 'h'} .
                                                    You can choose to unlock the chat
                                                    to wait
                                                    for a
                                                    reply
                                                    or ask
                                                    for a refund.</p>

                                                <ul className={"list-unstyled list-inline mb-0"}>
                                                    <li className={"list-inline-item"}>
                                                        <Button type={"button"} className={"btn-unlock"}
                                                                onClick={() => setUnlockModal(true)}>
                                                            <UnlockedIcon/> Unlock
                                                        </Button>
                                                    </li>
                                                    <li className={"list-inline-item"}>
                                                        <Button type={"button"} className={"btn-outline"}
                                                                onClick={() => refundTokens()}>
                                                            <DollarIcon/> Refund Tokens
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div> : ''
                                    }*/}


                                            {/* Actual message Conversation */}
                                            <ChatConversation
                                                messages={messages}
                                                activeCustomer={activeCustomer}
                                                activeThread={activeThread}
                                                isCustomerLogin={isCustomerLogin}
                                                activeCustomerDetail={activeCustomerDetail}
                                                messageBar={messageBar}
                                                showMessageBar={showMessageBar}
                                                replyMessage={replyMessage}
                                                setReplyMessage={setReplyMessage}
                                                chatInput={chatInput}
                                                setUnlockModal={setUnlockModal}
                                                resetMessages={resetMessages}
                                                setResetMessage={setResetMessage}
                                                setRequestBox={setRequestBox}
                                                requestBox={requestBox}
                                                isBlocked={isBlocked}
                                                scroll={scroll}
                                                setScroll={setScroll}
                                                reportType={reportType}
                                                setReportType={setReportType}
                                            />
                                        </div>

                                        {/* chat bottom */}
                                        <div className={"chatBottom"} onMouseLeave={() => handleFocus()}
                                             onMouseEnter={() => setInputFocus(true)}>

                                            {
                                                isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL && !messageBar &&
                                                <div className={"replyToBox"}>
                                                    <div className={"MessageToReply"}>
                                                        <span>Replying To:</span>
                                                        <span>{replyMessage.content}</span>
                                                    </div>
                                                    <div className={"closeIcon"} onClick={() => {
                                                        showMessageBar(true)
                                                        chatInput.current.value = ''
                                                    }}>
                                                        <CancelCircle/>
                                                    </div>
                                                </div>
                                            }

                                            <MessageInput
                                                inputFocus={inputFocus}
                                                isCustomerLogin={isCustomerLogin}
                                                activeCustomerDetail={activeCustomerDetail}
                                                textareaDisabled={textareaDisabled}
                                                isBlocked={isBlocked}
                                                accLocked={accLocked}
                                                activeThreadData={activeThreadData}
                                                characterCount={characterCount}
                                                message={message}
                                                chatInput={chatInput}
                                                textCount={textCount}
                                                onKeyDownMessage={onKeyDownMessage}
                                                setSubmitModalShow={setSubmitModalShow}
                                                SubmitMessage={SubmitMessage}
                                                messageBar={messageBar}
                                                showMessageBar={showMessageBar}
                                                offensiveWords={offensiveWords}
                                                setOffensiveWordsForModal={setOffensiveWordsForModal}
                                                setOffensiveWordsModal={setOffensiveWordsModal}
                                                setMessageReachedModal={setMessageReachedModal}
                                            />
                                            {
                                                isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER ?
                                                    <div className={"chatCostContainer"}>
                                                        <p>Sending this message will cost <Star/>
                                                            <b> {activeCustomerDetail?.message_price || 0}</b> tokens
                                                        </p>
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                    </>
                            }

                        </div>
                }


                <ThemeModal title={""} content={<SubmitMessageModal count={activeCustomerDetail?.message_price || 0}/>}
                            className={"addCardInfo"} size={"sm"}
                            show={submitModalShow}
                            onHide={() => {
                                setSubmitModalShow(false)
                            }}/>
                <ThemeModal title={"Add Card Details"} content={<CardModalContent/>} className={"addCardInfo"}
                            size={"md"}
                            show={cardModalShow}
                            onHide={() => {
                                setCardModalShow(false)
                                setPaymentModalShow(true)
                                // reset()
                            }}/>
                <ThemeModal title={"Purchase tokens"} content={<PurchaseTokensModal/>} className={"addCardInfo"}
                            size={"sm"}
                            show={tokenModalShow}
                            onHide={() => {
                                setTokenModalShow(false)
                                // reset()
                            }}/>
                <ThemeModal title={"Purchase tokens"}
                            content={<PaymentModal checked={checked} setChecked={setChecked} selectedCard={selectedCard}
                                                   setSelectedCard={setSelectedCard}/>}
                            className={"addCardInfo"}
                            size={"sm"}
                            show={paymentModalShow}
                            onHide={() => {
                                setPaymentModalShow(false)
                                setChecked(false)
                                setSelectedCard([])
                                reset()
                            }}/>
                <ThemeModal title={""} content={<SuccessModal/>} className={"addCardInfo"} size={"sm"}
                            show={successModalShow}
                            onHide={() => {
                                setSuccessModalShow(false)
                                // reset()
                            }}/>

                <ThemeModal title={""} content={<NotEnoughTokensModal/>} className={"addCardInfo"} size={"sm"}
                            show={notEnoughTokens}
                            onHide={() => {
                                setNotEnoughTokensShow(false)
                                // reset()
                            }}/>
                <ThemeModal title={""} content={<UnlockModalContent/>} className={"addCardInfo"} size={"sm"}
                            show={unlockModal}
                            onHide={() => {
                                setUnlockModal(false)
                                // reset()
                            }}/>
                <ThemeModal title={"Alert"} content={<OffensiveModalContent/>} className={"addCardInfo"}
                            size={"sm"}
                            show={offensiveWordsModal}
                            onHide={() => {
                                setOffensiveWordsModal(false)
                                // reset()
                            }}/>
                <ThemeModal title={"Alert"} content={<MessageRequestReached/>} className={"addCardInfo"}
                            size={"sm"}
                            show={messageReachedModal}
                            onHide={() => {
                                setMessageReachedModal(false)
                                // reset()
                            }}/>

                <ThemeModal title={"Alert"} content={<MessageMaximumCharacter/>} className={"addCardInfo"}
                            size={"sm"}
                            show={messageCharacterModal}
                            onHide={() => {
                                setMessageCharacterModal(false)
                                // reset()
                            }}/>
            </div>
        );
    }

}

export default Chat;
