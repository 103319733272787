import React, {useState, useEffect} from 'react'
import {constants} from "../../../utils/constants";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FaClock, FaCheck, FaLock, FaUndo} from "react-icons/fa";
import {lockFlag} from "../../../services/firebase.service";
import moment from "moment";

export const ShowTimeOrLockedStatus = ({isCustomerLogin, activeThread, message, icon, showIcon, reply}) => {
    const [show, showMessage] = useState('')
    const [modalReportShow, setModalReportShow] = useState(false);

    useEffect(() => {
        checkReplyOrLocked()
    }, [])

    const getCountdown = (dt2, const_hours) => {
        let message_date = new Date(dt2)
        message_date.setHours(message_date.getHours() + const_hours);
        return moment(message_date).fromNow()
        /*var diff = (message_date.getTime() - new Date().getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.round(diff));*/
    };

    const checkReplyOrLocked = () => {
        if (message.chatStatus == constants.CHAT_REQUEST_STATUS.REQUESTED) {
            let const_hours = message.lockMessage == 0 ? constants.ACCOUNT_LOCK_HOURS : constants.ACCOUNT_LOCK_FIVE_HOURS
            let hours = getCountdown(message.message_timestamp, const_hours);
            let diff = Math.abs(message.message_timestamp - new Date().getTime()) / 3600000;
            if (message.lockMessage == 0) {
                //Set Lock 1
                showMessage(`You have to reply ${hours}`)
                if (parseInt(diff) >= 3600000 * constants.ACCOUNT_LOCK_HOURS) {
                    showIcon(false);
                } else {
                    showIcon(true);
                }
            } else if (message.lockMessage == 1) {
                //Set Lock 2
                showMessage(`You have to reply ${hours}`)
                if (parseInt(diff) >= 3600000 * constants.ACCOUNT_LOCK_FIVE_HOURS) {
                    showIcon(false);
                } else {
                    showIcon(true);
                }
            }
        }
    }

    const lockMessageQuery = (status) => {
        lockFlag({
            activeThread: activeThread,
            timestamp: message.timestamp,
            status: status
        })
    }

    const renderClockTooltip = (props) => {
        //lockMessageQuery(0)
        return (
            <Tooltip id="clock-tooltip" {...props}>
                {show}
            </Tooltip>
        )
    };
    const renderLockTooltip = (props) => {
        //lockMessageQuery(1)
        return (
            <Tooltip id="lock" {...props}><strong>This chat has been locked</strong></Tooltip>
        )
    };

    const renderRefundTooltip = (props) => {
        return (
            <Tooltip id="lock" {...props}><strong>This chat has been refund</strong></Tooltip>
        )
    };

    const renderTickTooltip = (props) => {
        return (
            <Tooltip id="lock" {...props}><strong>You've replied to this chat</strong></Tooltip>
        )
    };

    //Show Clock
    if (message && message.chatStatus == 10 && message.lockFlag == 0) {
        return (
            <span className={"statustoolTips"}>
                <span title={show}><FaClock/></span>
            </span>
        )
    }

    //Show Tick
    if (message && message.chatStatus == 20) {
        return (
            <span className={`statustoolTips`}>
                <span title="You've replied to this chat"><FaCheck/></span>
            </span>
        )
    }

    //Show Refund
    if (message && message.chatStatus == 30) {
        return (
            <span className={`statustoolTips`}>
                <span title="This chat has been refund"><FaUndo/></span>
            </span>
        )
    }

    //Show Lock
    if (message && message.chatStatus == 10 && message.lockFlag == 1) {
        return (
            <span className={`statustoolTips`}>
                <span title="This chat has been locked"><FaLock/></span>
            </span>
        )
    }

    /*return (

<>
{
    icon && reply ? <span className={"statustoolTips"}>
        <OverlayTrigger
            delay={{hide: 0, show: 0}}
            placement="top"
            overlay={renderClockTooltip()}
        >
            <span><FaClock/></span>
        </OverlayTrigger>
        <span title={show}><FaClock/></span>
    </span> :
        <>
            {
                /!*message && message.messageReply != "" ?
                <span className={`statustoolTips`}>
                {/!*<OverlayTrigger
                    delay={{hide: 0, show: 0}}
                    placement="top"
                    overlay={renderTickTooltip}
                    >
                    <span><FaCheck/></span>
                    </OverlayTrigger>*!/}
                    <span title="You've replied to this chat"><FaCheck/></span>
                    </span>
                    :
                    message && message.chatStatus == '30' ?
                    <span className={`statustoolTips`}>
                    {/!*<OverlayTrigger
                    delay={{ hide: 0, show: 0 }}
                    placement="top"
                    overlay={renderRefundTooltip}
                    >
                    <span><FaLock/></span>
                    </OverlayTrigger>*!/}
                    <span title="This chat has been refund"><FaUndo/></span>
                    </span> : <span className={`statustoolTips`}>
                    {/!*<OverlayTrigger
                    delay={{ hide: 0, show: 0 }}
                    placement="top"
                    overlay={renderLockTooltip}
                    >
                    <span><FaLock/></span>
                    </OverlayTrigger>*!/}
                    <span title="This chat has been locked"><FaLock/></span>
                    </span>*!/
                    }
                    </>
                    /!*<span className={`statustoolTips`}>
                    <OverlayTrigger
                    placement="top"
                    overlay={renderLockTooltip}
                    >
                    <span className={`${message.messageReply != "" ? "d-none" : "d-block"}`}><FaLock/></span>
                    </OverlayTrigger>
                    </span>*!/
                    }

                    </>
                    )*/
}