import React, {useContext, useEffect} from "react";
import {Route, Navigate, Outlet} from "react-router";
import {constants} from "../utils/constants";
import {IsCustomerLogin} from "../App";
import {Toast} from "../utils/helpers";

const AuthProtectedRoute = () => {
    const [isCustomerLogin] = useContext(IsCustomerLogin);

    /* if (!isCustomerLogin) {
         return <Outlet/>
     }*/

    if (isCustomerLogin && isCustomerLogin.roles[0] && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL) {
        if (isCustomerLogin && isCustomerLogin.is_verified === 0 && isCustomerLogin.is_profile_complete === 0) {
            return <Navigate to="/account-verification-professional"/>
        } else if (isCustomerLogin && isCustomerLogin.is_verified === 1 && isCustomerLogin.is_profile_complete === 0) {
            return <Navigate to="/configure-profile"/>
        } else if (isCustomerLogin && isCustomerLogin.is_approved === 0 && isCustomerLogin.is_verified === 1 && isCustomerLogin.is_profile_complete === 1) {
            return <Navigate to="/not-verified-account"/>
        } else if (isCustomerLogin && isCustomerLogin.is_approved === 1 && isCustomerLogin.is_verified === 1 && isCustomerLogin.is_profile_complete === 1) {
            return <Navigate to="/dashboard"/>
        }
    } else if (isCustomerLogin && isCustomerLogin.roles[0].id && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
        return <Navigate to="/explore"/>
    } else {
        return <Outlet/>
    }
}

export default AuthProtectedRoute;
