import {constants} from '../utils/constants';
import {db} from "../utils/firebase-config";
import moment from "moment";
import ProfileIcon from "../assets/images/others/image-not-found.png"
//import {GetAuthUser} from "./auth/auth.service";
import {GetAuthUser} from "./authentication.service";
import {onLog} from "firebase";
import firebase from 'firebase/app';

const current_user = GetAuthUser();
const chatroom = constants.COLLECTION.CHATROOMSTAGING;
const user = constants.COLLECTION.USERSTAGING;
const chat_list = constants.COLLECTION.CHATLIST;
const scheduler = constants.COLLECTION.SCHEDULER;
const general_chat_users_live = constants.COLLECTION.GENERALCHATLIST;
const general_chatRoom_live = constants.COLLECTION.GENERALCHATROOM;

/*
Our Chat Queries Start
*/

export const testCron = async () => {
    //console.log('test');
}

export const removeScheduleOnReplyMessage = async (document_id) => {
    let query = db.collection(scheduler)
        .where('document_id', '==', document_id);

    query.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            doc.ref.delete();
        });
    });
}

export const fetchServerTime = async (user_id) => {
    await db.collection(user).doc(user_id.toString()).update({
        'serverTime': firebase.firestore.FieldValue.serverTimestamp()
    });

    const userRef = db.collection(user).doc(user_id.toString());
    return new Promise((resolve, reject) => {
        const unsubscribe = userRef.onSnapshot(snapshot => {
            const server_time = snapshot.data().serverTime.toDate().getTime();
            resolve(server_time);
            unsubscribe(); // Unsubscribe from the listener to prevent memory leaks
        }, reject);
    });
}

export const changeFlag = (user_id, active_thread) => {
    db.collection(user)
        .doc(user_id.toString())
        .collection(chat_list)
        .doc(active_thread.toString())
        .update({
            lockFlag: new Date().getTime(),
        });
}

export const updateTokens = async (user_id, tokens) => {
    let docRef = db.collection(user).doc(user_id.toString())
    docRef.get()
        .then(async (docSnapshot) => {
            if (docSnapshot.exists) {
                return await docRef.update({
                    total_tokens: tokens
                });
            }
        });
}


export const updateScheduler = async (data) => {
    db.collection('scheduler').add(data)
        .then((docRef) => {
            console.log('Document written with ID: ');
        })
        .catch((error) => {
            console.error('Error adding document: ', error);
        });
}

export const updateChatListDelete = async (user_id) => {
    let docRef = db.collection(user)
        .doc(user_id.toString())
        .collection(chat_list)

    await docRef.get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                doc.ref.update({user_deleted: 1});
            });
        })
        .catch((error) => {
            console.error("Error updating documents: ", error);
        });
}

export const deleteUser = async (user_id) => {
    let docRef = db.collection(user).doc(user_id.toString())

    await docRef.collection(chat_list).get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                doc.ref.update({user_deleted: 1});
                let chatDocRef = db.collection(user).doc(doc.data().chatWith.toString()).collection(chat_list).doc(doc.id);
                chatDocRef.update({user_deleted: 1});

            });
        })
        .catch((error) => {
            console.error("Error updating documents: ", error);
        });

    await docRef.get()
        .then(async (docSnapshot) => {
            if (docSnapshot.exists) {
                return await docRef.update({
                    show_name: 0,
                    name: 'Appuser',
                    image: '',
                    deleted_at: new Date().getTime()
                });
            }
        });
}

/*export const deleteUser = async (user_id) => {
    let docRef = db.collection(user).doc(user_id.toString())
    docRef.get()
        .then(async (docSnapshot) => {
            if (docSnapshot.exists) {
                return await docRef.update({
                    show_name: 0,
                    name: 'Appuser',
                    image: '',
                    deleted_at: new Date().getTime()
                });
            }
        });
}*/


export const checkDeleteUser = async (userId) => {
    let userRef = db.collection(user).doc(userId.toString());
    let data = await userRef.get().then(snapshot => {
        return snapshot.data().deleted_at;
    })
    //console.log('data', data)
    return data;
}

//Get Chat Messages
export const getChatCounts = async (user_id, callback) => {
    db.collection(user).doc(user_id.toString()).collection(chat_list).onSnapshot(async (querySnapshot) => {
            let totalRespondedMessage = 0
            let totalLockedMessage = 0
            let totalRepliedMessage = 0
            for (let doc of querySnapshot.docs) {
                let userData = doc.data()
                let userDoc = await db.collection(user).doc(userData.chatWith.toString()).get()
                let userDt = userDoc.data()
                if (userDt && userDt.deleted_at === null) {
                    //Respond
                    let respond = await db.collection(chatroom)
                        .doc(doc.id.toString())
                        .collection(doc.id.toString())
                        .where('chatStatus', '==', 10)
                        .where('lockFlag', '==', 0)
                        .where('messageReply', '==', "")
                        .get();

                    totalRespondedMessage = totalRespondedMessage + respond.docs.length;

                    //Locked
                    let locked = await db.collection(chatroom)
                        .doc(doc.id.toString())
                        .collection(doc.id.toString())
                        .where('chatStatus', '==', 10)
                        .where('lockFlag', '==', 1)
                        .get();

                    totalLockedMessage = totalLockedMessage + locked.docs.length;

                    //Replied
                    let replied = await db.collection(chatroom)
                        .doc(doc.id.toString())
                        .collection(doc.id.toString())
                        .where('chatStatus', '==', 20)
                        .where('idTo', '==', user_id.toString())
                        //.where('lockMessage', '==', 0)
                        .get();

                    totalRepliedMessage = totalRepliedMessage + replied.docs.length;
                }
            }

            callback({
                'totalRespondedMessage': totalRespondedMessage,
                'totalLockedMessage': totalLockedMessage,
                'totalRepliedMessage': totalRepliedMessage
            })
        }
    )
}

//Lock Threads
export const lockFlag = async (data) => {
    let docRef = db.collection(chatroom)
        .doc(data.activeThread.toString())
        .collection(data.activeThread.toString())
        .doc(data.timestamp.toString())

    docRef.get().then(async (docSnapshot) => {
        if (docSnapshot.exists) {
            return await docRef.update({
                lockFlag: data.status
            })
        }
    });
}

//Active Inactive User
export const activeInactiveUser = async (user_id, status) => {
    let docRef = db.collection(user).doc(user_id.toString())
    docRef.get()
        .then(async (docSnapshot) => {
            if (docSnapshot.exists) {
                return await docRef.update({
                    online: status
                });
            } else {
                return await docRef.set({
                    online: status
                });
            }
        });
}

//Check IsOnline
export const isOnline = async (user_id, callback) => {
    if (user_id) {
        let userRef = await db.collection(user).doc(user_id.toString());
        userRef.onSnapshot(snapshot => {
            callback(snapshot.data().online)
        })
    }
}

//Create new user doc
export const createOrUpdateUser = async (data) => {
    let docRef = db.collection(user).doc(data.id.toString())
    docRef.get()
        .then(async (docSnapshot) => {
            if (docSnapshot.exists) {
                return await docRef.update(data);
            } else {
                return await docRef.set(data);
            }
        });
}

//Create User Chat List
export const createUserChatList = async (data) => {
    let document_id = data.user_id.toString() + '-' + data.profileId.toString()
    //Connect Chatlist in both user and professional
    let docRef = db.collection(user).doc(data.user_id.toString()).collection(chat_list).doc(document_id)
    docRef.get()
        .then((docSnapshot) => {
            if (docSnapshot.exists) {
                docRef.update({
                    chatID: data.profileName,
                    chatWith: data.profileId.toString(),
                    //timestamp: new Date().getTime(),
                    show: 1,
                    user_deleted: 0
                });
            } else {
                docRef.set({
                    chatID: data.profileName,
                    chatWith: data.profileId.toString(),
                    delete: 0,
                    is_archive: 0,
                    lastChat: '',
                    online: false,
                    timestamp: new Date().getTime(),
                    show: 1,
                    unlock: 0,
                    block: 0,
                    is_new: 1,
                    user_deleted: 0
                });
            }
        });

    /*let docRefProfile = db.collection(user).doc(data.profileId.toString()).collection(chat_list).doc(document_id)
    docRefProfile.get()
        .then((docSnapshot) => {
            if (docSnapshot.exists) {
                return docRefProfile.update({
                    chatID: data.username,
                    chatWith: data.user_id.toString(),
                    timestamp: new Date().getTime(),
                    show: 1,
                });
            } else {
                return docRefProfile.set({
                    chatID: data.username,
                    chatWith: data.user_id.toString(),
                    delete: 0,
                    is_archive: 0,
                    lastChat: '',
                    online: false,
                    timestamp: new Date().getTime(),
                    show: 1,
                    unlock: 0,
                    block: 0
                });
            }
        });*/
}

//Create Professional Chat List
export const creatProfessionalChatList = async (data) => {
    let document_id = data.user_id.toString() + '-' + data.profileId.toString()
    //Connect Chatlist in both user and professional
    /*let docRef = db.collection(user).doc(data.user_id.toString()).collection(chat_list).doc(document_id)
    docRef.get()
        .then((docSnapshot) => {
            if (docSnapshot.exists) {
                docRef.update({
                    chatID: data.profileName,
                    chatWith: data.profileId.toString(),
                    timestamp: new Date().getTime(),
                    show: 1,
                });
            } else {
                docRef.set({
                    chatID: data.profileName,
                    chatWith: data.profileId.toString(),
                    delete: 0,
                    is_archive: 0,
                    lastChat: '',
                    online: false,
                    timestamp: new Date().getTime(),
                    show: 1,
                    unlock: 0,
                    block: 0
                });
            }
        });*/

    let docRefProfile = db.collection(user).doc(data.profileId.toString()).collection(chat_list).doc(document_id)
    docRefProfile.get()
        .then((docSnapshot) => {
            if (docSnapshot.exists) {
                return docRefProfile.update({
                    chatID: data.username,
                    chatWith: data.user_id.toString(),
                    //timestamp: new Date().getTime(),
                    show: 1,
                    user_deleted: 0
                });
            } else {
                return docRefProfile.set({
                    chatID: data.username,
                    chatWith: data.user_id.toString(),
                    delete: 0,
                    is_archive: 0,
                    lastChat: '',
                    online: false,
                    timestamp: new Date().getTime(),
                    show: 1,
                    unlock: 0,
                    block: 0,
                    user_deleted: 0
                });
            }
        });
}

//Block unblock Thread
export const blockUnblockThread = async (thread_id, block, isCustomerLogin) => {
    let user_id = isCustomerLogin.id;
    return db.collection(user)
        .doc(user_id.toString())
        .collection(chat_list)
        .doc(thread_id)
        .update({
            block: block,
            show: block == 1 ? 0 : 1
        });
}

//Block Unblock Profile
export const blockUnblockProfile = async (thread_id, block, activeUserId) => {
    let user_id = activeUserId;
    return db.collection(user.toString())
        .doc(user_id.toString())
        .collection(chat_list)
        .doc(thread_id)
        .update({
            block: block,
            show: block == 1 ? 0 : 1
        });
}

//Using this function for search in thread
const searchThread = (nameKey, myArray) => {
    for (let i = 0; i < myArray.length; i++) {
        if (myArray[i].username.toLowerCase().includes(nameKey.toLowerCase())) {
            return myArray[i];
        }
    }
}

//Get Images
export const getImage = async (userId, callback) => {
    let userRef = db.collection(user).doc(userId);
    userRef.get().then(snapshot => {
        callback(snapshot.data().image.replace(" ", "%20"))
    })
}

//Get Username
export const getUsername = async (userId, callback) => {
    let userRef = db.collection(user).doc(userId);
    userRef.get().then(snapshot => {
        callback(snapshot.data().name)
    })
}

//Hide Threads
export const hideThread = async (thread_id, isCustomerLogin, status) => {
    let user_id = isCustomerLogin.id;
    return db.collection(user)
        .doc(user_id.toString())
        .collection(chat_list)
        .doc(thread_id)
        .update({
            show: parseInt(status)
        });
}

//Lock Threads
export const lockThread = async (thread_id, unlock, isCustomerLogin) => {
    let user_id = isCustomerLogin.id;
    return db.collection(user)
        .doc(user_id.toString())
        .collection(chat_list)
        .doc(thread_id)
        .update({
            unlock: unlock
        });
}

export const readChatMessages = async (chat_id, customer_id) => {
    await db.collection(chatroom)
        .doc(chat_id.toString())
        .collection(chat_id.toString())
        .where('idFrom', '==', customer_id.toString())
        .get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                doc.ref.update({
                    isread: true
                });
            });
        });
}

export const getUnreadCount = async (chat_id, customer_id, callback) => {
    if (chat_id) {
        let chatRef = await db.collection(chatroom)
            .doc(chat_id.toString())
            .collection(chat_id.toString())
            .where('idFrom', '==', customer_id.toString())
            .where('isread', '==', false);
        chatRef.onSnapshot(snapshot => {
            callback(snapshot.size)
        })
    }
}

export const getThreadList = async (search = null, is_archive = false, isCustomerLogin, callback) => {
    let user_id = isCustomerLogin.id;
    let docRef = db.collection(user)
        .doc(user_id.toString())
        .collection(chat_list)
        .where('is_archive', '==', is_archive ? 1 : 0)

    await docRef
        .orderBy('timestamp', 'desc')
        .onSnapshot(async (snapShot) => {
            let data = []
            for (const doc of snapShot.docs) {
                let dt = doc.data();
                if (dt.show === 1) {
                    let userDoc = await db.collection(user).doc(dt.chatWith.toString()).get()
                    let userDt = userDoc.data()
                    if (userDt && userDt.deleted_at === null) {
                        let loopDt = {
                            chat_id: doc.id,
                            username: dt.chatID,
                            last_message: dt.lastChat,
                            created_ago: moment(dt.timestamp).fromNow(),
                            online: false,
                            user_id: dt.chatWith,
                            delete: dt.delete,
                            unlock: dt.unlock,
                            block: dt?.block || 0
                        }
                        data.push(loopDt)
                    }
                }
            }
            if (search) {
                let search_data = searchThread(search, data);
                if (search_data) {
                    callback([search_data]);
                } else {
                    callback(data);
                }
            } else {
                callback(data);
            }
        });
}

//Get real time thread list
/*export const getThreadList = async (search = null, is_archive = false, isCustomerLogin, callback) => {
    let user_id = isCustomerLogin.id;
    let docRef = db.collection(user)
        .doc(user_id.toString())
        .collection(chat_list)
        .where('is_archive', '==', is_archive ? 1 : 0)

    await docRef
        .orderBy('timestamp', 'desc')
        .onSnapshot((snapShot) => {
            let data = []
            snapShot.forEach(async (doc) => {
                let dt = doc.data();
                if (dt.show === 1) {
                    let loopDt = {
                        chat_id: doc.id,
                        username: dt.chatID,
                        last_message: dt.lastChat,
                        created_ago: moment(dt.timestamp).fromNow(),
                        online: false,
                        user_id: dt.chatWith,
                        delete: dt.delete,
                        unlock: dt.unlock,
                        block: dt?.block || 0
                    }

                    data.push(loopDt)
                }
            })
            if (search) {
                let search_data = searchThread(search, data);
                if (search_data) {
                    callback([search_data]);
                } else {
                    callback(data);
                }
            } else {
                callback(data);
            }
        });
}*/

//Remove Empty Messages Thread
export const removeEmptyMessageThreads = async (user_id) => {
    let query = db.collection(user)
        .doc(user_id)
        .collection(chat_list)
        .where('is_new', '==', 1);

    query.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            doc.ref.delete();
        });
    });
}

//Archive Chat
export const archiveUserChatList = async (data) => {
    let docRef = db.collection(user)
        .doc(data.user_id.toString())
        .collection(chat_list)
        .doc(data.chat_id)

    docRef.update({
        is_archive: data.is_archive,
        timestamp: new Date().getTime(),
    });
}

//Get Last Thread Message
export const getLastThreadMessage = async (chat_id, callback) => {
    let resultArray = [];
    let docRef = db.collection(chatroom)
        .doc(chat_id.toString())
        .collection(chat_id.toString())
        .orderBy("timestamp", 'desc')
        .limit(1)
    docRef = await docRef.get()
    docRef.forEach((doc) => {
        let dt = doc.data();
        resultArray.push(dt)
    });
    callback(resultArray[0])
}

//Get user image for sender and receiver on chat
export const getUserImage = async (user_id, callback) => {
    if (user_id) {
        const docRef = db.collection(user).where('id', '==', user_id);
        await docRef.onSnapshot((snapShot) => {
            let image = ''
            snapShot.forEach((doc) => {
                let dt = doc.data();
                //console.log("dt", dt)
                image = dt?.image || '';
            });
            callback(image);
        });
    }
}

//search Message
const searchMessage = (nameKey, myArray) => {
    let tempData = [];
    let count = 0
    for (let i = 0; i < myArray.length; i++) {
        let search = myArray[i].content.toLowerCase().search(nameKey.toLowerCase());
        if (search >= 0) {
            tempData[count] = myArray[i];
            count++
        }
    }
    return tempData;
}

//get all thread messages by active thread and active customer
export const getThreadMessages = async (search = null, activeThread, activeCustomer, current_user, callback) => {
    const docRef = db.collection(chatroom)
        .doc(activeThread)
        .collection(activeThread);
    await docRef.where("delete", "==", 0).onSnapshot((snapShot) => {
        let data = []
        snapShot.forEach((doc) => {
            let dt = doc.data();
            //get chat messages
            data.push({
                chatId: dt.chatId ? dt.chatId : '',
                content: dt.content ? dt.content : '',
                contentType: dt.idTo == current_user.id ? 'sent' : 'received',
                receivedAt: dt.timestamp ? moment(dt.timestamp).fromNow() : '',
                delete: 0,
                idFrom: dt.idFrom ? dt.idFrom : '',
                idTo: dt.idTo ? dt.idTo : '',
                isRead: dt.isread,
                reactions: dt.reactions ? dt.reactions : [],
                timestamp: dt.timestamp ? dt.timestamp : '',
                message_timestamp: dt.message_timestamp ? dt.message_timestamp : '',
                document_id: doc.id,
                chatRequestId: dt.chatRequestId ? dt.chatRequestId : '',
                chatRequestCreatedAt: dt.chatRequestCreatedAt ? dt.chatRequestCreatedAt : '',
                chatStatus: dt.chatStatus ? dt.chatStatus : '',
                messageReply: dt.messageReply ? dt.messageReply : '',
                lockMessage: dt.lockMessage ? dt.lockMessage : '',
                lockFlag: dt.lockFlag ? dt.lockFlag : ''
            })
            /*if ((dt.idFrom == current_user.id && dt.idTo == activeCustomer) || (dt.idFrom == activeCustomer && dt.idTo == current_user.id)) {
                let sender_image = ProfileIcon;
                let receiver_image = ProfileIcon;
                //get chat messages
                data.push({
                    chatId: dt.chatId ? dt.chatId : '',
                    content: dt.content ? dt.content : '',
                    contentType: dt.idTo == current_user.id ? 'sent' : 'received',
                    profileImg: dt.idTo == current_user.id ? sender_image : receiver_image,
                    receivedAt: dt.timestamp ? moment(dt.timestamp).fromNow() : '',
                    delete: 0,
                    idFrom: dt.idFrom ? dt.idFrom : '',
                    idTo: dt.idTo ? dt.idTo : '',
                    isRead: dt.isread,
                    reactions: dt.reactions ? dt.reactions : [],
                    timestamp: dt.timestamp ? dt.timestamp : '',
                })
            }*/
        });
        if (search) {
            let search_data = searchMessage(search, data);
            //console.log(search_data, search_data.length);
            if (search_data) {
                callback(search_data);
            } else {
                callback(data);
            }
        } else {
            //console.log(data);
            callback(data);
        }
        //callback(data);
    });
}

//Update last message on threads
export const updateLastMessage = async (userId, activeThread, message) => {
    await db.collection(user)
        .doc(userId.toString())
        .collection(chat_list)
        .doc(activeThread.toString())
        .update({
            lastChat: message,
            timestamp: new Date().getTime()
        });
}

//Submit Messsage
export const submitMessage = async (data, isCustomerLogin) => {
    changeIsNewChatList(data.chatId.toString(), data.idFrom.toString())

    await db.collection(chatroom).doc(data.chatId.toString()).set({test: 1});

    let document_timestamp =
        await db.collection(chatroom)
            .doc(data.chatId.toString())
            .collection(data.chatId.toString())
            .doc(data.timestamp.toString())
            .set(data);

    if (isCustomerLogin && isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
        //Update Scheduler
        let futureDate = new Date(data.timestamp + (48 * 60 * 60 * 1000)).getTime();
        updateScheduler({
            chat_id: data.chatId,
            document_id: data.timestamp,
            timestamp: futureDate,
            lockMessage: 0
        })
    }

    return true;
}

//Is New False
export const changeIsNewChatList = async (thread_id, user_id) => {
    return db.collection(user)
        .doc(user_id.toString())
        .collection(chat_list)
        .doc(thread_id)
        .update({
            is_new: 0
        });
}

//Fetch User Data
export const getUser = async (user_id, callback) => {
    if (user_id) {
        var docRef = db.collection(user).doc(user_id);

        docRef.onSnapshot((doc) => {
            if (doc.exists) {
                callback(doc.data());
            } else {
                callback()
            }
        });
    }
}

//Update Reaction In Chat Message
export const updateReaction = async (data, callback) => {
    if (data) {
        await db.collection(chatroom)
            .doc(data.activeThread)
            .collection(data.activeThread)
            .doc(data.timestamp.toString())
            .update({
                reactions: data.reactions
            });
        callback(data);
    }
}

//Unlock Messages
export const unlockMessage = async (data, callback) => {
    if (data) {
        await db.collection(chatroom)
            .doc(data.activeThread.toString())
            .collection(data.activeThread.toString())
            .doc(data.message_timestamp.toString())
            .update({
                message_timestamp: new Date().getTime()
            });

        callback(data);
    }
}

//Update message status
export const change_message_status = async (data) => {
    let docRef = db.collection(chatroom)
        .doc(data.activeThread.toString())
        .collection(data.activeThread.toString())
        .doc(data.timestamp.toString())

    docRef.get().then(async (docSnapshot) => {
        if (docSnapshot.exists) {
            return await docRef.update({
                chatStatus: data.status,
                messageReply: data.messageReply
            })
        }
    });
}

//Unlock Message
export const unlock_message = async (data) => {
    let docRef = db.collection(chatroom)
        .doc(data.activeThread.toString())
        .collection(data.activeThread.toString())
        .doc(data.timestamp.toString())

    docRef.get().then(async (docSnapshot) => {
        if (docSnapshot.exists) {
            await docRef.update({
                lockMessage: data.lockMessage,
                lockFlag: data.lockFlag,
                message_timestamp: new Date().getTime()
            })

            let futureDate = new Date(data.message_timestamp + (120 * 60 * 60 * 1000)).getTime();
            return updateScheduler({
                chat_id: data.activeThread.toString(),
                document_id: data.timestamp,
                timestamp: futureDate,
                lockMessage: 1
            })
        }
    });
}

//Get Chat Indicator
export const get_chat_indicator = async (user_id, callback) => {
    let userRef = await db.collection(user).doc(user_id.toString());
    userRef.onSnapshot(snapshot => {
        callback(snapshot.data().new_message_notify)
    })
}

//Get Chat Indicator
export const post_chat_indicator = async (user_id, status) => {
    let userRef = db.collection(user).doc(user_id.toString())
    userRef.get()
        .then(async (docSnapshot) => {
            return await userRef.update({
                new_message_notify: status
            });
        });
}

/*export const activeInactiveUser = async (user_id, status) => {
    let docRef = db.collection(user).doc(user_id.toString())
    docRef.get()
        .then(async (docSnapshot) => {
            if (docSnapshot.exists) {
                return await docRef.update({
                    online: status
                });
            } else {
                return await docRef.set({
                    online: status
                });
            }
        });
}*/


/*
Our Chat Queries End
*/

/*

//update existing user doc
export const updateUser = async (data) => {
    let docRef = db.collection(user).doc(data.id.toString());
    await docRef.update(data);
}



//get all thread messages by active thread and active customer
export const getThreadMessages = async (activeThread, activeCustomer, callback) => {
    const docRef = db.collection(chatroom)
        .doc(activeThread)
        .collection(activeThread);
    await docRef.where("deleteAgent", "==", constants.CHATMESSAGE.CONTENT).onSnapshot((snapShot) => {
        let data = []
        snapShot.forEach(async (doc) => {
            let dt = doc.data();
            if ((dt.idFrom == current_user.id && dt.idTo == activeCustomer) || (dt.idFrom == activeCustomer && dt.idTo == current_user.id)) {
                let sender_image = ProfileIcon;
                let receiver_image = ProfileIcon;

                //get chat messages
                data.push({
                    type: dt.idTo == current_user.id ? 'sent' : 'received',
                    text: dt.content ? dt.content : '',
                    profileImg: dt.idTo == current_user.id ? sender_image : receiver_image,
                    receivedAt: dt.timestamp ? moment(dt.timestamp).fromNow() : '',
                    vendorIds: dt.vendorIds ? dt.vendorIds : [],
                    mediaType: dt.contentType ? dt.contentType : '',
                    idFrom: dt.idFrom ? dt.idFrom : '',
                    order_id: dt.order_id ? dt.order_id : '',
                    media: dt.url && (dt.contentType == 'image' || dt.contentType == 'audio' || dt.contentType == 'video' || dt.contentType == 'doc') ? dt.url : '',
                    timestamp: dt.timestamp ? dt.timestamp : '',
                    isRead: dt.isread
                })


            }
        });
        callback(data);
    });
}

//get all vendor thread messages by active thread and active customer
export const getVendorThreadMessages = async (activeThread, activeCustomer, is_vendor = true, callback) => {
    let whereColumn = is_vendor ? 'delete' : 'deleteAgent';
    const docRef = db.collection(chatroom)
        .doc(activeThread.toString())
        .collection(activeThread.toString());
    await docRef.where(whereColumn.toString(), "==", constants.CHATMESSAGE.CONTENT).onSnapshot((snapShot) => {
        let data = []
        snapShot.forEach(async (doc) => {
            let dt = doc.data();
            if ((dt.idFrom == current_user.id && dt.idTo == activeCustomer) || (dt.idFrom == activeCustomer && dt.idTo == current_user.id)) {
                let sender_image = ProfileIcon;
                let receiver_image = ProfileIcon;

                //get chat messages
                data.push({
                    type: dt.idTo == current_user.id ? 'sent' : 'received',
                    text: dt.content ? dt.content : '',
                    profileImg: dt.idTo == current_user.id ? sender_image : receiver_image,
                    receivedAt: dt.timestamp ? moment(dt.timestamp).fromNow() : '',
                    vendorIds: dt.vendorIds ? dt.vendorIds : [],
                    mediaType: dt.contentType ? dt.contentType : '',
                    idFrom: dt.idFrom ? dt.idFrom : '',
                    order_id: dt.order_id ? dt.order_id : '',
                    media: dt.url && (dt.contentType == 'image' || dt.contentType == 'audio' || dt.contentType == 'video' || dt.contentType == 'doc') ? dt.url : '',
                    timestamp: dt.timestamp ? dt.timestamp : '',
                    isRead: dt.isread
                })


            }
        });
        callback(data);
    });
}

//getting active thread media files
export const getActiveThreadMedia = async (activeThread, activeCustomer, callback) => {
    const docRef = db.collection(chatroom).doc(activeThread.toString()).collection(activeThread.toString());
    await docRef.orderBy('timestamp', 'asc').onSnapshot((snapShot) => {
        let data = []
        snapShot.forEach((doc) => {
            let dt = doc.data();
            if ((dt.idFrom == current_user.id && dt.idTo == activeCustomer) || (dt.idFrom == activeCustomer && dt.idTo == current_user.id)) {
                //get chat messages
                data.push({
                    type: dt.idTo == current_user.id ? 'sent' : 'received',
                    mediaType: dt.contentType ? dt.contentType : '',
                    media: dt.url && (dt.contentType == 'image' || dt.contentType == 'audio' || dt.contentType == 'video' || dt.contentType == 'doc') ? dt.url : '',
                    timestamp: dt.timestamp ? dt.timestamp : ''
                })
            }
        });
        callback(data);
    });
}



//Update last message on threads
export const updateLastMessage = async (userId, activeThread, message) => {
    await db.collection(user)
        .doc(userId.toString())
        .collection(chat_list)
        .doc(activeThread.toString())
        .update({
            lastChat: message,
            timestamp: new Date().getTime()
        });
}

export const updateVendorLastMessage = async (userId, activeThread, message) => {
    await db.collection(user)
        .doc(userId.toString())
        .collection(chat_list)
        .doc(activeThread.toString())
        .update({
            lastChatVendor: message,
            timestamp: new Date().getTime()
        });
}


//delete message
export const deleteMessage = async (data) => {
    let docRef = await db.collection(chatroom)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            deleteAgent: data.deleted_at
        })
}

//delete vendor message
export const deleteVendorMessage = async (data) => {
    let docRef = await db.collection(chatroom)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            delete: data.deleted_at
        })
}

//delete message from everyone
export const deleteEveryOneMessage = async (data) => {
    let docRef = await db.collection(chatroom)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            delete: data.deleted_at,
            deleteAgent: data.deleted_at
        })
}

//delete vendor message
export const deleteEveryOneVendorMessage = async (data) => {
    let docRef = await db.collection(chatroom)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            delete: data.deleted_at,
            deleteAgent: data.deleted_at
        })
}

//read all messages
export const readMessage = async (data) => {
    let docRef = await db.collection(chatroom)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        //.doc(data.message_id.toString())
        .where('idFrom', '==', data.customer_id.toString())
        .get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                doc.ref.update({
                    isread: true
                });
            });
        });
}

//Get real time thread list for orders
export const getUsersThreadList = async (search = null, callback) => {
    let user_id = current_user.id;
    await db.collection(general_chat_users_live)
        .doc(user_id.toString())
        .collection(chat_list)
        .orderBy('timestamp', 'desc')
        .onSnapshot((snapShot) => {
            let data = []
            snapShot.forEach(async (doc) => {
                let dt = doc.data();

                data.push({
                    chat_id: doc.id,
                    //last_message: dt.lastChat,
                    created_ago: moment(dt.timestamp).fromNow(),
                    online: false,
                    customer_id: dt.chatWith,
                    customer_name: dt.userName || dt.chatWith
                })
            });

            if (search) {
                let search_data = searchThread(search, data);
                if (search_data) {
                    callback([search_data]);
                } else {
                    callback(data);
                }
            } else {
                callback(data);
            }
        });
}

//Get real time thread list for users
export const getUserThreadMessages = async (activeThread, activeCustomer, callback) => {
    const docRef = db.collection(general_chatRoom_live)
        .doc(activeThread)
        .collection(activeThread);
    await docRef.where("deleteAgent", "==", constants.CHATMESSAGE.CONTENT).onSnapshot((snapShot) => {
        let data = []
        snapShot.forEach(async (doc) => {
            let dt = doc.data();
            if ((dt.idFrom == current_user.id && dt.idTo == activeCustomer) || (dt.idFrom == activeCustomer && dt.idTo == current_user.id)) {
                let sender_image = ProfileIcon;
                let receiver_image = ProfileIcon;

                //get chat messages
                data.push({
                    type: dt.idTo == current_user.id ? 'sent' : 'received',
                    text: dt.content ? dt.content : '',
                    profileImg: dt.idTo == current_user.id ? sender_image : receiver_image,
                    receivedAt: dt.timestamp ? moment(dt.timestamp).fromNow() : '',
                    mediaType: dt.contentType ? dt.contentType : '',
                    idFrom: dt.idFrom ? dt.idFrom : '',
                    order_id: dt.order_id ? dt.order_id : '',
                    media: dt.url && (dt.contentType == 'image' || dt.contentType == 'audio' || dt.contentType == 'video' || dt.contentType == 'doc') ? dt.url : '',
                    timestamp: dt.timestamp ? dt.timestamp : '',
                    isRead: dt.isread,
                    product: dt.product ? dt.product : '',
                })
            }
        });
        callback(data);
    });
}

//Submit Messsage User Chat
export const submitUserChatMessage = async (data) => {
    //new Date().getTime().toString()
    await db.collection(general_chatRoom_live)
        .doc(data.chatId.toString())
        .collection(data.chatId.toString())
        .doc(data.timestamp.toString()).set(data);
    return true;
}

//delete message user chat
export const deleteUserChatMessage = async (data) => {
    let docRef = await db.collection(general_chatRoom_live)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            deleteAgent: data.deleted_at
        })
}

//delete for everyone in user chat
export const deleteEveryOneUserChatMessage = async (data) => {
    let docRef = await db.collection(general_chatRoom_live)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        .doc(data.message_id.toString())
        .update({
            delete: data.deleted_at,
            deleteAgent: data.deleted_at
        })
}

//read all users messages
export const readUserChatMessage = async (data) => {
    let docRef = await db.collection(general_chatRoom_live)
        .doc(data.active_thread.toString())
        .collection(data.active_thread.toString())
        //.doc(data.message_id.toString())
        .where('idFrom', '==', data.customer_id.toString())
        .get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                doc.ref.update({
                    isread: true
                });
            });
        });
}

//Update last message on user threads
export const updateUserChatLastMessage = async (userId, activeThread, message) => {
    await db.collection(general_chat_users_live)
        .doc(userId.toString())
        .collection(chat_list)
        .doc(activeThread.toString())
        .update({
            lastChat: message,
            timestamp: new Date().getTime()
        });
    return true;
}

//Check User Online
export const checkUserOnline = async (data, callback) => {
    let userdb = data.orderChat ? user : general_chat_users_live
    await db.collection(userdb).doc(data.user_id).collection(chat_list)
        .where("chatID", "==", data.active_thread.toString()).get().then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                let dt = await doc.data();
                callback(dt);
            });
        });
}
*/
