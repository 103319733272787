import React from 'react';
import {useNavigate} from "react-router-dom";
import ProfilePlaceholder from "../../../../assets/images/others/image-not-found.png";
import {ClockIcon, LockedIcon, SuccessIcon, UnlockedIcon, WarningIcon} from "../../../components/SvgComponents";
import {constants} from "../../../../utils/constants";
import moment from "moment";

export const StatusBar = ({type, props}) => {
    if (type == constants.CHAT_REQUEST_STATUS.LOCKED) {
        return (
            <span className={"statusBar locked"}>
                    <LockedIcon/> This chat has been locked
                </span>
        )
    } else if (type == constants.CHAT_REQUEST_STATUS.URGENT) {
        return (
            <span className={"statusBar urgent"}>
                    <WarningIcon/> Urgent response needed
                </span>
        )
    } else if (type == constants.CHAT_REQUEST_STATUS.REQUESTED) {
        if (props.remaining_time > 0) {
            return (
                <span className={"statusBar pending"}>
                    <ClockIcon/> You have {props.remaining_time}h to reply
                </span>
            )
        } else {
            return (
                <span className={"statusBar pending"}>
                <LockedIcon/> This chat has been locked
                 </span>
            )
        }
    } else if (type == constants.CHAT_REQUEST_STATUS.ACCEPTED) {
        return (
            <span className={"statusBar"} style={{color: 'green'}}>
                    <SuccessIcon/> You already reply to this request
                </span>
        )
    }
}

export const RequestBox = (props) => {
    const navigate = useNavigate()
    if(props.name){
        return (
        <li className={""} onClick={() => navigate('/inbox', {
            state: {
                user_id: props.userId,
            }
        })}>
            <div className={`tileComponent ${props.isActive ? "isActive" : ""}`}>
                <div className={"profileImgContainer"}>
                    <div className={"imgTile"}>
                        <img className={"img-fluid"} src={props.img ? props.img : ProfilePlaceholder}
                             alt={"Logan"}
                             title={"Logan"}/>
                    </div>
                </div>
                <div className={"profileDetails"}>
                    {
                        props.isUnderAge && <span className={"ageTag"}>Under 18</span>
                    }
                    <div className={"profileDetailsHeader"}>
                        {
                            props.name &&
                            <div style={{textTransform: 'capitalize'}}
                                 className={"profileName"}>{props.name}</div>
                        }
                        {/*{
                            props.lastSeen && <div className={"activeStatus"}>{props.lastSeen}</div>
                        }*/}
                    </div>
                    {
                        props.lastMessage && <p>{props.lastMessage}</p>
                    }
                    {
                        props.statusBar && <StatusBar type={props.statusBar} props={props}/>
                    }
                </div>
            </div>
        </li>
    )
    }

}
