import React from 'react'
import LoadingIcon from "../../assets/images/others/loading.gif"

export const LoadingComponent = () => {
    return (
        <div className={"mx-auto text-center"}>
            <img className={"img-fluid mx-auto"} src={LoadingIcon} style={{width: '100px', height: '100px'}}/>
        </div>
    )
}
