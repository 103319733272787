import {constants, apiUrl} from '../utils/constants';
import {GET} from './api.service.wrapper';

export const GetProfession = async (params) => {
    let response = await GET(apiUrl.professions, params);
    return response;
}

export const GetProfessionById = async (id) => {
    let response = await GET(apiUrl.professions + "/" + id);
    return response;
}

export const GetUserDashbaordData = async () => {
    let response = await GET(apiUrl.user_dashboard_data);
    return response;
}

/*export const UpdateProfile = async (id, data) => {
    let response = await PUT(apiUrl.users, id, data);
    await SetAuthUser(JSON.stringify(response?.data));
    return response;
}*/
