import React from "react";
import { Outlet } from "react-router-dom";
import authImg from "../../assets/images/auth/intro.png";
import logoImg from "../../assets/images/logo/logo.svg";
import Navigation from "../components/navigations/Navigation";
import {ToastContainer} from "react-toastify";

function OpenPageLayout() {
    return (
        <div className="OpenPageLayout">
            <Outlet />
            <ToastContainer/>
        </div>
    );
}

export default OpenPageLayout;
