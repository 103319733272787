import React, {useContext, useEffect, useState, useMemo} from "react";
import {Col, Container, Row, Button, Form, FloatingLabel, FormLabel} from "react-bootstrap";
import profileImg from "../../assets/images/others/profileUser.png";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {ArrowIcon} from "../components/SvgComponents";
import AccSidebar from "../components/AccSidebar";
import {useForm} from "react-hook-form";
import {Patterns, VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import {WithContext as ReactTags} from "react-tag-input";
import {MdDeleteOutline} from "react-icons/md";
import {IsCustomerLogin} from "../../App";
import {GetProfession} from "../../services/profession.service";
import {Toast, UploadImageToS3} from "../../utils/helpers";
import {EmptyLocalStorage} from "../../services/authentication.service";
import {UpdateProfile} from "../../services/user.service";
import placeholderImg from "../../assets/images/others/profilePlaceholder.png"
import {createOrUpdateUser} from "../../services/firebase.service";
import countryList from 'react-select-country-list'
import moment from "moment";
import {constants} from "../../utils/constants";
import {GetCharity} from "../../services/charity.service";

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function MyProfileProfessional() {
    let navigate = useNavigate()
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [profile, setProfile] = useState(false);
    const [profession, setProfession] = useState(false);
    const [charity, showCharity] = useState(false);
    const [messageLimit, showMessageLimit] = useState(false);
    const [charities, setCharities] = useState([]);
    const options = useMemo(() => countryList().getData(), [])
    const [disableButton, setDisableButton] = useState(false);
    const [imageError, showImageError] = useState(false);
    const [socialAccount, showSocialAccount] = useState(false);
    const {register, handleSubmit, setFocus, reset, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (isCustomerLogin) {
            reset({
                'bio': isCustomerLogin.about,
                'charity_mode': isCustomerLogin.charity_mode,
                'charity_id': isCustomerLogin.charity_id,
                'location': isCustomerLogin.location,
                'message_characters': isCustomerLogin.message_characters,
                'unlimited_message': isCustomerLogin.unlimited_message,
                'message_price': isCustomerLogin.message_price,
                'profession': isCustomerLogin.profession_id,
                'taking_messages': isCustomerLogin.taking_messages,
                'username': isCustomerLogin.username,
                'first_name': isCustomerLogin.first_name,
                'last_name': isCustomerLogin.last_name,
                'email': isCustomerLogin.email,
                'payment_link': isCustomerLogin.payment_link,
                'social_account': isCustomerLogin.social_account,
                'social_link': isCustomerLogin.social_link,
                'dob': isCustomerLogin.dob ? new Date(isCustomerLogin.dob).toISOString().split('T')[0] : '',
            })

            if (isCustomerLogin.charity_mode == 1) {
                showCharity(true)
            }

            if (isCustomerLogin.unlimited_message == 1) {
                showMessageLimit(true)
            }

            if (isCustomerLogin.social_account) {
                showSocialAccount(true)
            }

            let categories = [];
            isCustomerLogin?.tags.forEach((t) => {
                categories.push({id: t.tag, text: t.tag});
            })
            setTags(categories);
        }
        getCharityList();
        getProfessionList();
    }, [])

    const getProfessionList = async () => {
        await GetProfession({'order-column': 'name', order: 'asc'}).then((data) => {
            if (data.status) {
                setProfession(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getCharityList = async () => {
        await GetCharity({'order-column': 'name', order: 'asc'}).then((data) => {
            if (data.status) {
                setCharities(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const handleChange = (event) => {
        if (event.target.files[0]) {
            setProfile(URL.createObjectURL(event.target.files[0]));
        } else {
            setProfile(false);
        }
    }

    /*
   * React Tags Input ADD, delete and click
   * */
    const [tags, setTags] = React.useState([]);

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    const RemoveTagComponent = (props) => {
        const {className, onRemove} = props;
        return (
            <button onClick={onRemove} className={className}>
                <MdDeleteOutline/>
            </button>
        )
    }

    const onRemove = () => {
        updateProfile(isCustomerLogin.id, {
            'image': '',
        })
    }

    const updateProfile = async (id, data) => {
        setDisableButton(true);
        await UpdateProfile(id, data).then(async (data) => {
            if (data.status) {
                createOrUpdateUser(data.data).then(() => {
                    setIsCustomerLogin(data.data)
                    Toast(data.message, true);
                    setDisableButton(false);
                })
            } else {
                Toast(data.message, false);
                setDisableButton(false);
            }
        }).catch((error) => {
            if (error?.response?.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error?.response?.data?.message, false);
                setDisableButton(false);
            }
        })
    }

    /*
    * On form submit
    * */
    const onSubmit = async (data) => {
        let tag_data = [];

        tags && tags.map((data) => {
            tag_data.push({
                user_id: isCustomerLogin.id,
                tag: data.text
            })
        })

        let params = {
            tags: tag_data,
            about: data.bio,
            charity_mode: data.charity_mode ? 1 : 0,
            charity_id: data.charity_id,
            location: data.location,
            latitude: '12.000',
            longitude: '12.000',
            unlimited_message: data.unlimited_message ? 1 : 0,
            message_price: data.msgPrice,
            profession_id: data.profession,
            taking_messages: data.taking_messages ? 1 : 0,
            username: data.username,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            dob: data.dob,
            payment_link: data.payment_link,
            social_account: data.social_account,
            social_link: data.social_link,
            is_profile_complete: 1,
        }

        if(data.unlimited_message && data.unlimited_message){
            params.message_characters = data.msgLimit
        }

        if (data.media && data.media.length > 0) {
            showImageError(false);
            let uploadImage = await UploadImageToS3(data?.media);
            if (uploadImage) {
                if (!uploadImage.success) {
                    Toast(uploadImage.message, false);
                    return false;
                }

                params.image = uploadImage.data
            }
        } else if (isCustomerLogin.image == "") {
            showImageError(true);
            setFocus('first_name');
            return false;
        }


        updateProfile(isCustomerLogin.id, params)
    }

    return (
        <div className={"mainDashboard accountSettings"}>
            <div className={"ProfileInformation"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} className={"col-xxl-4 d-none d-md-block"}>
                                    <AccSidebar userData={isCustomerLogin}/>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8} xl={{span: 7, offset: 1}}
                                     className={"col-xxl-7 offset-xxl-1"}>
                                    <h1>Profile Information</h1>
                                    <form className={"profileInfoForm"} autoComplete={"off"}
                                          onSubmit={handleSubmit(onSubmit)}>
                                        <h4>Profile Picture</h4>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                                <div className={"profileChange"}>
                                                    <div className={"imgContainer"}>
                                                        <img width="120px" height="120px" className={""}
                                                             src={profile ? profile : isCustomerLogin?.image || placeholderImg}
                                                             alt={"Profile Img"} title={"Profile Img"}
                                                             onError={({currentTarget}) => {
                                                                 currentTarget.onerror = null; // prevents looping
                                                                 currentTarget.src = placeholderImg;
                                                             }}/>

                                                        {imageError ?
                                                            <span autofocus className={"validation-error d-block"}
                                                                  role="alert">Please upload your profile picture</span> : ""}
                                                        {/*<img className={"img-fluid"} src={profileImg} alt={"profileImg"}
                                                             title={"profileImg"}/>*/}
                                                    </div>
                                                    <div className={"w-100"}>
                                                        <Row className={"g-1"}>
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Form.Group controlId="formFile"
                                                                            onChange={(e) => handleChange(e)}
                                                                            className="inputFileBtn">
                                                                    <Form.Label className={"d-block w-100"} style={{
                                                                        paddingLeft: '0',
                                                                        paddingRight: '0',
                                                                        textAlign: 'center',
                                                                        marginBottom: '0px',
                                                                    }}>Upload Image</Form.Label>
                                                                    <Form.Control type="file" name={"media"}
                                                                                  {...register("media",
                                                                                      {
                                                                                          required: {
                                                                                              value: false,
                                                                                              message: VALIDATIONS_TEXT.IMAGE_REQUIRED
                                                                                          },
                                                                                      })
                                                                                  }
                                                                                  accept="image/png,image/jpeg,image/jpg"/>
                                                                </Form.Group>

                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Button onClick={() => onRemove()}
                                                                        type={"button"}
                                                                        className={"btnSolid active w-100"}
                                                                        style={{
                                                                            paddingLeft: '0',
                                                                            paddingRight: '0',
                                                                            textAlign: 'center'
                                                                        }}
                                                                >
                                                                    Remove Image
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Button disabled={disableButton} type={"submit"}
                                                                className={"btnSolid w-100"}>
                                                            Save Profile
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <h4>Status</h4>
                                        <div className={"checkBox-container m-b-20"}>
                                            <div className={"checkBox-Group"}>
                                                <FormLabel className={""} htmlFor={"takingMessages"}>
                                                    Taking Messages
                                                </FormLabel>
                                                <Form.Check
                                                    defaultChecked={isCustomerLogin.taking_messages}
                                                    type="switch"
                                                    id="takingMessages"
                                                    {...register("taking_messages")}
                                                />
                                            </div>
                                            {/*<p>Curabitur id ex et ex pharetra mollis.</p>*/}
                                        </div>
                                        {/*<div className={"mb13"}>
                                            <div className={"dualLabels"}>
                                                <FloatingLabel
                                                    controlId="messageLimit"
                                                    label="Messages left to respond"
                                                >
                                                    <Form.Control type="number" placeholder="20" min={"0"} max={"1000"}
                                                                  {...register('msgLimit', {
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.MSG_REQUIRED
                                                                      },
                                                                  })}
                                                    />
                                                    <span>messages</span>
                                                </FloatingLabel>
                                            </div>
                                            {errors.msgLimit &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.msgLimit.message}</span>}
                                        </div>*/}
                                        <div className={"checkBox-container m-b-20"}>
                                            <div className={"checkBox-Group"}>
                                                <FormLabel className={""} htmlFor={"charityMode"}>
                                                    Charity Mode
                                                </FormLabel>
                                                <Form.Check
                                                    defaultChecked={isCustomerLogin.charity_mode}
                                                    type="switch"
                                                    id="charityMode"
                                                    onClick={(e) => {
                                                        e.target.checked ? showCharity(true) : showCharity(false)
                                                    }}
                                                    {...register("charity_mode")}
                                                />
                                            </div>
                                            {/*<p>Curabitur id ex et ex pharetra mollis.</p>*/}
                                        </div>

                                        {
                                            charity || isCustomerLogin.charity_mode ?
                                                <div className={charity ? 'mb13' : 'mb13 d-none'}>
                                                    <FloatingLabel controlId="charity_id" label="Charity Partner">
                                                        <Form.Select aria-label="charity_id" {...register("charity_id",
                                                            {
                                                                required: {
                                                                    value: true,
                                                                    message: VALIDATIONS_TEXT.CHARITY_REQUIRED
                                                                },
                                                            })
                                                        }>
                                                            <option value="">Select Chairty</option>
                                                            {
                                                                charities && charities.map((dt) => {
                                                                    return <option
                                                                        selected={isCustomerLogin.charity_id == dt.id}
                                                                        value={dt.id}>{dt.name}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    {errors.charity_id &&
                                                    <span className={"validation-error"}
                                                          role="alert">{errors.charity_id.message}</span>}
                                                </div> : ''
                                        }


                                        <h4>Account info</h4>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formNameF"
                                                label="First Name"
                                            >
                                                <Form.Control type="text"
                                                              autoComplete={"off"}
                                                              placeholder="First Name"
                                                              defaultValue={isCustomerLogin?.first_name || ''}
                                                              {...register("first_name",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.NAME,
                                                                          message: VALIDATIONS_TEXT.NAME_MAX
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.FNAME_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.first_name &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.first_name.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formNameL"
                                                label="Last Name"
                                            >
                                                <Form.Control type="text"
                                                              autoComplete={"off"}
                                                              placeholder="Last Name"
                                                              defaultValue={isCustomerLogin?.last_name || ''}
                                                              {...register("last_name",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.NAME,
                                                                          message: VALIDATIONS_TEXT.NAME_MAX
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.LNAME_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.last_name &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.last_name.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formEmail"
                                                label="Email address"
                                            >
                                                <Form.Control type="text" autoComplete={"off"}
                                                              placeholder="Email address"
                                                              defaultValue={isCustomerLogin?.email || ''}
                                                              {...register("email",
                                                                  {
                                                                      maxLength: {
                                                                          value: VALIDATIONS.EMAIL,
                                                                          message: VALIDATIONS_TEXT.EMAIL_MAX
                                                                      },
                                                                      pattern: {
                                                                          value: Patterns.Email,
                                                                          message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.email &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.email.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formUsername"
                                                label="Username"
                                            >
                                                <Form.Control type="text"
                                                              autoComplete={"off"}
                                                              placeholder="Username"
                                                              defaultValue={isCustomerLogin?.username || ''}
                                                              {...register("username",
                                                                  {
                                                                      pattern: {
                                                                          value: Patterns.Username,
                                                                          message: VALIDATIONS_TEXT.USERNAME_VALID
                                                                      },
                                                                      maxLength: {
                                                                          value: VALIDATIONS.USERNAME,
                                                                          message: VALIDATIONS_TEXT.USERNAME_MAX
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.USERNAME_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.username &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.username.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="formUsername"
                                                label="Date of birth"
                                            >
                                                <Form.Control type="date" autoComplete={"off"}
                                                              placeholder="Date of birth"
                                                              max={moment().subtract(constants.DATE_AGE_LIMIT, 'years').format('YYYY-MM-DD')}
                                                              defaultValue={isCustomerLogin.dob ? new Date(isCustomerLogin.dob).toISOString().split('T')[0] : ''}
                                                              {...register("dob",
                                                                  {
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.DOB_REQUIRED
                                                                      },
                                                                  })
                                                              }/>
                                            </FloatingLabel>
                                            {errors.dob &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.dob.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel controlId="location" label="Location">
                                                <Form.Select aria-label="location" {...register("location",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.LOCATION_REQUIRED
                                                        },
                                                    })
                                                }>
                                                    <option value="">Select Location</option>
                                                    {
                                                        options && options.map((dt) => {
                                                            return <option
                                                                selected={isCustomerLogin.location == dt.label}
                                                                value={dt.label}>{dt.label}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                            {errors.location &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.location.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="Profession"
                                                label="Profession"
                                            >
                                                <Form.Select aria-label="profession" {...register("profession",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.PROFESSION_REQUIRED
                                                        },
                                                    })
                                                }>
                                                    <option value="">Select Profession</option>
                                                    {
                                                        profession && profession.map((data) => {
                                                            return (<option
                                                                selected={isCustomerLogin.profession_id == data.id}
                                                                value={data.id}>{data.name}</option>)
                                                        })
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                            {errors.profession &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.profession.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="payment_link"
                                                label="Paypal Email to Receive Payments"
                                            >
                                                <Form.Control type="email" placeholder="payment link"
                                                              maxLength="500"
                                                              defaultValue={isCustomerLogin?.payment_link || ''}
                                                              {...register("payment_link",
                                                                  {
                                                                      pattern: {
                                                                          value: Patterns.Email,
                                                                          message: VALIDATIONS_TEXT.EMAIL_VALID
                                                                      },
                                                                      required: {
                                                                          value: true,
                                                                          message: VALIDATIONS_TEXT.PAYMENT_LINK_REQUIRED
                                                                      },
                                                                  })
                                                              }
                                                />
                                            </FloatingLabel>
                                            {errors.payment_link &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.payment_link.message}</span>}
                                        </div>

                                        <div className={"mb13"}>
                                            <FloatingLabel
                                                controlId="social_account"
                                                label="Platform Where Admin Can Contact"
                                                onChange={(e) => {
                                                    e.target.value.length > 0 ? showSocialAccount(true) : showSocialAccount(false)
                                                }}
                                            >
                                                <Form.Select aria-label="social_account" {...register("social_account",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.SOCIAL_REQUIRED
                                                        },
                                                    })
                                                }>
                                                    <option value="">Select Platform</option>
                                                    <option selected={isCustomerLogin?.social_account == 'facebook'}
                                                            value="facebook">facebook
                                                    </option>
                                                    <option selected={isCustomerLogin?.social_account == 'twitter'}
                                                            value="twitter">twitter
                                                    </option>
                                                    <option selected={isCustomerLogin?.social_account == 'snapchat'}
                                                            value="snapchat">snapchat
                                                    </option>
                                                    <option selected={isCustomerLogin?.social_account == 'instagram'}
                                                            value="instagram">instagram
                                                    </option>
                                                    <option selected={isCustomerLogin?.social_account == 'linkedIn'}
                                                            value="linkedIn">linkedIn
                                                    </option>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <small style={{fontSize:'12px', fontWeight:'500'}}>In order to verify your account, we need to contact you on one of your verified social accounts</small>
                                                <br/>
                                            {errors.social_account &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.social_account.message}</span>}
                                        </div>

                                        {
                                            socialAccount ?
                                                <div className={"mb13"}>
                                                    <FloatingLabel
                                                        controlId="social_link"
                                                        label="Social Link"
                                                    >
                                                        <Form.Control type="text" placeholder="social link"
                                                                      maxLength="500"
                                                                      defaultValue={isCustomerLogin?.social_link || ''}
                                                                      {...register("social_link",
                                                                          {
                                                                              pattern: {
                                                                                  value: Patterns.Url,
                                                                                  message: VALIDATIONS_TEXT.URL_VALID
                                                                              },
                                                                              required: {
                                                                                  value: true,
                                                                                  message: VALIDATIONS_TEXT.SOCIAL_LINK_REQUIRED
                                                                              },
                                                                          })
                                                                      }
                                                        />
                                                    </FloatingLabel>
                                                    <small style={{fontSize:'12px', fontWeight:'500'}}>We will need this social account to contact you to confirm your identity</small>
                                                    <br/>
                                                    {errors.social_link &&
                                                    <span className={"validation-error"}
                                                          role="alert">{errors.social_link.message}</span>}
                                                </div> : ''
                                        }

                                        <h4>Bio</h4>
                                        <div className={"mb13"}>
                                            <FloatingLabel controlId="bio" label="Enter Bio">
                                                <Form.Control
                                                    as="textarea"
                                                    type="text"
                                                    placeholder="Nunc congue egestas turpis proin nulla. Dui duis ac consequat in. Et tristique consectetur at ultricies."
                                                    rows={"5"}
                                                    maxLength={VALIDATIONS.BIO_MAX}
                                                    defaultValue={isCustomerLogin?.about || ''}
                                                    {...register('bio', {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.BIO_REQUIRED
                                                        },
                                                        maxLength: {
                                                            value: VALIDATIONS.BIO_MAX,
                                                            message: VALIDATIONS_TEXT.BIO_MAX
                                                        },
                                                    })}
                                                />
                                            </FloatingLabel>
                                            {errors.bio &&
                                            <span className={"validation-error"}
                                                  role="alert">{errors.bio.message}</span>}
                                        </div>

                                        <h4>Pricing Configuration</h4>
                                        <Row className={""}>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className={"mb13"}>
                                                    <div className={"dualLabels"}>
                                                        <FloatingLabel
                                                            controlId="messagePrice"
                                                            label="Message Price"
                                                        >
                                                            <Form.Control type="number"
                                                                          placeholder="20" min={"1"}
                                                                          max={"10000000"}
                                                                          defaultValue={isCustomerLogin?.message_price || ''}
                                                                          {...register('msgPrice', {
                                                                              valueAsNumber: {
                                                                                  value: true,
                                                                                  message: "value should be a number only"
                                                                              },
                                                                              required: {
                                                                                  value: true,
                                                                                  message: VALIDATIONS_TEXT.MSG_REQUIRED
                                                                              },
                                                                          })}
                                                            />
                                                            <span>tokens</span>
                                                        </FloatingLabel>
                                                    </div>
                                                    {errors.msgPrice &&
                                                    <span className={"validation-error"}
                                                          role="alert">{errors.msgPrice.message}</span>}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className={""}>
                                            <div className={"checkBox-container m-b-20"}>
                                                <div className={"checkBox-Group"}>
                                                    <FormLabel className={""} htmlFor={"unlimitedMessage"}>
                                                        Message Limit
                                                    </FormLabel>
                                                    <Form.Check
                                                        defaultChecked={isCustomerLogin.unlimited_message}
                                                        type="switch"
                                                        id="unlimitedMessage"
                                                        onClick={(e) => {
                                                            if(e.target.checked){
                                                                showMessageLimit(true)
                                                            }
                                                            else{
                                                                showMessageLimit(false)
                                                            }
                                                        }}
                                                        {...register("unlimited_message")}
                                                    />
                                                </div>
                                                {/*<p>Curabitur id ex et ex pharetra mollis.</p>*/}
                                            </div>

                                            {
                                                messageLimit ?
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className={messageLimit ? 'mb13' : 'mb13 d-none'}>
                                                            <div className={"dualLabels"}>
                                                                <FloatingLabel
                                                                    controlId="messageLimit"
                                                                    label="Messages limit"
                                                                >
                                                                    <Form.Control type="number"
                                                                                  placeholder="20"
                                                                                  min={"1"}
                                                                                  max={"1000"}
                                                                                //disabled={messageLimit || isCustomerLogin?.unlimited_message == 1}
                                                                                  defaultValue={isCustomerLogin?.message_characters || ''}
                                                                                  {...register('msgLimit', {
                                                                                      valueAsNumber: {
                                                                                          value: true,
                                                                                          message: "value should be a number only"
                                                                                      },
                                                                                      required: {
                                                                                          value: true,
                                                                                          message: VALIDATIONS_TEXT.MSG_LIMIT_REQUIRED
                                                                                      },
                                                                                  })}
                                                                    />
                                                                    <span>messages</span>
                                                                </FloatingLabel>
                                                            </div>
                                                            {errors.msgLimit &&
                                                            <span className={"validation-error"}
                                                                  role="alert">{errors.msgLimit.message}</span>}
                                                        </div>
                                                    </Col> : ''}
                                        </Row>

                                        <h4>Tags</h4>

                                        <h4>Add Categories</h4>
                                        <div className={"mb13"}>
                                            <div className={"TagsContainer"}>
                                                <ReactTags
                                                    tags={tags}
                                                    delimiters={delimiters}
                                                    handleDelete={handleDelete}
                                                    handleAddition={handleAddition}
                                                    handleTagClick={handleTagClick}
                                                    removeComponent={RemoveTagComponent}
                                                    inputFieldPosition="inline"
                                                    autofocus={false}
                                                    autocomplete
                                                />
                                            </div>
                                        </div>

                                        <Button disabled={disableButton} type={"submit"}
                                                className={"btn-solid w-100 m-t-30"}>
                                            Save
                                        </Button>
                                    </form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default MyProfileProfessional;
