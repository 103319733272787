import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {constants} from "../../../../utils/constants";
import {getChatCounts} from "../../../../services/firebase.service";

export const PendingRequest = ({stats, user_id, requestCount, blockCount}) => {
    const [messageCount, setMessageCount] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        getChatCounts(user_id, (data) => {
            setMessageCount(data)
        });
    }, [])

    return (
        <>
            <h4 className={"m-t-30"}>Pending Requests</h4>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={"requestBox isPending"} onClick={() => {
                        navigate('/inbox', {
                            state: {
                                requestBox: constants.CHAT_REQUEST_STATUS.ACCEPTED
                            }
                        })
                    }} style={{cursor: 'pointer'}}>
                        {/*<h4>{stats.accepted_requests}</h4>*/}
                        <h4>{messageCount?.totalRepliedMessage || 0}</h4>
                        <p>Messages Replied</p>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={"requestBox isLocked"} onClick={() => {
                        navigate('/inbox', {
                            state: {
                                requestBox: constants.CHAT_REQUEST_STATUS.LOCKED
                            }
                        })
                    }} style={{cursor: 'pointer'}}>
                        {/*<h4>{stats.locked_requests}</h4>*/}
                        <h4>{messageCount?.totalLockedMessage || 0}</h4>
                        <p>Locked Messages</p>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={"requestBox isUrgent"} onClick={() => {
                        navigate('/inbox', {
                            state: {
                                requestBox: constants.CHAT_REQUEST_STATUS.REQUESTED
                            }
                        })
                    }} style={{cursor: 'pointer'}}>
                        {/*<h4>{requestCount}</h4>*/}
                        <h4>{messageCount?.totalRespondedMessage || 0}</h4>
                        <p>Messages To Respond</p>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={"requestBox isBlocked"} onClick={() => {
                        navigate('/blocked-users')
                    }} style={{cursor: 'pointer'}}>
                        <h4>{blockCount}</h4>
                        <p>Blocked Users</p>
                    </div>
                </Col>
            </Row>
        </>
    )
}