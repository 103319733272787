import React, {useContext, useEffect, useState} from "react";
import {LeftIcon, SuccessIcon} from "../components/SvgComponents";
import {Link, useNavigate, useLocation, Navigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import {EmptyLocalStorage, ForgetPassword} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {IsCustomerLogin} from "../../App";

const ResetPasswordSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false)
    const [resend, setResend] = useState(false);
    const [counter, setCounter] = useState(10);


    useEffect(() => {
        if (!location?.state?.email) {
            navigate("/forgot-password")
        } else {
            setShow(true);
        }
    }, [])

    /*useEffect(() => {
        setTimeout(() => {
            if (!resend) {
                setResend(true)
            }
        }, 10000)
    }, [resend])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCounter(counter - 1);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [counter]);*/

    const onResend = async (email) => {
        if (email) {
            setResend(true)
            setCounter(10)
            await ForgetPassword(email).then((data) => {
                if (data.status) {
                    Toast(data.message, true);
                    navigate("/success", {
                        state: {
                            email: email
                        }
                    });
                } else {
                    Toast(data.message, false);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    if (show) {
        return (
            <div className={"SuccessModal codeSentSuccess"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-50"}>
                        <SuccessIcon/>
                    </div>
                    <h2>Check your email</h2>
                    <p>Please follow the instructions that we have<br/> sent to your email.</p>
                </div>
                <div className={"mx-auto text-center m-t-40"}>
                    {
                        resend ?
                            <p className={"p-small"}>Didn’t get the code? <span onClick={() => {
                                onResend(location.state.email)
                                setResend(false)
                            }} style={{fontWeight: 'bold', cursor: 'pointer'}}>Send again</span></p> :
                            <>
                                <p className={"p-small"}>Didn’t get the code? Send again</p>
                                <h1>{counter}</h1>
                            </>
                    }

                </div>
            </div>
        )
    }
}

const PasswordChangeSuccess = () => {
    return (
        <div className={"passwordChangedSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-50"}>
                    <SuccessIcon/>
                </div>
                <h2>Password has been changed</h2>
                {/*<p>Please follow the instructions that we have<br/> sent to your email and we’ll verify
                    your<br/> account in no time!</p>*/}
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <Link to={"/"} className={"backToBtn"}>
                    <LeftIcon/> Back to login
                </Link>
            </div>
        </div>
    )
}

const AccountVerifiedSuccess = () => {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            navigate("/configure-profile");
        }, 3000);
    })
    return (
        <div className={"passwordChangedSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-50"}>
                    <SuccessIcon/>
                </div>
                <h2>Your account has been<br/> verified!</h2>
                {/*<p>Please follow the instructions that we have sent to your<br/> email and we’ll verify your account in
                    no time!</p>*/}
            </div>
            {/*<div className={"mx-auto text-center m-t-40"}>
                <Link to={"/"} className={"btn-solid mx-auto"}>
                    Login
                </Link>
            </div>*/}
        </div>
    )
}

const AccountCreatedSuccess = () => {
    return (
        <div className={"passwordChangedSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-50"}>
                    <SuccessIcon/>
                </div>
                <h2>Your account has been<br/> successfully created</h2>
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <Link to={"/"} className={"backToBtn"}>
                    <LeftIcon/> Back to login
                </Link>
            </div>
        </div>
    )
}

const renderComponent = (type) => {
    //console.log(type);
    if (type === "reset-password") {
        return <ResetPasswordSuccess/>
    } else if (type === "verification-code") {
        return <PasswordChangeSuccess/>
    } else if (type === "account-verified") {
        return <AccountVerifiedSuccess/>
    } else if (type === "account-created") {
        return <AccountCreatedSuccess/>
    }
}

function Success({type}) {
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    return (
        !isCustomerLogin ?
            <Navigate to={"/"}/>
            :
            <div className={"viewportFull"}>
                <div className={"d-flex align-items-center justify-content-center h-100"}>
                    <div className={"componentContainer"}>
                        <div className={"successPage"}>
                            {
                                renderComponent(type)
                            }
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default Success;
