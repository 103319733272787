import React, {useEffect, useState} from "react";
import {LeftIcon, SuccessIcon} from "../components/SvgComponents";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";


const Error404 = () => {
    const navigate = useNavigate();
    return(
        <div className={"codeSentSuccess"}>
            <div className={"mx-auto text-center"}>
                <div className={"m-b-20"}>
                    <h1 className={"error404Text"}>500</h1>
                </div>
                <h2>Feeling Lost?</h2>
                <p>Let us help you go back!!</p>
            </div>
            <div className={"mx-auto text-center m-t-40"}>
                <p className={"p-small"}><LeftIcon/> <span onClick={()=>{navigate('/')}} style={{cursor:'pointer'}}>Go Back</span></p>
            </div>
        </div>
    )
}



const renderComponent = (type) => {
    if(type === "404"){
        return <Error404 />
    }
}

function ProfileNotFound({type}) {
    return (
        <div className={"viewportFull"}>
            <div className={"d-flex align-items-center justify-content-center h-100"}>
                <div className={"componentContainer"}>
                    <div className={"successPage"}>
                        {
                            renderComponent(type)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileNotFound;
