import React, {useState, useCallback} from 'react'
import {getUnreadCount} from "../../../services/firebase.service";

export const GetUnreadCount = ({search, thread}) => {
    const [unReadCount, setUnReadCount] = useState(0)

    useCallback(getUnreadCount(thread.chat_id, thread.user_id, (data) => {
        setUnReadCount(data);
    }), [search])

    if (unReadCount != 0) {
        return (
            <span
                style={{
                    background: '#3d2570',
                    borderRadius: '20px',
                    color: '#ffffff',
                    width: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >{unReadCount}</span>
        )
    }
}
