import React, {useContext, useEffect, useState} from 'react';
import {Container, Row, Col, Button, FormLabel, Form, FloatingLabel} from "react-bootstrap";
import AccountNotification from "../../components/AccountNotification";
import {ClockIcon, EmptyListIcon, SuccessIcon, UnlockedIcon, WarningIcon} from "../../components/SvgComponents";
import ourChatAvatar from "../../../assets/images/others/our-chat-avatar.png";
import Profile01 from "../../../assets/images/others/feed/feed1.png";
import ProfilePlaceholder from "../../../assets/images/others/image-not-found.png";
import {GetProfile, UpdateProfile} from "../../../services/user.service";
import {useNavigate} from "react-router-dom";
import {EmptyLocalStorage, GetCurrentUser} from "../../../services/authentication.service";
import {Route, Navigate, Outlet} from "react-router";
import {IsCustomerLogin} from "../../../App";
import {get_firebase_token, Toast} from "../../../utils/helpers";
import {GetUserDashbaordData} from "../../../services/profession.service";
import {GetChatRequest} from "../../../services/chat_request.service";
import moment from "moment";
import {constants} from "../../../utils/constants";
import {Revenue} from "./component/Revenue";
import {createOrUpdateUser, isOnline, updateThis} from "../../../services/firebase.service";
import {GetBlockUsers} from "../../../services/block_user.service";
import {LoadingComponent} from "../../components/LoadingComponent";
import ThemeModal from "../../components/ThemeModal";
import {VALIDATIONS_TEXT} from "../../utils/constants";
import {useForm} from "react-hook-form";
import {GetCharity} from "../../../services/charity.service";
import {PriceConfiguration} from "./component/priceconfiguration";
import {auth} from "../../../utils/firebase-config";

function Dashboard() {
    const navigate = useNavigate();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [verificationStatus, setVerificationStatus] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [show, setShow] = useState(false);
    const [data, setData] = useState(false);
    const [requestData, setRequestData] = useState(null);
    const [requestMeta, setRequestMeta] = useState(null);
    const [requestCount, setRequestCount] = useState(0)
    const [pageLoading, setPageLoading] = useState(false)

    // const {register, handleSubmit, reset, watch, formState: {errors}} = useForm({
    //     mode: "onChange"
    // });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        get_firebase_token((token) => {
            return auth.signInWithCustomToken(token)
        });
    }, [])

    useEffect(() => {
        GetRequests()
    }, [])



    const GetRequests = async () => {
        if (isCustomerLogin && isCustomerLogin.is_approved === 1) {
            await GetChatRequest({
                professional_id: isCustomerLogin.id,
                status: constants.CHAT_REQUEST_STATUS.REQUESTED
            }).then(async (data) => {
                if (data.status) {
                    await setRequestData(data.data.data)
                    await setRequestMeta(data.data.meta)
                    if (data.data.data) {
                        let count = 0
                        data.data.data.map((request) => {
                            if (request.status != constants.CHAT_REQUEST_STATUS.ACCEPTED) {
                                count = count + 1;
                            }
                        })
                        setRequestCount(count);
                    }
                    setPageLoading(true)
                } else {
                    Toast(data.message, false);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }
    }

    const NotApproved = () => {
        return (
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                        <AccountNotification/>
                        <h1 className={"m-t-30"}>Dashboard</h1>
                        <Row className={"m-t-20"}>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className={""}>
                                <h4>Revenue</h4>
                                <div className={"statBox"}>
                                    <Row className={"align-items-center"}>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <h2>$0</h2>
                                            <p>alltime revenue</p>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <ul className={"list-unstyled listStats"}>
                                                <li>
                                                    <div>
                                                        <span>Today</span>
                                                        <span>$0</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <span>This week</span>
                                                        <span>$0</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <span>This month</span>
                                                        <span>$0</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>

                                <h4 className={"m-t-30"}>Pending requests</h4>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <div className={"requestBox isPending"}>
                                            <h4>0</h4>
                                            <p>regular requests</p>
                                        </div>
                                    </Col>
                                    {/*<Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <div className={"requestBox isUrgent"}>
                                            <h4>0</h4>
                                            <p>urgent requests</p>
                                        </div>
                                    </Col>*/}
                                    <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <div className={"requestBox isLocked"}>
                                            <h4>0</h4>
                                            <p>locked requests</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={{span: 5, offset: 1}} xl={{span: 5, offset: 1}}>
                                <h4>Charity Revenue</h4>
                                <div className={"statBox"}>
                                    <Row className={"align-items-center"}>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <h2>$0</h2>
                                            <p>alltime revenue</p>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <ul className={"list-unstyled listStats"}>
                                                <li>
                                                    <div>
                                                        <span>Today</span>
                                                        <span>$0</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <span>This week</span>
                                                        <span>$0</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <span>This month</span>
                                                        <span>$0</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={"spacer"} style={{marginTop: "30px"}}></div>
                                <h4>Latest Requests</h4>
                                <div className={`requestList isEmpty`}>
                                    <div className={"EmptyIconBox h-100"}>
                                        <div
                                            className={"mx-auto text-center h-100 d-flex flex-column align-contents-center justify-content-center align-items-center"}>
                                            <EmptyListIcon/>
                                            <p>No request yet.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <div className={"spacer"} style={{marginBottom: '100px'}}></div>
                </Row>
            </Container>
        )
    }

    const Approved = () => {
        const [stats, setStats] = useState({});
        const [blockCount, setBlockCount] = useState(0);

        useEffect(() => {
            getUserDashboardData()
            getBlockUser()
        }, [])

        const getUserDashboardData = async () => {
            await GetUserDashbaordData().then((data) => {
                if (data.status) {
                    //Toast(data.message, true);
                    setStats(data.data);
                } else {
                    Toast(data.message, false);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }

        const getBlockUser = async () => {
            await GetBlockUsers().then((data) => {
                if (data.status) {
                    setBlockCount(data.data.length)
                } else {
                    Toast(data.message, false);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <h1 className={"m-t-30"}>Dashboard</h1>
                            <Revenue user_id={isCustomerLogin?.id} stats={stats} blockCount={blockCount} requestData={requestData}
                                     requestCount={requestCount}/>
                        </Col>
                        <div className={"spacer"} style={{marginBottom: '50px'}}></div>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className={"col-xxl-5 offset-xxl-1"}>
                            <DashboardControls/>
                        </Col>
                        <div className={"spacer"} style={{marginBottom: '50px'}}></div>
                    </Row>
                </Container>
            </div>
        )
    }

    const CheckVerify = () => {
        if (isCustomerLogin && isCustomerLogin.is_verified === 0 && isCustomerLogin.is_profile_complete === 0) {
            navigate('/account-verification-professional')
        } else if (isCustomerLogin && isCustomerLogin.is_verified === 1 && isCustomerLogin.is_profile_complete === 0) {
            navigate('/configure-profile')
        }
    }

    const clickLoadMore = () => {

    }

    const switchCase = (key, value) => {
        switch (key) {
            case 'taking_messages':
                return value ? 'Enable taking messages' : 'Disabled taking messages';
            case 'charity_mode':
                return value ? 'Enable charity mode' : 'Disabled charity mode';
            case 'show_name':
                return value ? 'Enable show name' : 'Disabled show name';
            default:
                return ''
        }
    }

    const changeStatus = (data, key, value) => {
        let message = switchCase(key, value);
        updateProfile(isCustomerLogin.id, data, message)
    }

    const updateProfile = async (id, data, message = "") => {
        await UpdateProfile(id, data).then(async (data) => {
            if (data.status) {
                await createOrUpdateUser(data.data).then(() => {
                    Toast(message ? message : data.message, true);
                })
            } else {
                await createOrUpdateUser(data.data).then(() => {
                    Toast(message ? message : data.message, false);
                })
            }
            setIsCustomerLogin(data.data)
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    * Dashboard Status
    * */
    const DashboardControls = () => {
        const [showCharityModal, setShowCharityModal] = useState(false);
        const [disableButton, setDisableButton] = useState(false);
        const [charities, setCharities] = useState([]);
        const [selectedCharity, setSelectedCharity] = useState([]);

        useEffect(() => {
            getCharityList();
        }, [])

        const getCharityList = async () => {
            await GetCharity({'order-column': 'name', order: 'asc'}).then((data) => {
                if (data.status) {
                    setCharities(data.data)
                } else {
                    Toast(data.message, false);
                }
            }).catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error.response.data.message, false);
                }
            })
        }

        const CharityModalContent = ({charities, setSelectedCharity, isCustomerLogin, changeStatus, selectedCharity, setShowCharityModal}) => {
            return (
                <>
                    <div className={'mb13'}>
                        <FloatingLabel controlId="charity_id" label="Charity Partner">
                            <Form.Select aria-label="charity_id" defaultValue={selectedCharity} onChange={(e) => {
                                setSelectedCharity(e.target.value)
                            }}>
                                <option value="">Select Chairty</option>
                                {
                                    charities && charities.map((dt, index) => {
                                        return <option
                                            key={index}
                                            selected={isCustomerLogin.charity_id == dt.id}
                                            value={dt.id}>{dt.name}</option>
                                    })
                                }
                            </Form.Select>
                        </FloatingLabel>
                    </div>
                    <Button onClick={(event) => {
                        if(selectedCharity == ''){
                            Toast('Please select charity');
                            return false;
                        }
                        changeStatus(
                            {
                                'charity_mode': 1,
                                'charity_id': selectedCharity,
                            }, 'charity_mode', true)

                        setShowCharityModal(false)
                    }
                    }
                            disabled={disableButton} type={"submit"} className={"btn-solid w-100"}>
                        Submit
                    </Button>
                </>
            )
        }


        const updateProfile = async (id, data) => {
            setDisableButton(true);
            await UpdateProfile(id, data).then(async (data) => {
                if (data.status) {
                    createOrUpdateUser(data.data).then(() => {
                        setIsCustomerLogin(data.data)
                        Toast(data.message, true);
                        setDisableButton(false);
                    })
                } else {
                    Toast(data.message, false);
                    setDisableButton(false);
                }
            }).catch((error) => {
                if (error?.response?.status == 401) {
                    EmptyLocalStorage()
                    navigate('/');
                } else {
                    return Toast(error?.response?.data?.message, false);
                    setDisableButton(false);
                }
            })
        }


        return (
            <div>
                <div className={"checkBox-container m-b-20"}>
                    <div className={"checkBox-Group"}>
                        <FormLabel className={""} htmlFor={"takingMessages"}>
                            Taking Messages
                        </FormLabel>
                        <Form.Check
                            defaultChecked={isCustomerLogin && isCustomerLogin.taking_messages == 1 ? true : false}
                            type="switch"
                            id="takingMessages"
                            onClick={(event) => changeStatus({
                                'taking_messages': event.target.checked ? 1 : 0
                            }, 'taking_messages', event.target.checked)}
                        />
                    </div>
                    {/*<p>Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>*/}
                </div>
                <hr/>
                <div className={"checkBox-container m-b-20"}>
                    <div className={"checkBox-Group"}>
                        <FormLabel className={""} htmlFor={"charityMode"}>
                            Charity Mode
                        </FormLabel>
                        {
                            isCustomerLogin && isCustomerLogin.charity_id != null ?
                                <Form.Check
                                    defaultChecked={isCustomerLogin && isCustomerLogin.charity_mode == 1 ? true : false}
                                    type="switch"
                                    id="charityMode"
                                    onClick={(event) => changeStatus({
                                        'charity_mode': event.target.checked ? 1 : 0
                                    }, 'charity_mode', event.target.checked)}
                                /> :
                                <Form.Check
                                    defaultChecked={isCustomerLogin && isCustomerLogin.charity_mode == 1 ? true : false}
                                    type="switch"
                                    id="charityMode"
                                    onClick={() => setShowCharityModal(true)}
                                />
                        }
                        {/*<Form.Check
                            defaultChecked={isCustomerLogin && isCustomerLogin.charity_mode == 1 ? true : false}
                            type="switch"
                            id="charityMode"
                            // onClick={(event) => changeStatus({
                            //     'charity_mode': event.target.checked ? 1 : 0
                            // }, 'charity_mode', event.target.checked)}
                            onClick={() => setShowCharityModal(true)}
                        />*/}
                    </div>
                    {/*<p>Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>*/}
                </div>
                <hr/>
                <div className={"checkBox-container m-b-20"}>
                    <div className={"checkBox-Group"}>
                        <FormLabel className={""} htmlFor={"showName"}>
                            Show Name
                        </FormLabel>
                        <Form.Check
                            defaultChecked={isCustomerLogin && isCustomerLogin.show_name == 1 ? true : false}
                            type="switch"
                            id="showName"
                            onClick={(event) => changeStatus({
                                'show_name': event.target.checked ? 1 : 0
                            }, 'show_name', event.target.checked)}
                        />
                    </div>
                    {/*<p>Curabitur id ex et ex pharetra mollis. Duis placerat, augue.</p>*/}
                </div>
                <hr/>
                <PriceConfiguration updateProfile={updateProfile}/>

                <ThemeModal title={"Select Charity"} content={<CharityModalContent
                    charities={charities}
                    setSelectedCharity={setSelectedCharity}
                    isCustomerLogin={isCustomerLogin}
                    changeStatus={changeStatus}
                    selectedCharity={selectedCharity}
                    setShowCharityModal={setShowCharityModal}
                />
                } className={"addCardInfo"}
                            size={"sm"}
                            show={showCharityModal}
                            onHide={() => {
                                setShowCharityModal(false)
                                //reset()
                            }}/>
            </div>
        )
    }

    if (isCustomerLogin && isCustomerLogin.is_approved === 1) {
        if (!pageLoading) {
            return <div style={{
                backgroundColor: '#ffffff',
                position: 'fixed',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'

            }}><LoadingComponent/></div>
        }
        return (
            <div className={"mainDashboard"}>
                <div className={"dashboardProfessional"}>
                    <Approved/>
                </div>
            </div>
        )
    } else {
        return (
            <div className={"mainDashboard"}>
                <div className={"dashboardProfessional"}>
                    <NotApproved/>
                </div>
            </div>
        )
    }
}

export default Dashboard
