import React, { useState } from "react";

const ReadMore = ({ children, textLength, classList }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <p className={classList}>
            {isReadMore ? text.slice(0, textLength) : text}
            {
                text.length > textLength  && <span onClick={toggleReadMore} className={"read-or-hide"}>
                                {isReadMore ? "... read more" : " read less"}
                            </span>
            }
        </p>
    );
};

export default ReadMore