import React, {useState, useEffect, useContext} from 'react'
import {Button,ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {changeFlag, unlock_message} from "../../../services/firebase.service";
import {UnlockChatRequest} from "../../../services/chat_request.service";
import {EmptyLocalStorage} from "../../../services/authentication.service";
import {useNavigate} from "react-router-dom";
import {Toast} from "../../../utils/helpers";
import {FaUnlock} from "react-icons/fa";
import ThemeModal from "../../components/ThemeModal";


export const UnlockChat = ({setAccLocked, activeThread, message}) => {
    const navigate = useNavigate();
    const [unlockModal, setUnlockModal] = useState(false);
    const [disable, setDisable] = useState(false);

    const unLockMessage = async (activeThread, message) => {
        //Set Message timestamp to now and message lock to 20
        unlock_message({
            activeThread: activeThread,
            timestamp: message.timestamp,
            message_timestamp: message.message_timestamp,
            lockMessage: 1,
            lockFlag: 0,
        })

        changeFlag(message.idFrom, activeThread);
        changeFlag(message.idTo, activeThread);
        //Unlock Chat Request
        await UnlockChatRequest({
            id: message.chatRequestId,
        }).then((data) => {
            if (data.status) {
                setUnlockModal(false)
                setAccLocked(false)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    * Unlock message component Modal
    * */
    const UnlockModalContent = ({activeThread, message}) => {
        return (
            <div className="UnblockModal">
                <p className={"text-center"}>Are you sure you want to<br/> Unlock this chat??</p>
                <div className="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={disable} className="btn-rounded-outline"
                            onClick={() => {
                                setDisable(true)
                                unLockMessage(activeThread, message)
                            }}>Unlock</Button>
                    <Button className="btn-solid" onClick={() => setUnlockModal(false)}>Cancel</Button>
                </div>
            </div>
        )
    }

    return (
        <>
            {/*<OverlayTrigger placement="top"*/}
            {/*                delay={{ hide: 0, show: 0 }}*/}
            {/*                overlay={(props) => <Tooltip id="unlock-tooltip" {...props}>Unlock Chat</Tooltip>}>*/}
            {/*    <Button type={"button"}*/}
            {/*            className={"btn-settings"}*/}
            {/*            onClick={() => {*/}
            {/*                setDisable(false)*/}
            {/*                setUnlockModal(true)*/}
            {/*            }}>*/}
            {/*        <FaUnlock/>*/}
            {/*    </Button>*/}
            {/*</OverlayTrigger>*/}

              <Button type={"button"}
                        className={"btn-settings"}
                        onClick={() => {
                            setDisable(false)
                            setUnlockModal(true)
                        }} title="Unlock Chat">
                    <FaUnlock/>
                </Button>


            <ThemeModal title={""}
                        content={<UnlockModalContent activeThread={activeThread} message={message}/>}
                        className={"addCardInfo"} size={"sm"}
                        show={unlockModal}
                        onHide={() => {
                            setUnlockModal(false)
                            // reset()
                        }}/>
        </>
    )
}