import React, {useContext, useEffect, useState} from 'react'
import {Container, Row, Col, DropdownButton, Dropdown, Button, Form} from "react-bootstrap"
import {
    Dot,
    LeftIcon,
    Star,
    VerticalIcon,
    ChatIconOutline,
    BlockIcon,
    ReportIcon,
    SuccessIcon
} from "../components/SvgComponents";
import {Link, useNavigate, useParams} from "react-router-dom"
import profileImg from "../../assets/images/others/feed/feed7-lg.png"
import placeholderImg from "../../assets/images/others/image-not-found-lg.png"
import placeholderCharity from "../../assets/images/logo/icon.png"
import ReadMore from "../components/ReadMore";
import ThemeModal from "../components/ThemeModal";
import {DeleteBlockUser, GetBlockUserById, GetUserById, PostBlockUser} from "../../services/user.service";
import {EmptyLocalStorage} from "../../services/authentication.service";
import {Toast} from "../../utils/helpers";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../utils/constants";
import moment from "moment";
import {useForm} from "react-hook-form";
import {GetReportType} from "../../services/report_type.service";
import {PostReport} from "../../services/report.service";
import {constants} from "../../utils/constants";
import {PostChatRequest} from "../../services/chat_request.service";
import {
    blockUnblockProfile,
    blockUnblockThread,
    createUserChatList,
    getUser, hideThread, removeEmptyMessageThreads
} from "../../services/firebase.service";
import {IsCustomerLogin} from "../../App";
import CharityLogo1 from "../../assets/images/temp/charity1.png"
import CharityLogo2 from "../../assets/images/temp/charity2.png"
import CharityLogo3 from "../../assets/images/temp/charity3.png"

const Status = ({status}) => {
    if (status === "active") {
        return (
            <div className={"statusContainer"}>
                <span className={"status accepting"}>
                    <Dot/> Accepting messages
                </span>
            </div>
        )
    } else if (status === "notActive") {
        return (
            <div className={"statusContainer"}>
                <span className={"status notAccepting"}>
                    <Dot/> Not accepting messages
                </span>
            </div>
        )
    }
}

const BlockStatus = ({status}) => {
    if (status) {
        return (
            <div className={"statusContainer"}>
                <span className={"status blocked"}>
                    You have blocked this person
                </span>
            </div>
        )
    }
}

function UserProfile(props) {
    const navigate = useNavigate();
    const params = useParams();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [modalBlockShow, setModalBlockShow] = useState(false);
    const [modalUnBlockShow, setModalUnBlockShow] = useState(false);
    const [modalReportShow, setModalReportShow] = useState(false);
    const [ReportSuccessModalShow, setReportSuccessModalShow] = useState(false);
    const [data, setUserProfileDetails] = useState([]);
    const [reportType, setReportType] = useState([]);
    const [isBlocked, setIsBlocked] = useState(false);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [successBlockModalShow, setSuccessBlockModalShow] = useState(false);
    const [successUnBlockModalShow, setSuccessUnBlockModalShow] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [show, setShow] = useState(false);
    const [charityImage, setCharityImage] = useState(false);
    const {register, handleSubmit, reset, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getCharityImage()
        getProfile();
        getBlockUserById();
        getReportType();
    }, [])

    const getCharityImage = async () => {
        await GetUserById(params.id).then((data) => {
            if(data.status && data?.data){
                let charity_image = data?.data?.charity?.image || placeholderImg
                setCharityImage(charity_image);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getProfile = async () => {
        await getUser(params.id, (data) => {
            if (data && data.roles[0].id == constants.ROLES.ROLE_PROFESSIONAL) {
                setUserProfileDetails({
                    profileId: data ? data.id : '',
                    profileName: data ? data?.name || data.username : '',
                    ageLimit: moment().diff(data?.dob, 'years', false) <= 18 ? 'Under 18' : '',
                    isCharityMember: data ? data.charity_mode : '',
                    status: data && data.taking_messages == 1 ? "active" : "notActive",
                    block_status: data && data.block_status == 1 ? "" : "blocked",
                    img: data ? data.image : placeholderImg,
                    category: data && data.profession ? data.profession.name : '',
                    bio: data && data.about ? data.about : 'N/A',
                    tokensCount: data ? parseInt(data.message_price) : 0,
                    categoryTags: data ? data.tags : [],
                    charity: data ? data.charity : ''
                })
                setShow(true)
            }
            else{
                navigate('/explore')
            }
        });
    }

    const getBlockUserById = async () => {
        await GetBlockUserById(params.id).then((data) => {
            if (data.status) {
                data.data ? setIsBlocked(true) : setIsBlocked(false)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const getReportType = async () => {
        await GetReportType().then((data) => {
            if (data.status) {
                setReportType(data.data)
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                return Toast(error.response.data.message, false);
            }
        })
    }

    const BlockModalContent = () => {
        return (
            <div className={"blockProfile"}>
                <p className={"text-center"}>
                    Are you sure you want to<br/>
                    block this user?
                </p>
                <Row className={"g-3"}>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid active w-100"}
                                onClick={() => setModalBlockShow(false)}>
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button disabled={disableButton} type={"button"} className={"btn-solid w-100"}
                                onClick={() => handleBlockProfile(params.id)}>
                            Block
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    const UnBlockModalContent = () => {
        return (
            <div className={"blockProfile"}>
                <p className={"text-center"}>
                    Are you sure you want to<br/>
                    unblock this user?
                </p>
                <Row className={"g-3"}>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button type={"button"} className={"btn-solid active w-100"}
                                onClick={() => setModalUnBlockShow(false)}>
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={"12"} sm={12} md={6} lg={6} xl={6}>
                        <Button disabled={disableButton} type={"button"} className={"btn-solid w-100"}
                                onClick={() => handleUnBlockProfile(params.id)}>
                            Unblock
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    const ReportModalContent = () => {
        return (
            <div className={"blockProfile"}>
                <p>
                    Reason to report
                </p>
                <form className={"contactSupport"} autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                    <Row className={"g-3 p-3"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={"mb13"}>
                                <Form.Select id={"formLocation"}
                                             {...register("report_type_id",
                                                 {
                                                     required: {
                                                         value: true,
                                                         message: VALIDATIONS_TEXT.REPORT_TYPE_REQUIRED
                                                     },
                                                 })
                                             }>
                                    <option value="">Report Type</option>
                                    {
                                        reportType && reportType.map((type) => {
                                            return (
                                                <option value={type.id}>{type.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                                {errors.report_type_id &&
                                <span className={"validation-error"}
                                      role="alert">{errors.report_type_id.message}</span>}
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={"mb13"}>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    placeholder="Description"
                                    rows={"5"}
                                    {...register('description', {
                                        required: {
                                            value: true,
                                            message: VALIDATIONS_TEXT.MESSAGE_REQUIRED
                                        },
                                        maxLength: {
                                            value: VALIDATIONS.MESSAGE_MAX,
                                            message: VALIDATIONS_TEXT.MESSAGE_MAX
                                        },
                                    })}
                                />
                                {errors.description &&
                                <span className={"validation-error"}
                                      role="alert">{errors.description.message}</span>}
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button disabled={disableButton} type={"submit"} className={"btn-solid w-100"}>
                                Report
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        )
    }

    const ReportSuccessModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>Thanks for reporting</b></h2>
                    <p className={"m-b-20"}>If we find this content to be in violation of our community guidelines we
                        will remove it.</p>
                    <Button className="btn-solid" onClick={() => setReportSuccessModalShow(false)}>Done</Button>
                </div>
            </div>
        )
    }

    const SuccessBlockModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>User Blocked</b></h2>
                    <Button className="btn-solid" onClick={() => setSuccessBlockModalShow(false)}>Done</Button>
                </div>
            </div>
        )
    }

    const SuccessUnBlockModal = () => {
        return (
            <div className={"SuccessModal codeSentSuccess py-5"}>
                <div className={"mx-auto text-center"}>
                    <div className={"m-b-20"}>
                        <SuccessIcon/>
                    </div>
                    <h2 className={"m-b-20"}><b>User Unblocked</b></h2>
                    <Button className="btn-solid" onClick={() => setSuccessUnBlockModalShow(false)}>Done</Button>
                </div>
            </div>
        )
    }

    const onSubmit = async (data) => {
        setDisableButton(true)
        await PostReport({
            report_type_id: data.report_type_id,
            description: data.description,
            instance_type: constants.ROLES.ROLE_PROFESSIONAL,
            instance_id: params.id
        }).then((data) => {
            if (data.status) {
                // Toast(data.message, true);
                reset();
                setReportSuccessModalShow(true);
                setModalReportShow(false);
                setDisableButton(false)
            } else {
                Toast(data.message, false);
                setDisableButton(false)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                setDisableButton(false)
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    *  handle Block Click
    * */
    const handleBlockProfile = async (id) => {
        setDisableButton(true)
        await PostBlockUser(id).then((data) => {
            if (data.status) {
                // Toast("User Blocked", true);
                setSuccessBlockModalShow(true)
                let activeThread = [];
                if (isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
                    activeThread = isCustomerLogin.id + '-' + id;
                    //hideThread(id + '-' + isCustomerLogin.id, isCustomerLogin, 1)
                } else {
                    activeThread = id + '-' + isCustomerLogin.id;
                    //hideThread(isCustomerLogin.id + '-' + id, isCustomerLogin, 1)
                }
                blockUnblockProfile(activeThread, 1, id)
                getBlockUserById();
                setModalBlockShow(false)
                setDisableButton(false)
            } else {
                setDisableButton(false)
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                setDisableButton(false)
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    *  handle Block Click
    * */
    const handleUnBlockProfile = async (id) => {
        setDisableButton(true)
        await DeleteBlockUser(id).then((data) => {
            if (data.status) {
                // Toast("User UnBlocked", true);
                setSuccessUnBlockModalShow(true)
                setModalUnBlockShow(false)

                let activeThread = [];
                if (isCustomerLogin.roles[0].id == constants.ROLES.ROLE_USER) {
                    activeThread = isCustomerLogin.id + '-' + id;
                    //hideThread(id + '-' + isCustomerLogin.id, isCustomerLogin, 0)
                } else {
                    activeThread = id + '-' + isCustomerLogin.id;
                    //hideThread(isCustomerLogin.id + '-' + id, isCustomerLogin, 0)
                }
                blockUnblockProfile(activeThread, 0, id)

                getBlockUserById();
                setDisableButton(false)
            } else {
                Toast(data.message, false);
                setDisableButton(false)
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                setDisableButton(false)
                return Toast(error.response.data.message, false);
            }
        })
    }

    /*
    *  handle Report Profile Click
    * */
    const handleReportProfile = (id) => {
        console.log("Report profile Id: ", id);
    }

    const handleChatRequest = async (data) => {
        if (data && data.profileId) {
            let obj = {
                ...data, ...{
                    user_id: isCustomerLogin.id,
                    username: isCustomerLogin?.name || isCustomerLogin?.username || '',
                }
            };

            createUserChatList(obj).then(() => {
                //navigate to chat
                navigate('/inbox', {
                    state: {
                        user_id: data.profileId,
                        is_new: 1
                    }
                })
            })
        }
    }

    return (
        <div className={`mainDashboard ${show ? 'd-block' : 'd-none'}`}>
            <div className={"SingleFeedProfile"}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"col-xxl-10 offset-xxl-1"}>
                            <Row className={"m-b-30"}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Link to={"/explore"} className={"linkText"}>
                                        <LeftIcon/> Back
                                    </Link>
                                </Col>
                            </Row>

                            <Row className={"feedProfileBox"}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}
                                     className={"col-xxl-5 offset-xxl-1"}>
                                    <div className={`imgContainer ${data.isCharityMember ? "charityMember" : ""}`}>
                                        <img src={data.img ? data.img : placeholderImg}
                                             alt={data.profileName}
                                             className={"img-fluid parentImage"}
                                             onError={({currentTarget}) => {
                                                 currentTarget.onerror = null; // prevents looping
                                                 currentTarget.src = placeholderImg;
                                             }}/>
                                        {
                                            data.isCharityMember ?
                                                <div className={"charityIcons"}>
                                                    <div className={"imageFlex"}>
                                                        <img src={charityImage || placeholderImg}
                                                             alt={data.profileName}
                                                             className={"img-fluid"}
                                                             onError={({currentTarget}) => {
                                                                 currentTarget.onerror = null; // prevents looping
                                                                 currentTarget.src = placeholderImg;
                                                             }}/>
                                                        <span className={"charityName"}>{data?.charity?.name || ''}</span>
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                    </div>
                                </Col>
                                <div className={"spacer d-sm-none"} style={{marginTop: '30px'}}></div>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}
                                     className={"col-xxl-5 offset-xxl-1"}>
                                    <div className={"profileDescription"}>
                                        <div className={"profileTitle"}>
                                            <div className={"titleContainer"}>
                                                <h1>{data.profileName}</h1>
                                                {
                                                    data.ageLimit &&
                                                    <span className={"tag"}>{data.ageLimit}</span>
                                                }
                                            </div>
                                            <div className={"dropdownContainer"}>
                                                <DropdownButton id="dropdown-item-button" align={"end"}
                                                                title={<VerticalIcon/>}>
                                                    {
                                                        isBlocked ?
                                                            <Dropdown.Item as="button"
                                                                           onClick={() => setModalUnBlockShow(true)}><BlockIcon/> Unblock</Dropdown.Item>
                                                            :
                                                            <Dropdown.Item as="button"
                                                                           onClick={() => setModalBlockShow(true)}><BlockIcon/> Block</Dropdown.Item>
                                                    }

                                                    <Dropdown.Item as="button"
                                                                   onClick={() => setModalReportShow(true)}><ReportIcon/> Report
                                                        profile</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        isBlocked && <BlockStatus status={isBlocked}/>
                                    }
                                    {
                                        !isBlocked && <Status status={data.status}/>
                                    }

                                    {
                                        !isBlocked && <>
                                            <p className={"streamingCategory"}>{data.category}</p>
                                            <h4>Bio</h4>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={9} xl={9} className={"col-xxl-9"}>
                                                    {
                                                        data.bio &&
                                                        <ReadMore classList={"bioDetails"} textLength={"255"}
                                                                  children={data.bio}/>
                                                    }
                                                </Col>
                                            </Row></>
                                    }

                                    <Button className={"chatNow"}
                                            disabled={isBlocked || data.status == 'notActive'}
                                            onClick={() => {
                                                if (!isBlocked || data.status == 'Active') {
                                                    removeEmptyMessageThreads(isCustomerLogin.id.toString())
                                                    handleChatRequest(data)
                                                }
                                            }
                                            }
                                    >
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"chatIcon"}>
                                                <ChatIconOutline/>
                                            </div>
                                            <div className={"d-flex align-items-center mx-auto text-center"}>
                                                <span>Chat Now</span>
                                                <span
                                                    className={"starLabel"}><Star/> {data.tokensCount}</span>
                                            </div>
                                        </div>
                                    </Button>

                                    {
                                        !isBlocked && <>
                                            <h4 className={"m-b-20"}>Categories</h4>
                                            <ul className={"list-inline"}>
                                                {
                                                    data.categoryTags && data.categoryTags.length > 0 ?
                                                        data.categoryTags?.map((tags, index) => {
                                                            return (
                                                                <li className={"list-inline-item"} key={index}>
                                                                    <span className={"catTag"}>
                                                                        {tags.tag}
                                                                    </span>
                                                                </li>
                                                            )
                                                        }) : 'N/A'
                                                }
                                            </ul>
                                        </>
                                    }


                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <ThemeModal title={"Block User"} content={<BlockModalContent/>} className={"blockUser"} size={"sm"}
                                show={modalBlockShow}
                                onHide={() => {
                                    setModalBlockShow(false)
                                }}/>

                    <ThemeModal title={"Unblock User"} content={<UnBlockModalContent/>} className={"blockUser"}
                                size={"sm"}
                                show={modalUnBlockShow}
                                onHide={() => {
                                    setModalUnBlockShow(false)
                                }}/>

                    <ThemeModal title={"Report User"} content={<ReportModalContent/>} className={"reportUser"}
                                size={"md"}
                                show={modalReportShow}
                                onHide={() => {
                                    setModalReportShow(false)
                                }}/>
                    <ThemeModal title={""} content={<ReportSuccessModal/>} className={"addCardInfo"} size={"sm"}
                                show={ReportSuccessModalShow}
                                onHide={() => {
                                    setReportSuccessModalShow(false)
                                    reset()
                                }}/>
                    <ThemeModal title={""} content={<SuccessBlockModal/>} className={"addCardInfo"} size={"sm"}
                                show={successBlockModalShow}
                                onHide={() => {
                                    setSuccessBlockModalShow(false)
                                    reset()
                                }}/>
                    <ThemeModal title={""} content={<SuccessUnBlockModal/>} className={"addCardInfo"} size={"sm"}
                                show={successUnBlockModalShow}
                                onHide={() => {
                                    setSuccessUnBlockModalShow(false)
                                    reset()
                                }}/>
                </Container>
            </div>
        </div>
    )
}

export default UserProfile
