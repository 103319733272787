import React, {useContext, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import LogoImg from '../../../assets/images/logo/logo.svg';
import {NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import {IsCustomerLogin} from "../../../App";
import {SignOutIcon} from "../SvgComponents";
import {EmptyLocalStorage, Logout} from "../../../services/authentication.service";
import {Toast} from "../../../utils/helpers";
import {activeInactiveUser} from "../../../services/firebase.service";
import ThemeModal from "../ThemeModal";

const NavLinks = [{
        type: "link",
        url: "/",
        urlText: "Home",
        disabled: false
    },
    {
        type: "link",
        url: "/our-team",
        urlText: "Our Team",
        disabled: false
    },
    {
        type: "link",
        url: "/why-ourchat",
        urlText: "Why OurChat",
        disabled: false
    },
    {
        type: "link",
        url: "/contact",
        urlText: "Contact Us",
        disabled: false
    },
    {
        type: "dropdown",
        url: "/",
        urlText: "Services",
        dropdownItems: [{
                url: "/service-01",
                urlText: "Service 01",
                disabled: false
            },
            {
                url: "/service-02",
                urlText: "Service 02",
                disabled: true
        }],
    }];

const NavBtns = [{
        btnType: "outline",
        btnText: "Login",
        btnUrl: "/login",
    },
    {
        btnType: "solid",
        btnText: "Sign up",
        btnUrl: "/sign-up",
    }];

function Navigation() {
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [logoutModalShow, setLogoutModalShow] = useState(false);

    async function handleLogout() {
        await Logout().then(async (data) => {
            if (data.status) {
                setLogoutModalShow(false)
                await EmptyLocalStorage();
                //Toast(data.message, true)
                activeInactiveUser(isCustomerLogin.id, false)
                setIsCustomerLogin(null)
                navigate('/');
            } else {
                Toast(data.message, false);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate('/');
            } else {
                //console.log(error);
                return Toast(error.response.data.message, false);
            }
        })
    }

    const LogoutModal = () => {
        return (
            <div className={"purchaseTokensModal text-center"}>
                <p>Are you sure want to logout? </p>
                <div class="m-t-20 m-b-20 d-flex align-items-center justify-content-center">
                    <Button disabled={isLoading} className="btn-rounded-outline"
                            onClick={() => handleLogout()}>Logout</Button>
                    <Button className="btn-rounded-outline" onClick={() => {
                        setLogoutModalShow(false)
                    }}>Cancel</Button>
                </div>
            </div>
        )
    }

    return (
        <Navbar bg="light" expand="lg" fixed="top" className={"ourChatNav"}>
            <Container fluid>
                <Navbar.Brand onClick={() => {
                    navigate('/')
                }}>
                    <img src={LogoImg} alt={"OurChat"} className={"img-fluid"} />
                </Navbar.Brand>

                {
                    location.pathname === "/configure-profile" ?
                        <div className={""}>
                            <Button type={"button"} className={"btn-logout"} onClick={() => {setLogoutModalShow(true)}}>
                                <SignOutIcon/> Sign Out
                            </Button>
                        </div>
                        : ''
                }

            </Container>

            <ThemeModal title={"Logout"} content={<LogoutModal/>} className={"addCardInfo"}
                                size={"sm"}
                                show={logoutModalShow}
                                onHide={() => {
                                    setLogoutModalShow(false)
                                }}/>
        </Navbar>
    );
}

export default Navigation;
