import React from "react";
import { Outlet } from "react-router-dom";
import {ToastContainer} from "react-toastify";

function PageLayout() {
    return (
        <div className="OpenPageLayout">
            <Outlet />
            <ToastContainer/>
        </div>
    );
}

export default PageLayout;
