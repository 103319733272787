import React, {useState, useEffect, useContext} from 'react'
import {constants} from "../../../utils/constants";
import {UnlockChat} from "./UnlockChat";
import {RefundToken} from "./RefundToken";

export const GetLockRefundButton = ({customerLogin, message, activeThread}) => {
    const [accLocked, setAccLocked] = useState(false);

    useEffect(() => {
        checkLockLogin()
    }, [message])

    const checkLockLogin = () => {
        if (customerLogin && customerLogin.roles[0].id == constants.ROLES.ROLE_USER && message.chatStatus == constants.CHAT_REQUEST_STATUS.REQUESTED) {
            let diff = Math.abs(message.message_timestamp - new Date().getTime()) / 3600000;
            if (message.lockMessage == 0) {
                //Set Lock 1
                if (parseInt(diff) >= constants.ACCOUNT_LOCK_HOURS) {
                    setAccLocked(true);
                } else {
                    setAccLocked(false);
                }
            } else if (message.lockMessage == 1) {
                //Set Lock 2
                if (parseInt(diff) >= constants.ACCOUNT_LOCK_FIVE_HOURS) {
                    setAccLocked(true);
                } else {
                    setAccLocked(false);
                }
            }
        }
    }

    if (accLocked) {
        return (
            <div style={{
                verticalAlign: 'middle',
                display: 'flex',
                alignItems: 'center',
            }}>
                <UnlockChat setAccLocked={setAccLocked} activeThread={activeThread} message={message}/>

                <RefundToken setAccLocked={setAccLocked} activeThread={activeThread} message={message}/>
            </div>
        )
    }
}